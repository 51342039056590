import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { UserStatus } from '../../../constants';

function PrivateRoute({ component: Component, ...rest }) {
  const user = useSelector(state => state.user);
  const investor = useSelector(state => state.investor);

  return (
    <Route
      {...rest}
      render={props => {
        if (investor.form && Object.keys(investor.form) === 0) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/investor/form' }} />;
        }

        // if (
        //   investor.form &&
        //   Object.keys(investor.form) !== 0 &&
        //   user.isApproved === UserStatus.PENDING
        // ) {
        //   // role not authorised so redirect to home page
        //   return <Redirect to={{ pathname: '/investor/pending-approval' }} />;
        // }

        if (
          investor.form &&
          Object.keys(investor.form) !== 0 &&
          user.isApproved === UserStatus.DENIED
        ) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/investor/form' }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
