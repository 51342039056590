import { enterpriseConstants } from '../constants/enterprise';
import { enterpriseService } from '../services/enterpriseService';

export const enterpriseActions = {
  getForm,
  createForm,
  updateForm,
  getEnterprise,
  enterpriseFiancialRiskScores,
  enterpriseupdateMarketRiskPAD,
  enterpriseImpactRiskScores,
  matchEnterprise
};

function getForm(id) {
  return dispatch => {
    dispatch(request());
    return enterpriseService.getForm(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.GET_FORM_REQUEST };
  }
  function success(form) {
    return { type: enterpriseConstants.GET_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: enterpriseConstants.GET_FORM_FAILURE, error };
  }
}

function enterpriseFiancialRiskScores(id) {
  return dispatch => {
    dispatch(request());
    dispatch(resetData());
    return enterpriseService.enterpriseFiancialRiskScores(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };
  function resetData() {
    return {
      type: enterpriseConstants.RESET_STATE
    };
  }
  function request() {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_REQUEST
    };
  }
  function success(RiskScores) {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_SUCCESS,
      RiskScores
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_FAILURE,
      error
    };
  }
}

function enterpriseupdateMarketRiskPAD(data) {
  return dispatch => {
    dispatch(request());
    return enterpriseService.enterpriseupdateMarketRiskPAD(data).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return {
      type: enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_REQUEST
    };
  }
  function success(marketRiskPAD) {
    return {
      type: enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_SUCCESS,
      marketRiskPAD
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_FAILURE,
      error
    };
  }
}

function enterpriseImpactRiskScores(data) {
  return dispatch => {
    dispatch(request());
    return enterpriseService.enterpriseImpactRiskScores(data).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_REQUEST
    };
  }
  function success(impactRisk) {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_SUCCESS,
      impactRisk
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_FAILURE,
      error
    };
  }
}

function createForm(formAnswers) {
  return dispatch => {
    dispatch(request());
    enterpriseService.createForm({ formAnswers }).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.CREATE_FORM_REQUEST };
  }
  function success(form) {
    return { type: enterpriseConstants.CREATE_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: enterpriseConstants.CREATE_FORM_FAILURE, error };
  }
}

function updateForm(id, formAnswers) {
  return dispatch => {
    dispatch(request());
    console.log(formAnswers);
    enterpriseService.updateForm(id, { formAnswers }).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.UPDATE_FORM_REQUEST };
  }
  function success(form) {
    return { type: enterpriseConstants.UPDATE_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: enterpriseConstants.UPDATE_FORM_FAILURE, error };
  }
}

function getEnterprise() {
  return dispatch => {
    dispatch(request());
    return enterpriseService.getEnterprise().then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.GET_ENTERPRISE_REQUEST };
  }
  function success(enterprise) {
    return { type: enterpriseConstants.GET_ENTERPRISE_SUCCESS, enterprise };
  }
  function failure(error) {
    return { type: enterpriseConstants.GET_ENTERPRISE_FAILURE, error };
  }
}

function matchEnterprise(data) {
  return dispatch => {
    dispatch(request());
    return enterpriseService.matchEnterprise(data).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };
  function resetData() {
    return {
      type: enterpriseConstants.RESET_STATE
    };
  }
  function request() {
    return {
      type: enterpriseConstants.MATCH_ENTERPRISE_REQUEST
    };
  }
  function success(matchEnterData) {
    return {
      type: enterpriseConstants.MATCH_ENTERPRISE_SUCCESS,
      matchEnterData
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.MATCH_ENTERPRISE_FAILURE,
      error
    };
  }
}
