import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconAvatar from '../../../assets/images/avatarSmall.svg';
import { acceleratorActions } from '../../../actions/acceleratorAction';
import { useEffect } from 'react';
import { history } from '../../../helpers/history';
import * as yup from 'yup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const validationSchema = yup.object().shape({
  description: yup.string().required('Description is required'),
  amount: yup.string().required('Amount is required')
});

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const profileData = useSelector(state => state.accelerator.profile);
  useEffect(() => {
    user && user.id && dispatch(acceleratorActions.getProfile(user.id));
  }, [dispatch, user]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      description: profileData.description ? profileData.description : '',
      amount: profileData.amount ? profileData.amount : '',
      profileId: profileData.id ? profileData.id : ''
    },
    onSubmit: values => {
      dispatch(acceleratorActions.AddUpdateProfile(values));
      history.push('/accelerator/home');
    }
  });

  return (
    <Wrapper>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Your Accelerator Profile</strong>
        </Typography>
      </Box>
      <Box mt={7} mb={3}>
        <Typography variant="h4">
          <strong>
            Thanks! For us to be able to showcase your accelerator on
            ImpactCred, please fill out the following details.
          </strong>
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Card title="Description" icon={iconAvatar}>
          <Typography variant="h4">
            Please provide a description of your Accelerator
          </Typography>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Card>
        <Box mb={7} />
        <Card title="Follow-on Funding">
          <Tooltip
            placement="right-right"
            arrow
            display="flex"
            title="Ex. 500,000. Add a space to the number with M or B at the end for Million or Billion."
          >
            <Typography variant="h4">
              What is the anticipated amount in USD in follow-on funding that
              graduates of your program have raised?
              <HelpOutlineIcon
                style={{ fontSize: '20px', margin: '-4px 4px' }}
              />
            </Typography>
          </Tooltip>
          <Box mb={4} />
          <TextField
            variant="outlined"
            id="amount"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && formik.errors.amount}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </Card>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
          >
            Save
          </Button>
        </Box>
      </form>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  height: 100%;
  max-width: 918px;
  padding: 84px 60px;
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export default Profile;
