import { Redirect, Route, Switch } from 'react-router';
import HomeAccelerator from './Home/Home';
import Profile from './Profile/Profile';
import IntakeForm from './IntakeForm/IntakeForm';

const Accelerator = () => {
  return (
    <Switch>
      <Route path="/accelerator/home" component={HomeAccelerator} />
      <Route path="/accelerator/form" component={IntakeForm} />
      <Route path="/accelerator/profile" component={Profile} />
      <Redirect exact from="/accelerator" to="/accelerator/home" />
    </Switch>
  );
};

export default Accelerator;
