import { adminConstants } from '../constants/admin';
import { adminService } from '../services/adminService';

export const adminActions = { inviteUser };

function inviteUser(data) {
  return dispatch => {
    dispatch(request());
    adminService.inviteUser(data).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: adminConstants.SEND_INVITE_REQUEST };
  }
  function success(data) {
    return { type: adminConstants.SEND_INVITE_SUCCESS, data };
  }
  function failure(error) {
    return { type: adminConstants.SEND_INVITE_FAILURE, error };
  }
}
