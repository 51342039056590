import { Box, Typography } from '@material-ui/core';
import Card from '../../common/Card/Card';

import { Bold, Wrapper } from '../Onboarding/Onboarding.styled';

const PendingApproval = () => {
  return (
    <Wrapper>
      <Box mb={7}>
        <Bold variant="h1">Your Business Profile</Bold>
      </Box>
      <Card title={'Under Review'}>
        <Typography variant="h4">
          Thank you for completing your application to have your profile listed
          on ImpactCred. You will be notified within five to seven business days
          if we have further questions or your application is approved.
        </Typography>
      </Card>
    </Wrapper>
  );
};

export default PendingApproval;
