import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import iconPie from '../../../assets/images/pie.svg';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Bold,
  FileList,
  StyledDivider,
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object({
  acceleratorsorincubators: yup.string().required('This field is required'),
  referralName1: yup.string().required('This field is required'),
  referralEmail1: yup.string().required('This field is required'),
  referralName2: yup.string().required('This field is required'),
  referralEmail2: yup.string().required('This field is required')
  //referralName3: yup.string().required('This field is required'),
  //referralEmail3: yup.string().required('This field is required')
});

const initialValues = {
  //acceleratorOrIncubatorGraduatedFrom: '',
  referralName1: '',
  referralEmail1: '',
  referralName2: '',
  referralEmail2: '',
  acceleratorsorincubators: ''
  //referralName3: '',
  //referralEmail3: ''
};

const acceleratorsorincubators = [
  {
    id: '0',
    label: 'More than 3',
    score: '2',
    Weight: '0.05'
  },
  {
    id: '1',
    label: '3',
    score: '4',
    Weight: '0.05'
  },
  {
    id: '2',
    label: '2',
    score: '6',
    Weight: '0.05'
  },
  {
    id: '3',
    label: '1',
    score: '8',
    Weight: '0.05'
  }
  // {
  //   id: '4',
  //   label: '0',
  //   score: '10',
  //   Weight: '0.05'
  // }
];

const Pillar4 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [getRiskScores, setRiskScores] = useState([]);
  useEffect(() => {
    setRiskScores(RiskScores);
  }, [RiskScores]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    validationSchema,
    onSubmit: values => {
      var acceleratorScore = acceleratorsorincubators.filter(
        e => e.label == values.acceleratorsorincubators
      );
      var Weight = 0;
      if (acceleratorScore.length != 0) {
        Weight += parseFloat(acceleratorScore[0].Weight);
        getRiskScores.enterpriseFinancialRiskScore.pillar4Qualitative.acceptanceIntoAccelertor.score =
          acceleratorScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar4Qualitative.acceptanceIntoAccelertor.answer =
          acceleratorScore[0].label;
        var scorCR = acceleratorScore[0].Weight * acceleratorScore[0].score;
        scorCR = scorCR / Weight;
        getRiskScores.enterpriseFinancialRiskScore.pillar4Qualitative.finalQualitativeScore =
          parseFloat(scorCR.toFixed(1));
      }
      console.log(getRiskScores);
      onSave({
        pillar4Qualitative: values,
        riskScore: getRiskScores
      });
      onNext();
    }
  });

  return (
    <Card title="Pillar 4 of 5: Qualitative" icon={iconPie}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4">
            How many accelerators or incubators have you graduated from or are
            on track to graduate from?
          </Typography>
        </Box>
        <Box mt={3} mb={3}>
          {formik.errors.acceleratorsorincubators &&
          formik.errors.acceleratorsorincubators ? (
            <InputFeedback error={formik.errors.acceleratorsorincubators} />
          ) : null}
          <RadioGroup
            id="acceleratorsorincubators"
            name="acceleratorsorincubators"
            value={formik.values.acceleratorsorincubators}
            onChange={formik.handleChange}
            error={
              formik.touched.acceleratorsorincubators &&
              formik.errors.acceleratorsorincubators
            }
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={'row'}>
              {acceleratorsorincubators.map(item => (
                <Box
                  md={4}
                  sm={6}
                  xs={12}
                  mr={2}
                  key={`investment-goals_3-${item.id}`}
                >
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label={item.label}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
        </Box>
        <Divider />
        <Box mb={1.5} mt={2}>
          <Typography variant="h4">
            Please list two references who can speak about your integrity,
            management competencies and market potential.
          </Typography>
        </Box>
        <Divider />
        <Box mt={4} mb={3}>
          <Bold>First referral</Bold>
        </Box>
        <Box mb={2}>
          <TextField
            variant="outlined"
            id="referralName1"
            name="referralName1"
            placeholder="First and last name"
            value={formik.values.referralName1}
            onChange={formik.handleChange}
            error={
              formik.touched.referralName1 &&
              Boolean(formik.errors.referralName1)
            }
            helperText={
              formik.touched.referralName1 && formik.errors.referralName1
            }
          />
        </Box>
        <Box mb={8}>
          <TextField
            variant="outlined"
            id="referralEmail1"
            name="referralEmail1"
            placeholder="me@example.com"
            value={formik.values.referralEmail1}
            onChange={formik.handleChange}
            error={
              formik.touched.referralEmail1 &&
              Boolean(formik.errors.referralEmail1)
            }
            helperText={
              formik.touched.referralEmail1 && formik.errors.referralEmail1
            }
          />
        </Box>

        <Box mb={3}>
          <Bold>Second referral</Bold>
        </Box>
        <Box mb={2}>
          <TextField
            variant="outlined"
            id="referralName2"
            name="referralName2"
            placeholder="First and last name"
            value={formik.values.referralName2}
            onChange={formik.handleChange}
            error={
              formik.touched.referralName2 &&
              Boolean(formik.errors.referralName2)
            }
            helperText={
              formik.touched.referralName2 && formik.errors.referralName2
            }
          />
        </Box>
        <Box mb={8}>
          <TextField
            variant="outlined"
            id="referralEmail2"
            name="referralEmail2"
            placeholder="me@example.com"
            value={formik.values.referralEmail2}
            onChange={formik.handleChange}
            error={
              formik.touched.referralEmail2 &&
              Boolean(formik.errors.referralEmail2)
            }
            helperText={
              formik.touched.referralEmail2 && formik.errors.referralEmail2
            }
          />
        </Box>
        {/*

        <Box mb={3}>
          <Bold>Third referral</Bold>
        </Box>
        <Box mb={2}>
          <TextField
            variant="outlined"
            id="referralName3"
            name="referralName3"
            placeholder="First and last name"
            value={formik.values.referralName3}
            onChange={formik.handleChange}
            error={
              formik.touched.referralName3 &&
              Boolean(formik.errors.referralName3)
            }
            helperText={
              formik.touched.referralName3 && formik.errors.referralName3
            }
          />
        </Box> 
        <Box mb={8}>
          <TextField
            variant="outlined"
            id="referralEmail3"
            name="referralEmail3"
            placeholder="me@example.com"
            value={formik.values.referralEmail3}
            onChange={formik.handleChange}
            error={
              formik.touched.referralEmail3 &&
              Boolean(formik.errors.referralEmail3)
            }
            helperText={
              formik.touched.referralEmail3 && formik.errors.referralEmail3
            }
          />
        </Box>*/}
        <Divider />

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onPrev}
          >
            Prev
          </Button>
          <Box mr={3} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onSkip}
        >
          Skip for now
        </Button>
      </Box> */}
    </Card>
  );
};

export default Pillar4;
