import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem
} from '@material-ui/core';
import { useFormik } from 'formik';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconPie from '../../../assets/images/pie.svg';
import { FileList } from './Onboarding.styled';
import { useCallback, useEffect } from 'react';
const initialValues = {
  seekingLoan: '50000',
  repayment: '6 month'
};
const enterpriseFinancialRiskScore = {
  enterprise_user_id: '',
  pillar1Earnings: {
    revenue: { answer: '', score: '' },
    grossMargin: { answer: '', score: '' },
    netMargin: { answer: '', score: '' },
    yoyGrowth: { answer: '', score: '' },
    finalEarningsScore: ''
  },
  pillar2Team: {
    fundingTeamExperience: { answer: '', score: '' },
    boardOfadvisors: { answer: '', score: '' },
    finalTeamScore: ''
  },
  pillar3Fundamentals: {
    yearSinceIncorporation: { answer: '', score: '' },
    leverage: { answer: '', score: '' },
    dscr: { answer: '', score: '' },
    otherSeniorLoans: { answer: '', score: '' },
    finalFundamentalsScore: ''
  },
  pillar4Qualitative: {
    acceptanceIntoAccelertor: { answer: '', score: '' },
    finalQualitativeScore: ''
  },
  pillar5MarketRisk: {
    finalMarketRiskScore: ''
  },
  pillar6PubliclyAvailableData: {
    finalPubliclyAvailableDataScore: ''
  },
  finalFinRiskScore: '',
  adminUserfinalFinRiskScore: 0
};

const enterpriseImpactRiskScore = {
  enterprise_user_id: '',
  numberofSDGAbove10Percent: { answer: '', score: '' },
  numberofGeographies: { answer: '', score: '' },
  impactOutcome: { answer: '', score: '' },
  SGDBreakdown: {
    NoPoverty: '',
    ZeroHunger: '',
    goodHealth: '',
    qualityEducation: '',
    GenderEquality: '',
    IndustryInnovationInfrastructure: '',
    ClimateAction: '',
    CleanWaterSanitation: '',
    ReducedInequality: '',
    LifeBelowWater: '',
    AffordableCleanEnergy: '',
    SustainableCitiesCommunities: '',
    LifeonLand: '',
    PeaceJusticeStrongInsitutions: '',
    DecentWorkandEconomicGrowth: '',
    ResponsibleProductionConsumption: '',
    Partnerships: ''
  },
  ImpactScore: '',
  adminUserImpactScore: 0
};

const Pillar0 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   window.addEventListener('unload', alertUser);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //     window.removeEventListener('unload', alertUser);
  //     //alertUser();
  //   };
  // }, []);
  // const alertUser = e => {
  //   e.preventDefault();
  //   e.returnValue = '';
  // };
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    onSubmit: values => {
      onSave({
        financingNeeds: values,
        riskScore: enterpriseFinancialRiskScore,
        ImpactRiskScore: enterpriseImpactRiskScore
      });
      onNext();
    }
  });
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={{ height: window.innerHeight + 'px' }}
      >
        <Card title="Bridge Financing Needs" icon={iconPie}>
          <Divider />
          <FileList
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={1}
          >
            <Box display="flex">
              <Typography>Amount Seeking in Loans</Typography>
            </Box>
            <TextField
              id="seekingLoan"
              name="seekingLoan"
              variant="outlined"
              value={formik.values.seekingLoan}
              onChange={formik.handleChange}
              select
            >
              <MenuItem value="50000">$50,000</MenuItem>
              <MenuItem value="100000">$100,000</MenuItem>
              <MenuItem value="150000">$150,000</MenuItem>
              <MenuItem value="200000">$200,000</MenuItem>
              <MenuItem value="250000">$250,000</MenuItem>
              <MenuItem value="300000">$300,000</MenuItem>
              <MenuItem value="350000">$350,000</MenuItem>
              <MenuItem value="400000">$400,000</MenuItem>
              <MenuItem value="450000">$450,000</MenuItem>
              <MenuItem value="500000">$500,000</MenuItem>
              <MenuItem value="1000000">$1,000,000</MenuItem>
              <MenuItem value="1500000">$1,500,000</MenuItem>
              <MenuItem value="2000000">$2,000,000</MenuItem>
              <MenuItem value="2500000">$2,500,000</MenuItem>
              <MenuItem value="3000000">$3,000,000</MenuItem>
            </TextField>
            <Typography>Repayment Timeline</Typography>
            <TextField
              id="repayment"
              name="repayment"
              variant="outlined"
              value={formik.values.repayment}
              onChange={formik.handleChange}
              select
            >
              <MenuItem value="6 month">6 months</MenuItem>
              <MenuItem value="1 year">1 year</MenuItem>
              <MenuItem value="1.5 years">1.5 years</MenuItem>
              <MenuItem value="2 years">2 years</MenuItem>
              <MenuItem value="2.5 years">2.5 years</MenuItem>
              <MenuItem value="3 years">3 years</MenuItem>
              <MenuItem value="3.5 years">3.5 years</MenuItem>
              <MenuItem value="4 years">4 years</MenuItem>
              <MenuItem value="4.5 years">4.5 years</MenuItem>
            </TextField>
          </FileList>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            {/* <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={onPrev}
            >
              Prev
            </Button> */}
            <Box mr={3} />
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Card>
      </form>
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onSkip}
        >
          Skip for now
        </Button>
      </Box> */}
    </>
  );
};

export default Pillar0;
