import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const Card = props => {
  const { title, icon } = props;
  return (
    <Wrapper>
      <Title display="flex" alignItems="center" justifyContent="space-between">
        <TitleLabel variant="h5">{title}</TitleLabel>
        {icon && <Icon src={icon} alt={'icon'} />}
      </Title>
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)``;

const Title = styled(Box)`
  height: 48px;
  background-color: ${theme.palette.common.lightGray};
  padding: ${theme.spacing(1.5, 1, 1.5, 2)};
`;

const TitleLabel = styled(Typography)`
  font-weight: 200;
`;

const Icon = styled.img`
  padding: ${theme.spacing(1)}px;
  background: ${theme.palette.secondary.main};
  border-radius: 5px;
`;

const Content = styled(Box)`
  background: ${theme.palette.common.white};
  padding: 13px 24px 24px 13px;
`;

export default Card;
