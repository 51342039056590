import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  Grid,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import * as yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import iconPie from '../../../assets/images/pie.svg';
import iconDownload from '../../../assets/images/download.svg';
import { DownloadIcon, FileList, StyledDivider } from './Onboarding.styled';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { useDispatch, useSelector } from 'react-redux';
import { storage } from '../../../firebase/firebaseConfig';
import LinearProgress from '@material-ui/core/LinearProgress';
import HighlightOff from '@material-ui/icons/HighlightOff';
import classNames from 'classnames';
import {
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  revenueType: yup.string().required('This field is required'),
  explainBusinessModel: yup.string().required('This field is required.'),
  GrossMargin: yup.string().required('This field is required'),
  NetMargin: yup.string().required('This field is required'),
  yoyGrowth: yup.string().required('This field is required'),
  rangeforyourannualrevenue: yup.string().required('This field is required')
});

const initialValues = {
  revenueType: '',
  explainBusinessModel: '',
  GrossMargin: '',
  NetMargin: '',
  yoyGrowth: '',
  rangeforyourannualrevenue: '',
  yearoffinancials: [],
  contractAttachmentLinks: []
};

const rangeforyourannualrevenue = [
  {
    id: '0',
    label: '250,000 or greater',
    score: '2',
    Weight: '0.1'
  },
  {
    id: '1',
    label: '150,000 - 249,999',
    score: '4',
    Weight: '0.1'
  },
  {
    id: '2',
    label: '100,000 - 149,999',
    score: '6',
    Weight: '0.1'
  },
  {
    id: '3',
    label: '50,000 - 99,999',
    score: '8',
    Weight: '0.1'
  },
  {
    id: '4',
    label: 'Less than 50,000',
    score: '10',
    Weight: '0.1'
  }
];

const Margin = [
  {
    id: '0',
    label: '20% or greater',
    score: '2',
    Weight: '0.1'
  },
  {
    id: '1',
    label: '15 - 19.99%',
    score: '4',
    Weight: '0.1'
  },
  {
    id: '2',
    label: '10 - 14.99%',
    score: '6',
    Weight: '0.1'
  },
  {
    id: '3',
    label: '5 - 9.99%',
    score: '8',
    Weight: '0.1'
  },
  {
    id: '4',
    label: 'Less than 5%',
    score: '10',
    Weight: '0.1'
  }
];

const Pillar1 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [contractAttachmentLinks, setcontractAttachmentLinks] = useState([]);
  const [yearoffinancials, setyearoffinancials] = useState([]);
  const [getRiskScores, setRiskScores] = useState([]);
  const [documentType, setdocumentType] = useState('');
  const [yearoffinancialsErr, setyearoffinancialsErr] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    if (value.contractAttachmentLinks) {
      setcontractAttachmentLinks(value.contractAttachmentLinks);
    }
    if (value.yearoffinancials) {
      setyearoffinancials(value.yearoffinancials);
    }
    setRiskScores(RiskScores);
  }, [value, RiskScores]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    validationSchema,
    onSubmit: values => {
      if (yearoffinancials.length == 0) {
        setyearoffinancialsErr('This field is required!');
        return false;
      }
      var finalEarningsScoreR = '';
      var finalEarningsScoreM = '';
      var finalEarningsScoreNM = '';
      var finalEarningsScoreYG = '';
      values.contractAttachmentLinks = contractAttachmentLinks;
      values.yearoffinancials = yearoffinancials;
      var rangeforyourannualrevenueScore = rangeforyourannualrevenue.filter(
        e => e.label == values.rangeforyourannualrevenue
      );
      var Weight = 0;
      if (rangeforyourannualrevenueScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.revenue.score =
          rangeforyourannualrevenueScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.revenue.answer =
          rangeforyourannualrevenueScore[0].label;
        finalEarningsScoreR =
          rangeforyourannualrevenueScore[0].score *
          rangeforyourannualrevenueScore[0].Weight;
        Weight += parseFloat(rangeforyourannualrevenueScore[0].Weight);
      }
      var grossmarginScore = Margin.filter(e => e.label == values.GrossMargin);
      if (grossmarginScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.grossMargin.score =
          grossmarginScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.grossMargin.answer =
          grossmarginScore[0].label;
        finalEarningsScoreM =
          grossmarginScore[0].score * grossmarginScore[0].Weight;
        Weight += parseFloat(grossmarginScore[0].Weight);
      }

      var netmarginScore = Margin.filter(e => e.label == values.NetMargin);
      if (netmarginScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.netMargin.score =
          netmarginScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.netMargin.answer =
          netmarginScore[0].label;
        finalEarningsScoreNM =
          netmarginScore[0].score * netmarginScore[0].Weight;
        Weight += parseFloat(netmarginScore[0].Weight);
      }
      var yoyGrowthScore = Margin.filter(e => e.label == values.yoyGrowth);
      if (netmarginScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.yoyGrowth.score =
          yoyGrowthScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.yoyGrowth.answer =
          yoyGrowthScore[0].label;
        finalEarningsScoreYG =
          yoyGrowthScore[0].score * yoyGrowthScore[0].Weight;
        Weight += parseFloat(yoyGrowthScore[0].Weight);
      }
      var finalEarningsScore =
        finalEarningsScoreR +
        finalEarningsScoreM +
        finalEarningsScoreNM +
        finalEarningsScoreYG;
      finalEarningsScore = finalEarningsScore / Weight;
      getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.finalEarningsScore =
        parseFloat(finalEarningsScore).toFixed(1);
      onSave({
        pillar1Earnings: values,
        riskScore: getRiskScores
      });
      onNext();
    }
  });

  const handlecontractAttachmentLinks = event => {
    var ref = 'enterprise/contract/attachment';
    onUploadSubmission(event.target.files, 'contract', ref);
  };
  const handleyearoffinancials = event => {
    var ref = 'enterprise/contract/year-of-financials';
    onUploadSubmission(event.target.files, 'financials', ref);
  };

  const removeDocument = (data, type, refs) => {
    var ref = refs + data.name;
    var desertRef = storage.ref(ref);
    // Delete the file
    desertRef
      .delete()
      .then(function () {
        if (type == 'CA') {
          var links1 = contractAttachmentLinks.filter(e => e.name != data.name);
          setcontractAttachmentLinks(links1);
        }
        if (type == 'YO') {
          var links2 = yearoffinancials.filter(e => e.name != data.name);
          setyearoffinancials(links2);
          if (links2.length == 0) {
            setyearoffinancialsErr('This field is required!');
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onUploadSubmission = (files, type, ref) => {
    for (let i = 0; i < files.length; i++) {
      var fileName =
        files[i].name.split('.')[0] +
        '~' +
        Date.now() +
        '.' +
        files[i].name.split('.')[1];
      const uploadTask = storage.ref(`${ref}/${fileName}`).put(files[i]);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref(ref)
            .child(fileName)
            .getDownloadURL()
            .then(async url => {
              setdocumentType(type);
              if (type == 'contract') {
                var imageArray = {
                  name: fileName,
                  url: url,
                  progress: progress
                };
                var valueesData = Object.keys(value).length
                  ? value
                  : initialValues;
                valueesData.revenueType = formik.values.revenueType;
                valueesData.rangeforyourannualrevenue =
                  formik.values.rangeforyourannualrevenue;
                valueesData.GrossMargin = formik.values.GrossMargin;
                valueesData.NetMargin = formik.values.NetMargin;
                valueesData.explainBusinessModel =
                  formik.values.explainBusinessModel;
                valueesData.yoyGrowth = formik.values.yoyGrowth;
                setcontractAttachmentLinks(prevState => [
                  ...prevState,
                  imageArray
                ]);
                valueesData.contractAttachmentLinks = [
                  ...contractAttachmentLinks,
                  imageArray
                ];
                valueesData.yearoffinancials = yearoffinancials;
                onSave({
                  pillar1Earnings: valueesData
                });
              } else if (type == 'financials') {
                var imageArray1 = {
                  name: fileName,
                  url: url,
                  progress: progress
                };
                var valueesData1 = Object.keys(value).length
                  ? value
                  : initialValues;
                setyearoffinancials(prevState => [...prevState, imageArray1]);
                valueesData1.yearoffinancials = [
                  ...yearoffinancials,
                  imageArray1
                ];
                valueesData1.contractAttachmentLinks = contractAttachmentLinks;
                valueesData1.revenueType = formik.values.revenueType;
                valueesData1.rangeforyourannualrevenue =
                  formik.values.rangeforyourannualrevenue;
                valueesData1.GrossMargin = formik.values.GrossMargin;
                valueesData1.NetMargin = formik.values.NetMargin;
                valueesData1.explainBusinessModel =
                  formik.values.explainBusinessModel;
                valueesData1.yoyGrowth = formik.values.yoyGrowth;
                console.log(formik.values, 'formik.values');
                console.log(valueesData1);
                onSave({
                  pillar1Earnings: valueesData1
                });
                setyearoffinancialsErr('');
              }
            });
        }
      );
    }
  };

  const saveAttachment = () => {
    initialValues.contractAttachmentLinks = contractAttachmentLinks;
    initialValues.yearoffinancials = yearoffinancials;
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card title="Pillar 1 of 5: Earnings" icon={iconPie}>
          <Box mb={1.5}>
            <Typography variant="h4">
              What type of revenue do you have?
            </Typography>
          </Box>
          <Divider />
          <Box mt={3} mb={3}>
            <RadioGroup
              id="revenueType"
              name="revenueType"
              value={formik.values.revenueType}
              onChange={formik.handleChange}
              error={formik.touched.revenueType && formik.errors.revenueType}
              style={{ padding: '10px' }}
            >
              {formik.errors.revenueType && formik.errors.revenueType ? (
                <InputFeedback error={formik.errors.revenueType} />
              ) : null}
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                <Box mr={3}>
                  <FormControlLabel
                    value="CurrentRevenue"
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label="Current revenue"
                  />
                </Box>
                <FormControlLabel
                  value="ContractRevenue"
                  control={
                    <StyledRadio
                      disableRipple
                      icon={<StyledCheckedIconTick />}
                      checkedIcon={<StyledCheckedTickIcon />}
                    />
                  }
                  label="Contract revenue"
                />
              </Box>
            </RadioGroup>
          </Box>
          <Divider />
        </Card>
        <Card title="Pillar 1 of 5: Earnings" icon={iconPie}>
          <Box mb={2} mt={3}>
            <Tooltip
              placement="right-right"
              arrow
              display="flex"
              title="Please estimate in US Dollars"
            >
              <Typography variant="h4">
                Which of the following represents the range for your annual
                revenue (answers are in USD)?
                <HelpOutlineIcon
                  style={{ fontSize: '20px', margin: '-4px 4px' }}
                />
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <RadioGroup
            id="rangeforyourannualrevenue"
            name="rangeforyourannualrevenue"
            value={formik.values.rangeforyourannualrevenue}
            onChange={formik.handleChange}
            style={{ padding: '10px' }}
          >
            {formik.errors.rangeforyourannualrevenue &&
            formik.errors.rangeforyourannualrevenue ? (
              <InputFeedback error={formik.errors.rangeforyourannualrevenue} />
            ) : null}
            <Grid item md={4} sm={6} xs={12}>
              {rangeforyourannualrevenue.map(item => (
                <Box key={`investment-goals_6-${item.id}`}>
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={
                          <StyledCheckedTickIcon
                            style={{ marginBottom: '15px' }}
                          />
                        }
                      />
                    }
                    style={{ marginBottom: '15px' }}
                    label={item.label}
                  />
                </Box>
              ))}
            </Grid>
          </RadioGroup>
          <Box mb={2} mt={3}>
            <Typography variant="h4">
              If you do not have current revenue, have you acquired any
              long-term contracts to service this debt? Please attach relevant
              contract agreements.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={4}>
            <Typography
              variant="h4"
              style={{ cursor: 'pointer' }}
              htmlFor="icon-button-file"
            >
              <input
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={event => {
                  handlecontractAttachmentLinks(event);
                }}
              />
              <label htmlFor="raised-button-file">+ Add document</label>
            </Typography>
            <StyledDivider />
          </Box>
          {documentType == 'contract' && progress != 0 && progress != 100 && (
            <LinearProgress variant="determinate" value={progress} />
          )}
          {contractAttachmentLinks.map((item, index) => (
            <FileList
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={1}
              key={`${index}`}
            >
              <Box display="flex">
                <DownloadIcon src={iconDownload} alt="download" />
                <Typography>
                  {item.name.split('~')[0] +
                    '.' +
                    item.name.split('~')[1].split('.')[1]}
                </Typography>
              </Box>
              {/* <Typography>A description of the deal and terms</Typography> */}
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disableElevation
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={item.name}
                  style={{ color: '#000' }}
                >
                  Download
                </a>
              </Button>
              <Box
                display="flex"
                onClick={e =>
                  removeDocument(item, 'CA', 'enterprise/contract/attachment/')
                }
              >
                <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
              </Box>
            </FileList>
          ))}
        </Card>
        <Card title="Pillar 1 of 5: Earnings" icon={iconPie}>
          <Box mb={2} mt={3}>
            <Typography variant="h4">
              Explain your business model succintly. What are your lines of
              revenue?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="explainBusinessModel"
              name="explainBusinessModel"
              value={formik.values.explainBusinessModel}
              onChange={formik.handleChange}
              error={
                formik.touched.explainBusinessModel &&
                formik.errors.explainBusinessModel
              }
              helperText={
                formik.touched.explainBusinessModel &&
                formik.errors.explainBusinessModel
              }
            />
          </Box>
        </Card>
        <Card title="Pillar 1 of 5: Earnings" icon={iconPie}>
          <Box mb={1.5}>
            <Tooltip
              placement="right-right"
              arrow
              display="flex"
              title="This is your revenue minus cost of goods or services divided by revenue. Please use 'Less than 5%' if you don't have any revenue yet."
            >
              <Typography variant="h4">
                What is your Gross Margin from revenue?
                <HelpOutlineIcon
                  style={{ fontSize: '20px', margin: '-4px 4px' }}
                />
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box mt={3} mb={3}>
            {formik.errors.GrossMargin && formik.errors.GrossMargin ? (
              <InputFeedback error={formik.errors.GrossMargin} />
            ) : null}
            <RadioGroup
              id="GrossMargin"
              name="GrossMargin"
              value={formik.values.GrossMargin}
              onChange={formik.handleChange}
              error={formik.touched.GrossMargin && formik.errors.GrossMargin}
              style={{ padding: '10px' }}
            >
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                {Margin.map(item => (
                  <Box mr={1} key={`investment-goals_5-${item.id}`}>
                    <FormControlLabel
                      value={item.label}
                      control={
                        <StyledRadio
                          disableRipple
                          icon={<StyledCheckedIconTick />}
                          checkedIcon={<StyledCheckedTickIcon />}
                        />
                      }
                      label={item.label}
                    />
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </Box>
          <Box mb={1.5}>
            <Tooltip
              placement="right-right"
              arrow
              display="flex"
              title="This is your net after-tax income of the business divided by revenue. Please use 'Less than 5%' if you don't have any revenue yet."
            >
              <Typography variant="h4">
                What is your Net Margin from revenue?
                <HelpOutlineIcon
                  style={{ fontSize: '20px', margin: '-4px 4px' }}
                />
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box mt={3} mb={3}>
            {formik.errors.NetMargin && formik.errors.NetMargin ? (
              <InputFeedback error={formik.errors.NetMargin} />
            ) : null}
            <RadioGroup
              id="NetMargin"
              name="NetMargin"
              value={formik.values.NetMargin}
              onChange={formik.handleChange}
              error={formik.touched.NetMargin && formik.errors.NetMargin}
              style={{ padding: '10px' }}
            >
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                {Margin.map(item => (
                  <Box mr={1} key={`investment-goals_5-${item.id}`}>
                    <FormControlLabel
                      value={item.label}
                      control={
                        <StyledRadio
                          disableRipple
                          icon={<StyledCheckedIconTick />}
                          checkedIcon={<StyledCheckedTickIcon />}
                        />
                      }
                      label={item.label}
                    />
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </Box>
          <Box mb={1.5}>
            <Tooltip
              placement="right-right"
              arrow
              display="flex"
              title="Please use the average growth rate. Use 'Less than 5%'' if you didn't have any year-on-year growth."
            >
              <Typography variant="h4">
                What has been your YOY growth of revenue for the last three full
                financial years?
                <HelpOutlineIcon
                  style={{ fontSize: '20px', margin: '-4px 4px' }}
                />
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box mt={3} mb={3}>
            {formik.errors.yoyGrowth && formik.errors.yoyGrowth ? (
              <InputFeedback error={formik.errors.yoyGrowth} />
            ) : null}
            <RadioGroup
              id="yoyGrowth"
              name="yoyGrowth"
              value={formik.values.yoyGrowth}
              onChange={formik.handleChange}
              error={formik.touched.yoyGrowth && formik.errors.yoyGrowth}
              style={{ padding: '10px' }}
            >
              <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                {Margin.map(item => (
                  <Box mr={1} key={`investment-goals_7-${item.id}`}>
                    <FormControlLabel
                      value={item.label}
                      control={
                        <StyledRadio
                          disableRipple
                          icon={<StyledCheckedIconTick />}
                          checkedIcon={<StyledCheckedTickIcon />}
                        />
                      }
                      label={item.label}
                    />
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </Box>
          <Box mb={1.5}>
            <Typography variant="h4">
              Please upload your latest financials and annual financials for
              past three years.
            </Typography>
          </Box>
          {documentType == 'financials' && progress != 0 && progress != 100 && (
            <LinearProgress variant="determinate" value={progress} />
          )}
          {yearoffinancialsErr && <InputFeedback error={yearoffinancialsErr} />}
          {yearoffinancials.map((item, index) => (
            <FileList
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={1}
              key={`${index}`}
            >
              <Box display="flex">
                <DownloadIcon src={iconDownload} alt="download" />
                <Typography>
                  {item.name.split('~')[0] +
                    '.' +
                    item.name.split('~')[1].split('.')[1]}
                </Typography>
              </Box>
              {/* <Typography>A description of the deal and terms</Typography> */}
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disableElevation
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={item.name}
                  style={{ color: '#000' }}
                >
                  Download
                </a>
              </Button>
              <Box
                display="flex"
                onClick={e =>
                  removeDocument(
                    item,
                    'YO',
                    'enterprise/contract/year-of-financials/'
                  )
                }
              >
                <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
              </Box>
            </FileList>
          ))}
          <Box display="flex" alignItems="center" mt={4}>
            <Typography
              variant="h4"
              style={{ cursor: 'pointer' }}
              htmlFor="icon-buttonfile"
            >
              <input
                style={{ display: 'none' }}
                id="raised-buttonfile"
                multiple
                type="file"
                onChange={event => {
                  handleyearoffinancials(event);
                }}
              />
              <label htmlFor="raised-buttonfile">+ Add document</label>
            </Typography>
            <StyledDivider />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={onPrev}
            >
              Prev
            </Button>
            <Box mr={3} />
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Card>
        {/* <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onSkip}
          >
            Skip for now
          </Button>
        </Box> */}
      </form>
    </>
  );
};

export default Pillar1;
