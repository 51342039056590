import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { UserStatus } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Card from '../../common/Card/Card';
import { acceleratorActions } from '../../../actions/acceleratorAction';
import {
  EnhancedTableHead,
  stableSort,
  getComparator
} from '../../common/Table/Table';
import { Grid } from '@material-ui/core';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem
} from '@material-ui/core';
import { userActions } from '../../../actions/userActions';
import { toastr } from 'react-redux-toastr';
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'organization',
    numeric: false,
    disablePadding: false,
    label: 'Organization'
  },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  denyButton: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.gray
  }
}));

export default function Accelerators({ rows, onAction }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [content, setcontent] = React.useState(false);
  const [UserInfo, setUserInfo] = React.useState([]);
  const dispatch = useDispatch();
  const acceleratorProfile = useSelector(state => state.accelerator.profile);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleContent = row => {
    setUserInfo(row);
    dispatch(acceleratorActions.getProfile(row.id));
    setcontent(true);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setcontent(false);
  };
  const DeleteUser = id => {
    console.log(id);
    if (confirm('Are you sure you want to delete this user')) {
      dispatch(userActions.deleteUser(id, 'Accelerators')).then(data => {
        toastr.success('Success', 'User deleted successful');
        dispatch(userActions.list());
      });
    }
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} size="medium">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.first} {row.last}
                    </TableCell>
                    <TableCell>{row.organization}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '2px',
                              backgroundColor: '#4791db'
                            }}
                            onClick={e => handleContent(row)}
                          >
                            View
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            fontSize: '13px',
                            height: '25px',
                            padding: '2px'
                          }}
                          className={classes.denyButton}
                          onClick={e => DeleteUser(row.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={content}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Personal info">
              <Typography variant="h4">Name</Typography>
              {UserInfo && UserInfo.first && UserInfo.last
                ? UserInfo.first + '' + UserInfo.last
                : 'N/A'}
              <Divider />
              <Typography variant="h4">Email</Typography>
              {UserInfo && UserInfo.email ? UserInfo.email : 'N/A'}
              <Divider />
              <Typography variant="h4">DOB</Typography>
              {UserInfo && UserInfo.dob ? UserInfo.dob : 'N/A'}
              <Divider />
              <Typography variant="h4">Gender</Typography>
              {UserInfo && UserInfo.gender ? UserInfo.gender : 'N/A'}
              <Divider />
              <Typography variant="h4">City</Typography>
              {UserInfo && UserInfo.city ? UserInfo.city : 'N/A'}
              <Divider />
              <Typography variant="h4">State</Typography>
              {UserInfo && UserInfo.state ? UserInfo.state : 'N/A'}
              <Divider />
              <Typography variant="h4">Country</Typography>
              {UserInfo && UserInfo.country ? UserInfo.country : 'N/A'}
              <Divider />
              <Typography variant="h4">Organization</Typography>
              {UserInfo && UserInfo.organization
                ? UserInfo.organization
                : 'N/A'}
              <Divider />
            </Card>
            <Card title="Profile info">
              <Typography variant="h4">
                Please provide a description of your Accelerator
              </Typography>
              <Typography variant="h5">
                {acceleratorProfile && acceleratorProfile.description
                  ? acceleratorProfile.description
                  : 'N/A'}
              </Typography>
              <Divider />
              <Typography variant="h4">
                What is the anticipated amount in USD in follow-on funding that
                graduates of your program have raised?
              </Typography>
              <Typography variant="h5">
                {acceleratorProfile && acceleratorProfile.amount
                  ? acceleratorProfile.amount
                  : 'N/A'}
              </Typography>
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
