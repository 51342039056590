import {
  Box,
  Grid,
  Divider,
  useMediaQuery,
  Button,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import { Formik, Field, Form } from 'formik';
import { useMemo } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import iconMinus from '../../../assets/images/minusRect.svg';
import imageBG from '../../../assets/images/circleBackground.svg';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import * as yup from 'yup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  DownloadIcon,
  FileList,
  StyledDivider,
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  yourTeamDescription: yup.string().required('This field is required'),
  updatesanyrelevantPR: yup.string().required('This field is required'),
  statedimpactoutcome: yup.string().required('This field is required')
});
const initialValues = {
  investmentGoalsSdg: {
    sdgPreferences: {}
  },
  geoPreferences: {},
  deployviaImpactCred: {},
  yourTeamDescription: '',
  updatesanyrelevantPR: '',
  statedimpactoutcome: ''
};

const investmentGoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];

const geoSDGItems = [
  {
    id: '0',
    label: 'Decent Work and Economic Growth',
    perc: '100%'
  },
  {
    id: '1',
    label: 'Industry Innovation & Infrastructure',
    perc: '30%'
  },
  {
    id: '2',
    label: 'Climate Action',
    perc: '50%'
  }
];

const impactOutcome = [
  {
    id: '0',
    label: 'Neutral'
  },
  {
    id: '1',
    label: 'Benefit Stakeholders'
  },
  {
    id: '2',
    label: 'Act to Avoid Harm',
    perc: '50%'
  },
  {
    id: '3',
    label: 'Contribute to Solutions'
  }
];

const geoPreferencesItems = [
  {
    id: 'all',
    label: 'All Geographies'
  },
  {
    id: 'asia',
    label: 'Asia'
  },
  {
    id: 'australia',
    label: 'Australia'
  },
  { id: 'africa', label: 'Africa' },
  { id: 'northAmerica', label: 'North America' },
  { id: 'southAmerica', label: 'South America' },
  { id: 'europe', label: 'Europe' }
];
const geoSDGItemsS = [
  {
    DecentWork: '',
    value: ''
  },
  {
    IndustryInnovation: '',
    value: ''
  },
  {
    ClimateAction: '',
    value: ''
  }
];
const EnterpriseEcosystem = ({
  onSave,
  value,
  RiskScores,
  onNext,
  onPrev,
  onSkip,
  ImpactRiskScores
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [getRiskScores, setRiskScores] = useState([]);
  const [getImpactRiskScores, setImpactRiskScores] = useState([]);
  const [updatesanyrelevantPR, setupdatesanyrelevantPR] = useState('');
  const [yourTeamDescription, setyourTeamDescription] = useState('');
  const [primarily, sdgprimarily] = useState();
  const [sdgPreferencesValue, sdgPreferencesValueV] = useState();
  const [DecentWorkP, setDecentWorkP] = useState('');
  const [IndustryInnovation, setIndustryInnovation] = useState('');
  const [ClimateAction, setClimateAction] = useState('');
  const [deployviaI, setdeployviaI] = useState('');
  const [Preferencesarr, setPreferencesarr] = useState([]);
  useEffect(() => {
    setImpactRiskScores(ImpactRiskScores);
  }, [ImpactRiskScores]);
  const initial = useMemo(() => ({ ...initialValues, ...value }), [value]);
  console.log(initial);
  const onSubmit = useCallback(
    values => {
      console.log(Object.keys(values.investmentGoalsSdg.sdgPreferences).length);
      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 0) {
        sdgPreferencesValueV('This field is required');
        return;
      } else {
        sdgPreferencesValueV('');
      }
      var SGDTotal =
        parseInt(DecentWorkP) +
        parseInt(IndustryInnovation) +
        parseInt(ClimateAction);

      // if (values.deployviaImpactCred.length != 3 || isNaN(SGDTotal)) {
      //   setdeployviaI('This field is required');
      //   return;
      // } else {
      //   setdeployviaI('');
      // }

      // if (SGDTotal != 100) {
      //   setdeployviaI('The percentage must be equal to 100% in 3 fields');
      //   return;
      // } else {
      //   setdeployviaI('');
      // }
      if (Object.keys(values.geoPreferences).length == 0) {
        sdgprimarily('This field is required');
        return;
      } else {
        sdgprimarily('');
      }
      values.SDGItems = geoSDGItemsS;
      values.SDGItems[0].DecentWork = true;
      values.SDGItems[1].IndustryInnovation = true;
      values.SDGItems[2].ClimateAction = true;
      values.SDGItems[0].value = DecentWorkP;
      values.SDGItems[1].value = IndustryInnovation;
      values.SDGItems[2].value = ClimateAction;
      console.log(values.SDGItems);
      if (values.statedimpactoutcome == 'Neutral') {
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.answer =
          values.statedimpactoutcome;
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.score = 2;
      } else if (values.statedimpactoutcome == 'Contribute to Solutions') {
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.answer =
          values.statedimpactoutcome;
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.score = 4;
      } else if (values.statedimpactoutcome == 'Act to Avoid Harm') {
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.answer =
          values.statedimpactoutcome;
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.score = 3;
      } else if (values.statedimpactoutcome == 'Benefit Stakeholders') {
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.answer =
          values.statedimpactoutcome;
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.score = 4;
      }

      if (values.geoPreferences.length == 1) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer = 1;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score = 5;
      } else if (values.geoPreferences.length == 2) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer = 2;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score = 4;
      } else if (values.geoPreferences.length == 3) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer = 3;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score = 3;
      } else if (values.geoPreferences.length == 4) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer = 4;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score = 2;
      } else if (values.geoPreferences.length > 4) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer =
          'More than 4';
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score = 1;
      } else if (values.geoPreferences.length == 0) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.answer =
          '0';
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies.score =
          '';
      }

      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 1) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer = 1;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score = 5;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 2
      ) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer = 2;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score = 4;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 3
      ) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer = 3;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score = 3;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 4
      ) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer = 4;
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score = 2;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length > 4
      ) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer =
          'More than 4';
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score = 1;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 0
      ) {
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.answer =
          '';
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent.score =
          '';
      }

      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length > 0) {
        var noPoverty =
          values.investmentGoalsSdg.sdgPreferences.indexOf('noPoverty');
        if (noPoverty != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.NoPoverty =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.NoPoverty =
            '';
        }

        var genderEquality =
          values.investmentGoalsSdg.sdgPreferences.indexOf('genderEquality');
        if (genderEquality != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.GenderEquality =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.GenderEquality =
            '';
        }
        var industryInnovation =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'industryInnovation'
          );

        if (industryInnovation != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.IndustryInnovationInfrastructure =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.IndustryInnovationInfrastructure =
            '';
        }
        var climateAction =
          values.investmentGoalsSdg.sdgPreferences.indexOf('climateAction');

        if (climateAction != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.climateAction =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.climateAction =
            '';
        }
        var zeroHunger =
          values.investmentGoalsSdg.sdgPreferences.indexOf('zeroHunger');

        if (zeroHunger != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ZeroHunger =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ZeroHunger =
            '';
        }
        var cleanWater =
          values.investmentGoalsSdg.sdgPreferences.indexOf('cleanWater');

        if (cleanWater != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.CleanWaterSanitation =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.CleanWaterSanitation =
            '';
        }
        var reducedInequality =
          values.investmentGoalsSdg.sdgPreferences.indexOf('reducedInequality');

        if (reducedInequality != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ReducedInequality =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ReducedInequality =
            '';
        }
        var lifeBelowWater =
          values.investmentGoalsSdg.sdgPreferences.indexOf('lifeBelowWater');

        if (lifeBelowWater != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.LifeBelowWater =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.LifeBelowWater =
            '';
        }
        var goodHealthAndWellBeing =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'goodHealthAndWellBeing'
          );

        if (goodHealthAndWellBeing != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.goodHealth =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.goodHealth =
            '';
        }
        var affordableAndCleanEnergy =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'affordableAndCleanEnergy'
          );

        if (affordableAndCleanEnergy != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.AffordableCleanEnergy =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.AffordableCleanEnergy =
            '';
        }
        var sustainableCities =
          values.investmentGoalsSdg.sdgPreferences.indexOf('sustainableCities');

        if (sustainableCities != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.SustainableCitiesCommunities =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.SustainableCitiesCommunities =
            '';
        }
        var lifeOnLand =
          values.investmentGoalsSdg.sdgPreferences.indexOf('lifeOnLand');

        if (lifeOnLand != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.LifeonLand =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.LifeonLand =
            '';
        }
        var peaceAndJusticeStrongInstitutions =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'peaceAndJusticeStrongInstitutions'
          );

        if (peaceAndJusticeStrongInstitutions != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.PeaceJusticeStrongInsitutions =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.PeaceJusticeStrongInsitutions =
            '';
        }
        var qualityEducatio =
          values.investmentGoalsSdg.sdgPreferences.indexOf('qualityEducatio');
        if (qualityEducatio != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.qualityEducation =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.qualityEducation =
            '';
        }
        var decentWorkAndEconomicGrowth =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'decentWorkAndEconomicGrowth'
          );

        if (decentWorkAndEconomicGrowth != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.DecentWorkandEconomicGrowth =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.DecentWorkandEconomicGrowth =
            '';
        }
        var responsibleProduction =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'responsibleProduction'
          );

        if (responsibleProduction != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ResponsibleProductionConsumption =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.ResponsibleProductionConsumption =
            '';
        }
        var partnerships =
          values.investmentGoalsSdg.sdgPreferences.indexOf('partnerships');
        if (partnerships != -1) {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.Partnerships =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          getImpactRiskScores.enterpriseimpactRiskScore.SGDBreakdown.Partnerships =
            '';
        }
      }

      var impactOutcomeScore =
        getImpactRiskScores.enterpriseimpactRiskScore.impactOutcome.score *
        0.15;
      var numberofGeographiesScore =
        getImpactRiskScores.enterpriseimpactRiskScore.numberofGeographies
          .score * 0.6;
      var numberofSDGAbove10PercentScore =
        getImpactRiskScores.enterpriseimpactRiskScore.numberofSDGAbove10Percent
          .score * 0.25;
      var ImpactScore =
        impactOutcomeScore +
        numberofGeographiesScore +
        numberofSDGAbove10PercentScore;
      var questionWeightSum = 0.15 + 0.6 + 0.25;
      ImpactScore = ImpactScore / questionWeightSum;
      getImpactRiskScores.enterpriseimpactRiskScore.ImpactScore = parseFloat(
        ImpactScore.toFixed(2)
      );
      var pillar6 = {
        investmentGoalsSdg: values.investmentGoalsSdg,
        geoPreferences: values.geoPreferences,
        yourTeamDescription: values.yourTeamDescription,
        updatesanyrelevantPR: values.updatesanyrelevantPR,
        statedimpactoutcome: values.statedimpactoutcome,
        SDGItems: values.SDGItems
      };
      console.log(pillar6);
      onSave({
        pillar6: pillar6,
        ImpactRiskScores: getImpactRiskScores
      });
      onNext();
      dispatch(userActions.get(user.id));
    },
    [
      dispatch,
      onSave,
      onNext,
      user.id,
      getImpactRiskScores,
      DecentWorkP,
      IndustryInnovation,
      ClimateAction
    ]
  );
  const handleCheckedChange = e => {
    if (e.target.checked) {
      sdgPreferencesValueV('');
      sdgprimarily('');
    }
  };

  const setSGD = (value, id) => {
    if (id == 0) {
      if (value.length < 3) {
        setDecentWorkP(value.replace(/\D/, ''));
      }
    } else if (id == 1) {
      if (value.length < 3) {
        setIndustryInnovation(value.replace(/\D/, ''));
      }
    } else {
      if (value.length < 3) {
        setClimateAction(value.replace(/\D/, ''));
      }
    }
  };
  const toolTipText = () => (
    <div>
      <p style={{ width: '100%' }}>
        Per The Impact Management Project (IMP) classification guide, the below
        are the categories of impact goals for any impact-driven enterprise or
        investor:
      </p>
      <p style={{ width: '100%' }}>
        <b>Act to Avoid Harm.</b> The enterprise prevents or reduces significant
        effects on important negative outcomes for people and the planet.
      </p>
      <p style={{ width: '100%' }}>
        <b>Benefit Stakeholders.</b> The enterprise not only acts to avoid harm,
        but also generates various effects on positive outcomes for people and
        the planet.
      </p>
      <p style={{ width: '100%' }}>
        <b>Contribute to Solutions </b> The enterprise not only acts to avoid
        harm, but also generates one or more significant effect(s) on positive
        outcomes for otherwise underserved people and the planet.
      </p>
      <p style={{ width: '100%' }}>
        For more information, please refer to: Impact Classes | Impact
        Management Project. Enterprises are expected to track their impact
        according to industry standard impact reporting standards.
      </p>
    </div>
  );

  return (
    <>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Your Enterprise Profile</strong>
        </Typography>
      </Box>
      <Grid container direction="column" spacing={3}>
        <Formik
          initialValues={initial}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <Grid item>
                <Card title="Define Your Business">
                  <Tooltip
                    placement="right-right"
                    arrow
                    display="flex"
                    title="Selecting your SDG, industry and geographic preferences to share with the ImpactCred community helps the right investors, enterprises and accelerators find you more easily. Please see https://sdgs.un.org/goals for additional information on the UN Sustainable Development Goals. If you were to estimate your business' spend allocated across each of these goals, how would you distribute it?"
                  >
                    <Typography variant="h4">
                      How would you define your business’ primary impact on the
                      Sustainable Development Goals?
                      <HelpOutlineIcon
                        style={{ fontSize: '20px', margin: '-4px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {sdgPreferencesValue ? (
                    <InputFeedback error={sdgPreferencesValue} />
                  ) : null}
                  <Grid container spacing={1}>
                    {investmentGoalsSdgItems.map(item => (
                      <Grid
                        item
                        key={`investment-goals-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="investmentGoalsSdg.sdgPreferences"
                            value={item.id}
                            onClick={e => handleCheckedChange(e)}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                onClick={e => handleCheckedChange(e)}
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                </Card>
              </Grid>
              {/*<Grid item display="flex">
                <Card title="Detail of SDG Involvement">
                  <Tooltip
                    placement="right-right"
                    arrow
                    display="flex"
                    title="Based on the SDGs you selected, ImpactCred showcases your SDG portfolio goal"
                  >
                    <Typography variant="h4">
                      If you were to estimate your business (take out
                      apostrophe) spend allocated across each of these goals,
                      how would you distribute it?
                      <HelpOutlineIcon
                        style={{ fontSize: '20px', margin: '-4px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {deployviaI ? <InputFeedback error={deployviaI} /> : null}
                  <Grid container display="flex">
                    {geoSDGItems.map(item => (
                      <Grid
                        item
                        key={`investment-goals-${item.id}`}
                        xs={12}
                        sm={12}
                        md={12}
                        display="flex"
                        justifyContent="space-Evenly"
                        spacing={20}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          m={3}
                        >
                          <Field
                            type="checkbox"
                            name="deployviaImpactCred"
                            value={item.label}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                checked={field.value === item.label}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography style={{ width: '44%' }}>
                            {item.label}
                          </Typography>
                          {item.id == 0 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={DecentWorkP}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                          {item.id == 1 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={IndustryInnovation}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                          {item.id == 2 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={ClimateAction}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                </Card>
              </Grid>*/}
              <Grid item>
                <Card title="Define Your Geographic Preferences">
                  <Typography variant="h4">
                    Where does your business primarily have impact?
                  </Typography>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {primarily ? <InputFeedback error={primarily} /> : null}
                  <Grid container spacing={1}>
                    {geoPreferencesItems.map(item => (
                      <Grid
                        item
                        key={`geo-preferences-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="geoPreferences"
                            onClick={e => handleCheckedChange(e)}
                            value={item.id}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                onClick={e => handleCheckedChange(e)}
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>
              <Grid item>
                <Card title="Help us share your story! ">
                  <Typography variant="h4">
                    Please provide your Team Description and any relevant links.
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <TextField
                    type="text"
                    variant="outlined"
                    name="yourTeamDescription"
                    style={{
                      marginBottom: '0',
                      width: '100%'
                    }}
                    value={values.yourTeamDescription}
                    error={
                      touched.yourTeamDescription && errors.yourTeamDescription
                    }
                    helperText={
                      touched.yourTeamDescription && errors.yourTeamDescription
                    }
                    onChange={e =>
                      setFieldValue('yourTeamDescription', e.target.value)
                    }
                  />
                  <Typography variant="h6">
                    Investors will see this on your public profile.
                  </Typography>
                  <Box my={2}></Box>
                  <Typography variant="h4">
                    Please provide any recent updates and any relevant links to
                    Press Releases.
                  </Typography>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <TextField
                    type="text"
                    variant="outlined"
                    name="updatesanyrelevantPR"
                    value={values.updatesanyrelevantPR}
                    style={{
                      marginBottom: '0',
                      width: '100%'
                    }}
                    onChange={e =>
                      setFieldValue('updatesanyrelevantPR', e.target.value)
                    }
                    error={
                      touched.updatesanyrelevantPR &&
                      errors.updatesanyrelevantPR
                    }
                    helperText={
                      touched.updatesanyrelevantPR &&
                      errors.updatesanyrelevantPR
                    }
                  />
                  <Typography variant="h6">
                    Investors will see this on your public profile.
                  </Typography>
                  <Box my={2}></Box>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                </Card>
              </Grid>
              <Grid item>
                <Card title="Define Your Impact Outcomes">
                  <Tooltip
                    arrow
                    placement="right-top"
                    display="flex"
                    title={toolTipText()}
                  >
                    <Typography variant="h4">
                      What is your business’ stated impact outcome?
                      <HelpOutlineIcon
                        style={{ fontSize: '20px', margin: '-4px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {errors.statedimpactoutcome && errors.statedimpactoutcome ? (
                    <InputFeedback error={errors.statedimpactoutcome} />
                  ) : null}
                  <Grid container spacing={1}>
                    {impactOutcome.map(item => (
                      <Grid
                        item
                        key={`investment-goals-${item.id}`}
                        xs={12}
                        sm={6}
                        md={6}
                        style={{ padding: '15px' }}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="radio"
                            name="statedimpactoutcome"
                            value={item.label}
                            control={
                              <StyledRadio
                                disableRipple
                                icon={<StyledCheckedIconTick />}
                                checkedIcon={<StyledCheckedTickIcon />}
                              />
                            }
                            style={{ padding: '10px' }}
                          >
                            {({ field }) => (
                              <FormControlLabel
                                color="primary"
                                checked={field.value === item.id}
                                control={
                                  <StyledRadio
                                    disableRipple
                                    icon={<StyledCheckedIconTick />}
                                    checkedIcon={<StyledCheckedTickIcon />}
                                  />
                                }
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="button"
                      onClick={onPrev}
                    >
                      Prev
                    </Button>
                    <Box mr={3} />
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      Next
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default EnterpriseEcosystem;

const ZeroHunger = styled.div`
  background: ${theme.palette.common.green1};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Zero Hunger';
    color: white;
    margin-left: 10px;
  }
`;

const LifeBelowWater = styled.div`
  background: ${theme.palette.common.main};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Life Below Water';
    color: white;
    margin-left: 10px;
  }
`;

const NoPoverty = styled.div`
  background: ${theme.palette.common.mainDark};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'No Poverty';
    color: white;
    margin-left: 10px;
  }
`;

const CircleImage = styled(Box)`
  color: white;
  width: 110px;
  height: 110px;
  padding: 20px;
  background-size: cover;
  background-image: url(${imageBG});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;
