import { enterpriseConstants } from '../constants/enterprise';

const initialState = {
  form: {},
  contractagreementsAttachment: {},
  enterprise: [],
  RiskScores: [],
  marketRiskPAD: [],
  impactRisk: [],
  matchEnterData: []
};

export function enterprise(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.GET_FORM_REQUEST:
      return state;
    case enterpriseConstants.GET_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case enterpriseConstants.GET_FORM_FAILURE:
      return state;
    case enterpriseConstants.CREATE_FORM_REQUEST:
      return state;
    case enterpriseConstants.CREATE_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case enterpriseConstants.CREATE_FORM_FAILURE:
      return state;
    case enterpriseConstants.UPDATE_FORM_REQUEST:
      return state;
    case enterpriseConstants.UPDATE_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case enterpriseConstants.UPDATE_FORM_FAILURE:
      return state;
    case enterpriseConstants.ADD_UPDATE_FORM_ATTACHMENT_REQUEST:
      return state;
    case enterpriseConstants.ADD_UPDATE_FORM_ATTACHMENT_SUCCESS:
      return {
        ...state,
        contractagreementsAttachment: {
          ...state.contractagreementsAttachment,
          ...action.contractagreementsAttachment
        }
      };
    case enterpriseConstants.ADD_UPDATE_FORM_ATTACHMENT_FAILURE:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_REQUEST:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_SUCCESS:
      return {
        ...state,
        enterprise: {
          ...state.enterprise,
          ...action.enterprise
        }
      };
    case enterpriseConstants.GET_ENTERPRISE_FAILURE:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_REQUEST:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_SUCCESS:
      return {
        ...state,
        RiskScores: {
          ...state.RiskScores,
          ...action.RiskScores
        }
      };
    case enterpriseConstants.GET_ENTERPRISE_FIANCIAL_RISK_SCORE_FAILURE:
      return state;
    case enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_REQUEST:
      return state;
    case enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_SUCCESS:
      return {
        ...state,
        marketRiskPAD: {
          ...state.marketRiskPAD,
          ...action.marketRiskPAD
        }
      };
    case enterpriseConstants.UPDATE_ENTERPRISE_MR_PAD_FAILURE:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_REQUEST:
      return state;
    case enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_SUCCESS:
      return {
        ...state,
        impactRisk: {
          ...state.impactRisk,
          ...action.impactRisk
        }
      };
    case enterpriseConstants.GET_ENTERPRISE_IMPACT_RISK_SCORE_FAILURE:
      return state;
    case enterpriseConstants.MATCH_ENTERPRISE_REQUEST:
      return state;
    case enterpriseConstants.MATCH_ENTERPRISE_SUCCESS:
      return {
        ...state,
        matchEnterData: {
          ...state.matchEnterData,
          ...action.matchEnterData
        }
      };
    case enterpriseConstants.MATCH_ENTERPRISE_FAILURE:
      return state;
    case enterpriseConstants.RESET_STATE:
      return {
        form: {},
        contractagreementsAttachment: {},
        enterprise: [],
        RiskScores: [],
        marketRiskPAD: []
      };
    default:
      return state;
  }
}
