import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Tooltip,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { userActions } from '../../../actions/userActions';
import theme from '../../../styles/theme';
import { history } from '../../../helpers/history';
import { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  readTermsAndPrivacy: yup.boolean().oneOf([true], 'Message'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
});

const Signup = props => {
  const dispatch = useDispatch();

  const { open, setOpen } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      readTermsAndPrivacy: false
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setShowSpinner(true);
      console.log(values);
      dispatch(
        userActions.register({
          email: values.email,
          password: values.password,
          readTermsAndPrivacy: values.readTermsAndPrivacy
        })
      )
        .then(() => {
          setOpen(false);
          setShowSpinner(false);
          history.push(`/role-select`);
        })
        .catch(err => {
          setShowSpinner(false);
        });
    }
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'}>
      <Title>Sign-up</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Email
              </Typography>
              <TextField
                placeholder="Enter your email address..."
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Password
                <Tooltip
                  arrow
                  placement="right-start"
                  display="flex"
                  title="Password must be at least 8 characters"
                >
                  <HelpOutlineIcon
                    style={{ fontSize: '18px', margin: '-3px 4px' }}
                  />
                </Tooltip>
              </Typography>
              <TextField
                placeholder="Enter your password..."
                variant="outlined"
                fullWidth
                id="password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.readTermsAndPrivacy}
                          onChange={formik.handleChange}
                          name="readTermsAndPrivacy"
                        />
                      }
                      //label="By signing up on ImpactCred you are consenting to our Terms of Use and Privacy Policy"
                    />
                  </FormGroup>
                </FormControl>
                <FormHelperText
                  style={{
                    color: formik.errors.readTermsAndPrivacy
                      ? '#ff0000'
                      : '#000',
                    marginLeft: '10px'
                  }}
                >
                  By signing up on ImpactCred you are consenting to our{' '}
                  <a
                    href="http://impactcred.com/TermsofUse.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="http://impactcred.com/PrivacyPolicy.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  {formik.errors.readTermsAndPrivacy}
                </FormHelperText>
              </Box>
              <Box mt={4}>
                <ContinueButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  {showSpinner ? (
                    <CircularProgress size="2rem" color="inherit" />
                  ) : (
                    <>
                      <Typography variant="button">
                        Signup with Email
                      </Typography>
                      <Typography variant="button">&gt;</Typography>
                    </>
                  )}
                </ContinueButton>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </form>
    </Dialog>
  );
};

const Title = styled(DialogTitle)`
  padding: ${theme.spacing(6, 15, 4.5, 10)};

  & h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 43px;
    line-height: 1;
  }

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const Content = styled(DialogContent)`
  padding: ${theme.spacing(0, 15, 4.5, 10)};

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const ContinueButton = styled(Button)`
  & .MuiButton-label {
    display: flex;
    justify-content: space-around;
  }
`;
export default Signup;
