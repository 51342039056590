import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { userActions } from '../../../actions/userActions';
import theme from '../../../styles/theme';
import { history } from '../../../helpers/history';
import { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
});

const Login = props => {
  const dispatch = useDispatch();
  const [showReset, setshowReset] = useState(false);
  const { open, setOpen } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setShowSpinner(true);
      dispatch(
        userActions.login({
          email: values.email,
          password: values.password
        })
      )
        .then(data => {
          console.log(data);
          if (data.role) {
            if (
              data.onboardingLeaveStep != 'intake' &&
              data.onboardingLeaveStep != 'complete'
            ) {
              history.push(`/${data.role.toLowerCase()}/onboarding`);
            } else if (data.onboardingLeaveStep == 'intake') {
              history.push(`/${data.role.toLowerCase()}/form`);
            } else {
              console.log(data.role.toLowerCase());
              history.push(`/${data.role.toLowerCase()}`);
            }
          } else {
            history.push(`/role-select`);
          }
          setOpen(false);
          setShowSpinner(false);
        })
        .catch(err => {
          setShowSpinner(false);
        });
    }
  });

  const handleClose = useCallback(() => {
    setOpen(false);
    setshowReset(false);
  }, [setOpen, setshowReset]);

  const onForgot = useCallback(() => {
    setOpen(false);
    setshowReset(true);
  }, [setshowReset, setOpen]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <Title>Log-in</Title>
        <form onSubmit={formik.handleSubmit}>
          <Content>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Email
                </Typography>
                <TextField
                  placeholder="Enter your email address..."
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Password
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Password must be at least 8 characters"
                  >
                    <HelpOutlineIcon
                      style={{ fontSize: '18px', margin: '-3px 4px' }}
                    />
                  </Tooltip>
                </Typography>
                <TextField
                  placeholder="Enter your password..."
                  variant="outlined"
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item>
                <Box mt={4}>
                  <ContinueButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    disableElevation
                    type="submit"
                  >
                    {showSpinner ? (
                      <CircularProgress size="2rem" color="inherit" />
                    ) : (
                      <>
                        <Typography variant="button">
                          Log In&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="button">&gt;</Typography>
                      </>
                    )}
                  </ContinueButton>
                </Box>
              </Grid>
              <Box
                mt={4}
                style={{
                  margin: '3px 9px',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
              >
                <Typography
                  onClick={onForgot}
                  variant="h5"
                  style={{ textAlign: 'right' }}
                >
                  Forgot Password
                </Typography>
              </Box>
            </Grid>
          </Content>
        </form>
      </Dialog>
      <ForgotPassword showReset={showReset} setshowReset={setshowReset} />
    </>
  );
};

const Title = styled(DialogTitle)`
  padding: ${theme.spacing(6, 15, 4.5, 10)};

  & h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 43px;
    line-height: 1;
  }

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const Content = styled(DialogContent)`
  padding: ${theme.spacing(0, 15, 4.5, 10)};

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const ContinueButton = styled(Button)`
  & .MuiButton-label {
    display: flex;
    justify-content: center;
  }
`;
export default Login;
