import { Box, Radio, Typography } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import tickMark from '../../../assets/images/tick.png';

export const Wrapper = styled(Box)`
  height: 100%;
  padding: 84px 60px;
  max-width: 1000px;
  background-color: ${props => props.backgroundcolor};
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export const Title = styled(Typography)`
  font-size: 88px;
  font-weight: 500;
  max-width: 738px;

  ${theme.breakpoints.down('md')} {
    font-size: 60px;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 40px;
  }
`;

export const Content = styled(Typography)`
  max-width: 738px;
`;

export const FormWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  padding: 30px 40px;
  max-width: 925px;

  ${theme.breakpoints.down('md')} {
    padding: 100px 68px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 80px 18px;
  }

  ${theme.breakpoints.down('xs')} {
    padding: 40px 16px;
  }
`;

export const BorderedRadioGroup = styled(Box)`
  border: 1px solid ${theme.palette.primary.dark};
  border-radius: 30px;
`;

export const StyledRadio = styled(Radio)`
  padding: 0;
`;

export const RadioLabel = styled(Typography)`
  font-size: 11px;
  width: 124px;
  margin-left: -24px;
  text-align: center;
`;
export const StyledCheckedIcon = () => {
  return (
    <svg width={24} height={24}>
      <circle cx={12} cy={12} r={12} fill={theme.palette.primary.dark}></circle>
    </svg>
  );
};

export const StyledCheckedTickIcon = () => {
  return (
    <img
      src={tickMark}
      alt="next"
      style={{
        height: '23px',
        marginTop: '-4px',
        marginRight: '3px'
      }}
    />
  );
};
export const StyledCheckedIconTick = () => {
  return (
    <svg width={24} height={24}>
      <circle cx={10} cy={10} r={10} fill={'#B1C4C3'}></circle>
    </svg>
  );
};

export const StyledIcon = () => {
  return (
    <svg width={24} height={24}>
      <circle
        cx={12}
        cy={12}
        r={11}
        fill={theme.palette.common.white}
        stroke={theme.palette.primary.dark}
      ></circle>
    </svg>
  );
};
