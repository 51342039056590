import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Grid,
  Button,
  Divider
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { adminActions } from '../../../actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required')
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  denyButton: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.gray
  }
}));
export default function Capabiltiy() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state);
  console.log(user);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: { email: '' },
    onSubmit: values => {
      values.url = window.location.origin;
      dispatch(adminActions.inviteUser(values));
    }
  });

  const Send = () => {
    //dispatch(adminActions.inviteUser({ email: 'user.id', url: window.location.origin }));
  };
  return (
    <div className={classes.root}>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            name="email"
            placeholder="Email"
            style={{ width: '300px' }}
            value={formik.values.email}
            onChange={formik.handleChange}
            fullWidth
          />
          <span>&nbsp;&nbsp;</span>
          <span>&nbsp;&nbsp;</span>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: '7px' }}
          >
            Send
          </Button>
        </form>
      </Grid>
      <Grid item xs={6}></Grid>
    </div>
  );
}
