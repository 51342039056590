import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import iconPie from '../../../assets/images/pie.svg';
import iconDownload from '../../../assets/images/download.svg';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {
  DownloadIcon,
  FileList,
  StyledDivider,
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
import { useDispatch, useSelector } from 'react-redux';
import { storage } from '../../../firebase/firebaseConfig';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  growthmilestones: yup.string().required('This field is required'),
  doYouHaveestablishedBoard: yup.string().required('This field is required'),
  rangerepresentstheFoundingTeam: yup
    .string()
    .required('This field is required')
  //listAdvisorsOrDirectors: yup.array().of(yup.string())
});

const initialValues = {
  growthMilestonesAchieved: '',
  teamExpertiseCombined: '',
  doYouHaveAdvisorsOrDirectors: '',
  rangerepresentstheFoundingTeam: '',
  listAdvisorsOrDirectors: [],
  doYouHaveestablishedBoard: ''
};

const rangerepresentstheFoundingTeam = [
  {
    id: '0',
    label: '10+ Years',
    score: '2',
    Weight: '0.075'
  },
  {
    id: '1',
    label: '5+ - 10 Years',
    score: '4',
    Weight: '0.075'
  },
  {
    id: '2',
    label: '3+ - 5 Years',
    score: '6',
    Weight: '0.075'
  },
  {
    id: '3',
    label: 'Less than 3 Yrs',
    score: '8',
    Weight: '0.075'
  },
  {
    id: '4',
    label: 'Less than 1 Yr',
    score: '10',
    Weight: '0.075'
  }
];

const Pillar2 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [getRiskScores, setRiskScores] = useState([]);
  const [BAV, setBAV] = useState('');
  const [listAdvisorsOrDirectors, setlistAdvisorsOrDirectors] = useState([]);
  useEffect(() => {
    if (value.listAdvisorsOrDirectors) {
      setlistAdvisorsOrDirectors(value.listAdvisorsOrDirectors);
    }
    setRiskScores(RiskScores);
  }, [value, RiskScores]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    validationSchema,
    onSubmit: values => {
      var finalTeamScoreFT = '';
      var finalTeamScoreBOA = '';
      var finalTeamScore = '';
      if (listAdvisorsOrDirectors.length == 0) {
        //setBAV('Please provide relevant agreements.');
        //return false;
      } else {
        //setBAV('');
      }
      var Weight = 0;
      values.listAdvisorsOrDirectors = listAdvisorsOrDirectors;
      var FoundingTeamScore = rangerepresentstheFoundingTeam.filter(
        e => e.label == values.rangerepresentstheFoundingTeam
      );
      if (FoundingTeamScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.fundingTeamExperience.score =
          FoundingTeamScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.fundingTeamExperience.answer =
          FoundingTeamScore[0].label;
        finalTeamScoreFT =
          FoundingTeamScore[0].Weight * FoundingTeamScore[0].score;
        Weight += parseFloat(FoundingTeamScore[0].Weight);
      }
      if (values.doYouHaveestablishedBoard == 'true') {
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.boardOfadvisors.score =
          '2';
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.boardOfadvisors.answer =
          'Yes';
        finalTeamScoreBOA = 0.025 * 2;
        Weight += 0.025;
      } else {
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.boardOfadvisors.score =
          '6';
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.boardOfadvisors.answer =
          'No';
        finalTeamScoreBOA = 0.025 * 6;
        Weight += 0.025;
      }
      finalTeamScore = finalTeamScoreFT + finalTeamScoreBOA;
      finalTeamScore = finalTeamScore / Weight;
      console.log(finalTeamScore);
      getRiskScores.enterpriseFinancialRiskScore.pillar2Team.finalTeamScore =
        parseFloat(finalTeamScore).toFixed(1);
      console.log(getRiskScores);
      onSave({
        pillar2Team: values,
        riskScore: getRiskScores
      });
      onNext();
    }
  });

  const listAdvisorsOrDirectorsA = event => {
    var ref = 'enterprise/advisors-or-directors';
    onUploadSubmission(event.target.files, 'financials', ref);
  };

  const onUploadSubmission = (files, type, ref) => {
    for (let i = 0; i < files.length; i++) {
      var fileName =
        files[i].name.split('.')[0] +
        '~' +
        Date.now() +
        '.' +
        files[i].name.split('.')[1];
      const uploadTask = storage.ref(`${ref}/${fileName}`).put(files[i]);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref(ref)
            .child(fileName)
            .getDownloadURL()
            .then(url => {
              var imageArray = {
                name: fileName,
                url: url,
                progress: progress
              };
              var valueesData = Object.keys(value).length
                ? value
                : initialValues;
              setlistAdvisorsOrDirectors(prevState => [
                ...prevState,
                imageArray
              ]);
              setBAV('');
              valueesData.listAdvisorsOrDirectors = [
                ...listAdvisorsOrDirectors,
                imageArray
              ];
              valueesData.doYouHaveestablishedBoard =
                formik.values.doYouHaveestablishedBoard;
              valueesData.growthmilestones = formik.values.growthmilestones;
              valueesData.rangerepresentstheFoundingTeam =
                formik.values.rangerepresentstheFoundingTeam;
              valueesData.leverageratio = formik.values.leverageratio;
              console.log(formik.values, '<=formik');
              console.log(valueesData, '<=valueesData');
              onSave({
                pillar2Team: valueesData
              });
            });
        }
      );
    }
  };
  const removeDocument = data => {
    var ref = 'enterprise/advisors-or-directors/' + data.name;
    var desertRef = storage.ref(ref);
    // Delete the file
    desertRef
      .delete()
      .then(function () {
        console.log('deleteed');
        var links1 = listAdvisorsOrDirectors.filter(e => e.name != data.name);
        if (links1.length == 0) {
          setBAV('Please provide relevant agreements.');
        }
        setlistAdvisorsOrDirectors(links1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card title="Pillar 3 of 5: Team" icon={iconPie}>
        <Box mb={2}>
          <Typography variant="h4">
            What growth milestones have you achieved so far?
          </Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          {formik.errors.growthmilestones && formik.errors.growthmilestones ? (
            <InputFeedback error={formik.errors.growthmilestones} />
          ) : null}
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="growthmilestones"
            name="growthmilestones"
            value={formik.values.growthmilestones}
            onChange={formik.handleChange}
            error={
              formik.touched.growthmilestones && formik.errors.growthmilestones
            }
            helperText={
              formik.touched.growthmilestones && formik.errors.growthmilestones
            }
          />
        </Box>
        <Box mb={1.5} mt={6}>
          <Typography variant="h4">
            Which range represents the Founding Team’s combined year(s) of
            industry expertise as relevant to this business?
          </Typography>
        </Box>
        <Divider />

        <Box mt={3} mb={3}>
          {formik.errors.rangerepresentstheFoundingTeam &&
          formik.errors.rangerepresentstheFoundingTeam ? (
            <InputFeedback
              error={formik.errors.rangerepresentstheFoundingTeam}
            />
          ) : null}
          <RadioGroup
            id="rangerepresentstheFoundingTeam"
            name="rangerepresentstheFoundingTeam"
            value={formik.values.rangerepresentstheFoundingTeam}
            onChange={formik.handleChange}
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              {rangerepresentstheFoundingTeam.map(item => (
                <Box mr={1} key={`investment-goals_2-${item.id}`}>
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label={item.label}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
        </Box>
        <Divider />
        <Box mb={1.5} mt={6}>
          <Typography variant="h4">
            Do you have an established Board of Advisors or Directors?
          </Typography>
        </Box>
        <Box mt={3} mb={3}>
          {formik.errors.doYouHaveestablishedBoard &&
          formik.errors.doYouHaveestablishedBoard ? (
            <InputFeedback error={formik.errors.doYouHaveestablishedBoard} />
          ) : null}
          <RadioGroup
            id="doYouHaveestablishedBoard"
            name="doYouHaveestablishedBoard"
            value={formik.values.doYouHaveestablishedBoard}
            onChange={formik.handleChange}
            error={
              formik.touched.doYouHaveestablishedBoard &&
              formik.errors.doYouHaveestablishedBoard
            }
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              <Box mr={3}>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      disableRipple
                      icon={<StyledCheckedIconTick />}
                      checkedIcon={<StyledCheckedTickIcon />}
                    />
                  }
                  label="Yes"
                />
              </Box>
              <FormControlLabel
                value="false"
                label="No"
                control={
                  <StyledRadio
                    disableRipple
                    icon={<StyledCheckedIconTick />}
                    checkedIcon={<StyledCheckedTickIcon />}
                  />
                }
              />
            </Box>
          </RadioGroup>
        </Box>
        {progress != 0 && progress != 100 && (
          <LinearProgress variant="determinate" value={progress} />
        )}
        {listAdvisorsOrDirectors.map((item, index) => (
          <FileList
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={1}
            key={`${index}`}
          >
            <Box display="flex">
              <DownloadIcon src={iconDownload} alt="download" />
              <Typography>
                {item.name.split('~')[0] +
                  '.' +
                  item.name.split('~')[1].split('.')[1]}
              </Typography>
            </Box>
            {/* <Typography>A description of the deal and terms</Typography> */}
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disableElevation
            >
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                id={item.name}
                style={{ color: '#000' }}
              >
                Download
              </a>
            </Button>
            <Box display="flex" onClick={e => removeDocument(item)}>
              <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
            </Box>
          </FileList>
        ))}
        <Box mb={1.5} mt={6}>
          {/*BAV && BAV ? <InputFeedback error={BAV} /> : null*/}
          <Typography variant="h4">
            Please share your list of Board of Advisors or Directors or both.
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" mt={4}>
          <Typography
            variant="h4"
            style={{ cursor: 'pointer' }}
            htmlFor="icon-button-file"
          >
            <input
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={event => {
                listAdvisorsOrDirectorsA(event);
              }}
            />
            <label htmlFor="raised-button-file">+ Add document</label>
          </Typography>
          <StyledDivider />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onPrev}
          >
            Prev
          </Button>
          <Box mr={3} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Next
          </Button>
        </Box>
      </Card>
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onSkip}
        >
          Skip for now
        </Button>
      </Box> */}
    </form>
  );
};

export default Pillar2;
