import { combineReducers } from 'redux';

import { user } from './userReducer';
import { enterprise } from './enterpriseReducer';
import { investor } from './investorReducer';
import { accelerator } from './acceleratorReducer';
import { adminR } from './adminReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

const rootReducer = combineReducers({
  user,
  enterprise,
  investor,
  accelerator,
  adminR,
  toastr: toastrReducer
});

export default rootReducer;
