import { useState, useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import { history } from '../../../helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from './Onboarding.styled';
import { investorActions } from '../../../actions/investorActions';
import { useCallback, useEffect } from 'react';
import CompleteProfile from './CompleteProfile';
import InvestingEcosystem1 from './InvestingEcosystem1';
import InvestingEcosystem2 from './InvestingEcosystem2';
import KnowYourCustomer from './KnowYourCustomer';
import { userActions } from '../../../actions/userActions';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { userRoles } from '../../../constants/user';
const MAX_STEP = 3;

const Content = props => {
  const { onNext, onPrev } = props;
  return (
    <>
      {props.children}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onPrev}
        >
          Prev
        </Button>
        <Box mr={3} />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onNext}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

const Onboarding = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [step, setStep] = useState(0);
  const [isSkip, setisSkip] = useState(false);
  const enterpriseSeekLoan = useSelector(state => state.enterprise.enterprise);
  useEffect(() => {
    user.id && dispatch(investorActions.investorFiancialRiskScores(user.id));
    user.id && dispatch(investorActions.investorImpactRiskScores(user.id));
    user && user.id && dispatch(investorActions.getForm(user.id));
    if (isSkip && user.onboardingLeaveStep >= 0 && user.onboardingLeaveStep) {
      console.log(user.onboardingLeaveStep);
      setStep(user.onboardingLeaveStep);
    }
    dispatch(enterpriseActions.getEnterprise());
  }, [isSkip, step, user, user.onboardingLeaveStep, dispatch]);

  const form = useSelector(state => state.investor.form);
  const RiskScores = useSelector(state => state.investor.RiskScores);
  const ImpactRiskScores = useSelector(
    state => state.investor.ImpactRiskScores
  );
  const onSave = useCallback(
    data => {
      if (form.id) {
        dispatch(
          investorActions.updateForm(form.id, {
            ...form.formAnswers,
            ...data
          })
        );
      } else {
        dispatch(investorActions.createForm(data));
      }
    },
    [dispatch, form.formAnswers, form.id]
  );

  const onSkip = useCallback(() => {
    var onboardingLeaveStep = step + 1;
    dispatch(userActions.update(user.id, { ...user, onboardingLeaveStep }));
    //history.push('/investor/home');
    return;
  }, [dispatch, step, user]);

  const onPrev = useCallback(() => {
    setisSkip(false);
    user.id && dispatch(investorActions.investorFiancialRiskScores(user.id));
    user.id && dispatch(investorActions.investorImpactRiskScores(user.id));
    if (step !== 0) {
      setStep(step - 1);
    }
  }, [step, user, dispatch]);

  const onNext = useCallback(async () => {
    if (step === MAX_STEP - 1) {
      var onboardingLeaveStep = 'complete';
      delete user['users'];
      delete user['type'];
      await dispatch(
        userActions.update(user.id, { ...user, onboardingLeaveStep })
      );
      setTimeout(() => history.push('/investor/home'), 3000);
      return;
    } else {
      setStep(step + 1);
      setisSkip(true);
      onboardingLeaveStep = step + 1;
      delete user['users'];
      delete user['type'];
      dispatch(userActions.update(user.id, { ...user, onboardingLeaveStep }));
    }
    dispatch(userActions.list());
  }, [step, setisSkip, user, dispatch]);
  var SeekLoan = 0;
  if (enterpriseSeekLoan.data) {
    SeekLoan = enterpriseSeekLoan.data.reduce(function (accumulator, item) {
      return accumulator + parseInt(item.seekingLoan);
    }, 0);
  }
  const accelerators = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.ACCELERATOR);
    }
    return [];
  }, [user]);
  const investors = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.INVESTOR);
    }
    return [];
  }, [user]);
  return (
    <Wrapper>
      {step === 0 && (
        <CompleteProfile SeekLoan={SeekLoan} onNext={onNext} onSkip={onSkip} />
      )}
      {step === 1 && (
        <InvestingEcosystem1
          onSave={onSave}
          form={form.formAnswers || {}}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          RiskScores={RiskScores}
          ImpactRiskScores={ImpactRiskScores}
          accelerators={accelerators}
          investors={investors}
          enterpriseSeekLoan={enterpriseSeekLoan}
        />
      )}
      {step === 2 && (
        <InvestingEcosystem2
          onSave={onSave}
          form={form.formAnswers || {}}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          RiskScores={RiskScores}
          ImpactRiskScores={ImpactRiskScores}
        />
      )}
      {step === 3 && <KnowYourCustomer onNext={onNext} />}
    </Wrapper>
  );
};

export default Onboarding;
