import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { userActions } from '../../../actions/userActions';
import theme from '../../../styles/theme';
import { history } from '../../../helpers/history';
import { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useParams } from 'react-router-dom';
const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  passwordConfirmation: yup
    .string()
    .required('Confirm password is required')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
});
const ResetPassword = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const [showSpinner, setShowSpinner] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setShowSpinner(true);
      dispatch(
        userActions.resetPassword({
          password: values.password,
          id: params.id
        })
      ).then(data => {
        console.log(data);
        setShowSpinner(false);
        history.push(`/`);
      });
    }
  });
  const handleClose = useCallback(() => {
    //setshowReset(false);
  }, []);
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth={'xs'}>
      <Title>Reset Password</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Password
                <Tooltip
                  arrow
                  placement="right-start"
                  display="flex"
                  title="Password must be at least 8 characters"
                >
                  <HelpOutlineIcon
                    style={{ fontSize: '18px', margin: '-3px 4px' }}
                  />
                </Tooltip>
              </Typography>
              <TextField
                placeholder="New Password"
                variant="outlined"
                fullWidth
                id="password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Confirm Password
              </Typography>
              <TextField
                placeholder="Confirm Password"
                variant="outlined"
                fullWidth
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
              />
            </Grid>
            <Grid item>
              <Box mt={4}>
                <ContinueButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  {showSpinner ? (
                    <CircularProgress size="2rem" color="inherit" />
                  ) : (
                    <>
                      <Typography variant="button">Reset Password</Typography>
                      <Typography variant="button">&gt;</Typography>
                    </>
                  )}
                </ContinueButton>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </form>
    </Dialog>
  );
};

const Title = styled(DialogTitle)`
  padding: ${theme.spacing(6, 15, 4.5, 10)};

  & h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 43px;
    line-height: 1;
  }

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const Content = styled(DialogContent)`
  padding: ${theme.spacing(0, 15, 4.5, 10)};

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const ContinueButton = styled(Button)`
  & .MuiButton-label {
    display: flex;
    justify-content: space-around;
  }
`;
export default ResetPassword;
