import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';

const RATING_TYPES = {
  positive: { color: theme.palette.common.main },
  netural: { color: theme.palette.common.red },
  negative: { color: theme.palette.common.yellow },
  norating: { color: theme.palette.common.green1 }
};

const RiskAlignment = ({ enterpriseScore, activeFormAnswers, activeData }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(enterpriseScore);
  const data = [
    {
      positive: 1.5,
      netural: 2.5,
      negative: 4.5,
      norating: 1.5,
      value: 0
    },
    {
      positive: 1.5,
      netural: 2.5,
      negative: 4.5,
      norating: 1.5,
      value: 0
    },
    {
      positive: 1.5,
      netural: 2.5,
      negative: 4.5,
      norating: 1.5,
      value: 0
    },
    {
      positive: 1.5,
      netural: 2.5,
      negative: 4.5,
      norating: 1.5,
      value: 0
    }
  ];
  var totalPer = 0;

  if (enterpriseScore && enterpriseScore.enterpriseFinancialRiskScore) {
    var score_ = enterpriseScore.enterpriseFinancialRiskScore;
    if (score_.pillar1Earnings) {
      data[0].value = score_.pillar1Earnings.finalEarningsScore;
    }
    if (score_.pillar3Fundamentals) {
      data[1].value = score_.pillar3Fundamentals.finalFundamentalsScore;
    }
    if (score_.pillar5MarketRisk) {
      data[2].value = score_.pillar5MarketRisk.finalMarketRiskScore;
    }
    if (score_.pillar4Qualitative) {
      data[3].value = score_.pillar4Qualitative.finalQualitativeScore;
    }
    totalPer = enterpriseScore.enterpriseFinancialRiskScore.finalFinRiskScore;
  }

  return (
    <div id="f_A">
      <Card title="Risk Alignment">
        <Box mx={2} mb={1}>
          <Typography variant="h4">
            In order to evaluate its readiness for investment,{' '}
            {activeData && activeData.organization} financial performance was
            evaluated across four categories: Earnings, Fundamentals, Market
            Risk and Qualitative. Qualitative includes an assessment of the
            team, existence of governing Board and investment readiness
            experiences. In comparing its relative risk across its peer group on
            ImpactCred, {activeData && activeData.organization} is in the{' '}
            {enterpriseScore &&
              enterpriseScore.enterpriseFinancialRiskScore &&
              (
                (1 -
                  enterpriseScore.enterpriseFinancialRiskScore
                    .finalFinRiskScore /
                    10) *
                100
              ).toFixed(0)}
            {''} percentile compared to companies in its peer group.
          </Typography>
        </Box>
        <Divider />
        <Box
          mx={2}
          my={3}
          display="flex"
          alignItems="center"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Box display="flex" flexDirection="column" alignItems="center" mr={4}>
            <PercentageLabel variant="h1">
              {enterpriseScore &&
                enterpriseScore.enterpriseFinancialRiskScore &&
                (
                  (1 -
                    enterpriseScore.enterpriseFinancialRiskScore
                      .finalFinRiskScore /
                      10) *
                  100
                ).toFixed(0)}
            </PercentageLabel>
            <Typography variant="h4">Percentile</Typography>
          </Box>
          {/*<Typography variant="h4">
            {activeFormAnswers &&
              activeFormAnswers.pillar1Earnings &&
              activeFormAnswers.pillar1Earnings.explainBusinessModel}
          </Typography>*/}
        </Box>
        <Divider />
        <StyledChart display="flex" justifyContent="space-between" m={3}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography>
              <strong></strong>
            </Typography>
            <Typography>
              <strong></strong>
            </Typography>
            <Typography>
              <strong>Average</strong>
            </Typography>
            <Typography>
              <strong></strong>
            </Typography>
            <Typography>
              <strong>Risk Profile</strong>
            </Typography>
          </Box>
          {data.map((item, index) => (
            <Bars key={`rating-${index}`}>
              {Object.keys(item).map((type, i) =>
                type !== 'value' ? (
                  <Bar
                    key={`rating-bar-${index}-${i}`}
                    color={RATING_TYPES[type].color}
                    value={item[type] * 10}
                  />
                ) : (
                  <Value value={(10 - item[type]) * 10}>{item[type]}</Value>
                )
              )}
              <Typography style={{ marginTop: '15px' }}>
                <strong>{index == 0 ? 'Earnings' : ''}</strong>
                <strong>{index == 1 ? 'Fundamentals' : ''}</strong>
                <strong>{index == 2 ? 'MarketRisk' : ''}</strong>
                <strong>{index == 3 ? 'Qualitative ' : ''}</strong>
              </Typography>
            </Bars>
          ))}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography>
              <strong>10</strong>
            </Typography>
            <Typography>
              <strong>7</strong>
            </Typography>
            <Typography>
              <strong>3</strong>
            </Typography>
            <Typography>
              <strong>NR</strong>
            </Typography>
          </Box>
        </StyledChart>
      </Card>
    </div>
  );
};

const StyledChart = styled(Box)`
  height: 615px;
  ${theme.breakpoints.down('xs')} {
    height: 320px;
  }
`;

const Bars = styled(Box)`
  position: relative;
  width: 32px;

  ${theme.breakpoints.down('xs')} {
    width: 16px;
  }
`;

const Bar = styled.div`
  background-color: ${props => props.color};
  width: 100%;
  height: ${props => props.value}%;
`;

const Value = styled.div`
  position: absolute;
  top: ${props => props.value}%;
  background: #88c8f9;
  height: 20px;
  width: 100%;
  margin-top: -10px;
  text-align: center;
  color: ${theme.palette.common.white};
  font-size: 12px;
  line-height: 20px;

  &:after {
    content: '';
    position: absolute;
    right: -16px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 17.3px;
    border-color: transparent transparent transparent #88c8f9;
  }

  ${theme.breakpoints.down('xs')} {
    height: 14px;
    margin-top: -7px;
    line-height: 14px;

    &:after {
      right: -12px;
      border-width: 7px 0 7px 12.1px;
    }
  }
`;

export const PercentageLabel = styled(Typography)`
  font-size: 74px;
  font-weight: 700;
  margin-bottom: ${theme.spacing(1)}px;
`;

export default RiskAlignment;
