import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';

const PendingApproval = () => {
  return (
    <Wrapper>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Your Business Profile</strong>
        </Typography>
      </Box>
      <Card title={'Under Review'}>
        <Typography variant="h4">
          Thank you! Your profile is under review by our staff; you will be
          notified if we have further questions or approve your account.
        </Typography>
      </Card>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  height: 100%;
  max-width: 918px;
  padding: 84px 60px;
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export default PendingApproval;
