import { toastr } from 'react-redux-toastr';
import authHeader from '../helpers/authHeader';

export const acceleratorService = {
  getProfile,
  AddUpdateProfile,
  sendEnterpriseInvite,
  SaveEnterpriseInviteQueAns,
  getEnterpriseInviteQueAns,
  getaccelerator
};

const BASEURL = process.env.REACT_APP_API_URL;
const toastrOptions = {
  timeOut: 3000, // by setting to 0 it will prevent the auto close
  position: 'bottom-right'
};
function getProfile(id) {
  const url = `/accelerator/get-profile/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function AddUpdateProfile(body) {
  const url = `/accelerator/add-update-profile`;

  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Profile updated successfully', toastrOptions);
      return response.data;
    });
}

function sendEnterpriseInvite(body) {
  const url = `/accelerator/enterprise-invite`;

  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Invitation sent successfully', toastrOptions);
      return response.data;
    });
}

function SaveEnterpriseInviteQueAns(body) {
  console.log(body);
  const url = `/accelerator/save-enterprise-ques-ans`;

  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function getEnterpriseInviteQueAns(user_id, accelerator_id, flag) {
  const url = `/accelerator/get-enterprise-invite-que-ans/${accelerator_id}/${user_id}/${flag}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function getaccelerator() {
  const url = `/accelerator/get-accelerator/`;

  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      toastr.error('Error', error);
      return Promise.reject(error);
    }

    //toastr.success('Success', data.message);
    return data;
  });
}
