import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { userActions } from '../../../actions/userActions';
import theme from '../../../styles/theme';
import { history } from '../../../helpers/history';
import { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
});

const ForgotPassword = props => {
  const dispatch = useDispatch();
  console.log(props);
  const { showReset, setshowReset } = props;
  const [showSpinner, setShowSpinner] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setShowSpinner(true);
      dispatch(
        userActions.forgotPassword({
          email: values.email,
          url: window.location.origin
        })
      )
        .then(data => {
          console.log(data);
          //setshowReset(false);
          setShowSpinner(false);
          setshowReset(false);
          //history.push(`/role-select`);
        })
        .catch(err => {
          setShowSpinner(false);
        });
    }
  });

  const handleClose = useCallback(() => {
    setshowReset(false);
  }, [setshowReset]);

  return (
    <Dialog
      open={showReset}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <Title>Forgot Password</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Email
              </Typography>
              <TextField
                placeholder="Enter your email address..."
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item>
              <Box mt={4}>
                <ContinueButton
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  {showSpinner ? (
                    <CircularProgress size="2rem" color="inherit" />
                  ) : (
                    <>
                      <Typography variant="button">Forgot Password</Typography>
                      <Typography variant="button">&gt;</Typography>
                    </>
                  )}
                </ContinueButton>
              </Box>
            </Grid>
          </Grid>
        </Content>
      </form>
    </Dialog>
  );
};

const Title = styled(DialogTitle)`
  padding: ${theme.spacing(6, 15, 4.5, 10)};

  & h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 43px;
    line-height: 1;
  }

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const Content = styled(DialogContent)`
  padding: ${theme.spacing(0, 15, 4.5, 10)};

  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(3)}px;
  }
`;

const ContinueButton = styled(Button)`
  & .MuiButton-label {
    display: flex;
    justify-content: space-around;
  }
`;
export default ForgotPassword;
