export const enterpriseConstants = {
  GET_FORM_REQUEST: 'ENTERPRISE_GET_FORM_REQUEST',
  GET_FORM_SUCCESS: 'ENTERPRISE_GET_FORM_SUCCESS',
  GET_FORM_FAILURE: 'ENTERPRISE_GET_FORM_FAILURE',

  CREATE_FORM_REQUEST: 'ENTERPRISE_CREATE_FORM_REQUEST',
  CREATE_FORM_SUCCESS: 'ENTERPRISE_CREATE_FORM_SUCCESS',
  CREATE_FORM_FAILURE: 'ENTERPRISE_CREATE_FORM_FAILURE',

  UPDATE_FORM_REQUEST: 'ENTERPRISE_UPDATE_FORM_REQUEST',
  UPDATE_FORM_SUCCESS: 'ENTERPRISE_UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE: 'ENTERPRISE_UPDATE_FORM_FAILURE',

  ADD_UPDATE_FORM_ATTACHMENT_REQUEST: 'ADD_UPDATE_FORM_ATTACHMENT_REQUEST',
  ADD_UPDATE_FORM_ATTACHMENT_SUCCESS: 'ADD_UPDATE_FORM_ATTACHMENT_SUCCESS',
  ADD_UPDATE_FORM_ATTACHMENT_FAILURE: 'ADD_UPDATE_FORM_ATTACHMENT_FAILURE',

  GET_ENTERPRISE_REQUEST: 'GET_ENTERPRISE_REQUEST',
  GET_ENTERPRISE_SUCCESS: 'GET_ENTERPRISE_SUCCESS',
  GET_ENTERPRISE_FAILURE: 'GET_ENTERPRISE_FAILURE',

  GET_ENTERPRISE_FIANCIAL_RISK_SCORE_REQUEST:
    'GET_ENTERPRISE_FIANCIAL_RISK_SCORE_REQUEST',
  GET_ENTERPRISE_FIANCIAL_RISK_SCORE_SUCCESS:
    'GET_ENTERPRISE_FIANCIAL_RISK_SCORE_SUCCESS',
  GET_ENTERPRISE_FIANCIAL_RISK_SCORE_FAILURE:
    'GET_ENTERPRISE_FIANCIAL_RISK_SCORE_FAILURE',
  RESET_STATE: 'RESET_STATE',

  GET_ENTERPRISE_IMPACT_RISK_SCORE_REQUEST:
    'GET_ENTERPRISE_IMPACT_RISK_SCORE_REQUEST',
  GET_ENTERPRISE_IMPACT_RISK_SCORE_SUCCESS:
    'GET_ENTERPRISE_IMPACT_RISK_SCORE_SUCCESS',
  GET_ENTERPRISE_IMPACT_RISK_SCORE_FAILURE:
    'GET_ENTERPRISE_IMPACT_RISK_SCORE_FAILURE',

  UPDATE_ENTERPRISE_MR_PAD_REQUEST: 'UPDATE_ENTERPRISE_MR_PAD_REQUEST',
  UPDATE_ENTERPRISE_MR_PAD_SUCCESS: 'UPDATE_ENTERPRISE_MR_PAD_SUCCESS',
  UPDATE_ENTERPRISE_MR_PAD_FAILURE: 'UPDATE_ENTERPRISE_MR_PAD_FAILURE',

  MATCH_ENTERPRISE_REQUEST: 'MATCH_ENTERPRISE_REQUEST',
  MATCH_ENTERPRISE_SUCCESS: 'MATCH_ENTERPRISE_SUCCESS',
  MATCH_ENTERPRISE_FAILURE: 'MATCH_ENTERPRISE_FAILURE'
};
