import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';

import Accelerator from './Accelerator/Accelerator';
import Enterprise from './Enterprise/Enterprise';
import Investor from './Investor/Investor';
import Capabiltiy from './Capabiltiy/Capabiltiy';

import theme from '../../styles/theme';
import { userRoles } from '../../constants/user';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/userActions';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const Admin = () => {
  const [activeTab, setActiveTab] = useState(userRoles.ENTERPRISE);
  const handleTabChange = useCallback((event, newTab) => {
    console.log(newTab);
    setActiveTab(newTab);
  }, []);
  const user = useSelector(state => state.user);
  const EnterprisesWithScore = useSelector(state => state.user.enterpriseScore);
  const InvestorWithScore = useSelector(state => state.user.investorScore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.list());
    dispatch(userActions.getAllEnterprisesWithScore());
    dispatch(userActions.getAllInvestorsWithScore());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApproveOrDeny = useCallback(
    (which, status) => async () => {
      await dispatch(userActions.approveOrDeny(which, status));
      dispatch(userActions.list());
      dispatch(userActions.getAllEnterprisesWithScore());
    },
    [dispatch]
  );

  const accelerators = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.ACCELERATOR);
    }
    return [];
  }, [user]);
  const enterprises = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.ENTERPRISE);
    }
  }, [user]);
  const investors = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.INVESTOR);
    }
  }, [user]);

  console.log(EnterprisesWithScore);

  return (
    <Wrapper>
      <Paper>
        <AppBar position="static" color="default">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="user-table"
          >
            <Tab value={userRoles.ENTERPRISE} label="Enterprises" />
            <Tab value={userRoles.INVESTOR} label="Investors" />
            <Tab value={userRoles.ACCELERATOR} label="Accelerators" />
            <Tab value={'INVITE'} label="INVITE" />
          </Tabs>
        </AppBar>
        <TabPanel value={activeTab} index={userRoles.ENTERPRISE}>
          <Enterprise
            rows={EnterprisesWithScore}
            onAction={handleApproveOrDeny}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={userRoles.INVESTOR}>
          <Investor rows={InvestorWithScore} onAction={handleApproveOrDeny} />
        </TabPanel>
        <TabPanel value={activeTab} index={userRoles.ACCELERATOR}>
          <Accelerator rows={accelerators} onAction={handleApproveOrDeny} />
        </TabPanel>
        <TabPanel value={activeTab} index="INVITE">
          <Capabiltiy />
        </TabPanel>
      </Paper>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  height: 100%;
  max-width: 1400px;
  padding: 84px 20px;
  margin: 0 auto;
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export default Admin;
