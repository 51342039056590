import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Layout from './components/common/Layout/Layout';
import NotFound from './components/NotFound/NotFound';
import PrivateRoute from './components/common/PrivateRoute/PrivateRoute';
import Enterprise from './components/Enterprise/Enterprise';
import Investor from './components/Investor/Investor';
import Accelerator from './components/Accelerator/Accelerator';
import Admin from './components/Admin/Admin';
import Home from './components/Home/Home';
import RoleSelect from './components/RoleSelect/RoleSelect';
import { history } from './helpers/history';
import { adminRole, userRoles } from './constants/user';
import SearchEnterprise from './components/Enterprise/Search/Search';
import SearchInvestor from './components/Investor/Search/Search';
import SearchAccelerator from './components/Accelerator/Search/Search';
import ResetPassword from './components/common/ResetPassword/ResetPassword';
const App = () => {
  return (
    <Router history={history}>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route path="/role-select" exact component={RoleSelect} />
          <PrivateRoute
            path="/search-enterprise"
            exact
            component={SearchEnterprise}
          />
          <PrivateRoute
            path="/search-investor"
            exact
            component={SearchInvestor}
          />
          <PrivateRoute
            path="/search-accelerator"
            exact
            component={SearchAccelerator}
          />
          <PrivateRoute
            path="/enterprise"
            role={userRoles.ENTERPRISE}
            component={Enterprise}
          />
          <PrivateRoute
            path="/investor"
            role={userRoles.INVESTOR}
            component={Investor}
          />
          <PrivateRoute
            path="/accelerator"
            role={userRoles.ACCELERATOR}
            component={Accelerator}
          />
          <PrivateRoute path="/admin" role={adminRole} component={Admin} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
