import styled from 'styled-components';
import Card from '../../common/Card/Card';
import iconDealdetail from '../../../assets/images/dealdetail.svg';
import { Box, Typography, Grid, FormControlLabel } from '@material-ui/core';
import theme from '../../../styles/theme';
import {
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from '../Onboarding/Onboarding.styled';
const GoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];
const ImpactDetails = activeFormAnswers => {
  return (
    <div id="m_d">
      <Card title="Impact Details" icon={iconDealdetail}>
        <Typography>DEAL TITLE IMPACT DETAIL</Typography>
        <Grid container spacing={1}>
          {activeFormAnswers &&
            activeFormAnswers.activeFormAnswers &&
            activeFormAnswers.activeFormAnswers.pillar6 &&
            activeFormAnswers.activeFormAnswers.pillar6.investmentGoalsSdg &&
            Object.keys(
              activeFormAnswers.activeFormAnswers.pillar6.investmentGoalsSdg
                .sdgPreferences
            ).length > 0 &&
            activeFormAnswers.activeFormAnswers.pillar6.investmentGoalsSdg.sdgPreferences.map(
              item => (
                <Grid item key={`geoPre_-${item}`} xs={12} sm={6} md={4}>
                  <Box style={{ marginLeft: '10px', marginTop: '30px' }}>
                    <FormControlLabel
                      style={{ textTransform: 'capitalize' }}
                      checked={true}
                      control={
                        <StyledRadio
                          disableRipple
                          icon={<StyledCheckedIconTick />}
                          checkedIcon={<StyledCheckedTickIcon />}
                        />
                      }
                      label={GoalsSdgItems.filter(e => e.id == item)[0].label}
                    />
                  </Box>
                </Grid>
              )
            )}
        </Grid>
      </Card>
    </div>
  );
};

const ZeroHunger = styled.div`
  background: ${theme.palette.common.green1};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;
`;

const LifeBelowWater = styled.div`
  background: ${theme.palette.common.main};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;
`;

const NoPoverty = styled.div`
  background: ${theme.palette.common.mainDark};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;
`;

export default ImpactDetails;
