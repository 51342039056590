import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  Link,
  Tooltip
} from '@material-ui/core';
import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import theme from '../../../styles/theme';
import nextIcon from '../../../assets/images/next.svg';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { Title, Content, FormWrapper } from './Onboarding.styled';
import { COUNTRY_LIST, US_STATES } from '../../../constants/strings';
import Card from '../../common/Card/Card';
import iconAvatar from '../../../assets/images/avatarSmall.svg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import classNames from 'classnames';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  first: yup.string().required('First Name is required'),
  last: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  dob: yup.string().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  //organization: yup.string().required('Organization Name is required'),
  description: yup.string().required('Description is required'),
  city: yup.string().required('City Name is required'),
  //state: yup.string(),
  country: yup.string().required('Country Name is required')
});

const CompleteProfile = ({ onNext, onSkip, SeekLoan }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  localStorage.setItem('SeekLoan', SeekLoan);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [StateError, setStateError] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first: user.first || '',
      last: user.last || '',
      email: user.email || '',
      dob: user.dob || '',
      gender: user.gender || '',
      organization: user.organization || '',
      city: user.city || '',
      state: user.state || '',
      country: user.country || '',
      description: user.description || ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (values.country == 'USA') {
        if (!values.state) {
          setStateError('State Name is required');
          return false;
        }
      } else {
        setStateError('');
      }
      dispatch(userActions.update(user.id, values));
      onNext();
    }
  });

  const getCountry = (e, value) => {
    formik.setFieldValue('country', value);
    if (value !== 'USA') {
      setStateError('');
      formik.setFieldValue('state', '');
    } else {
      setStateError('State Name is required');
    }
  };

  return (
    <>
      <Box mb={12}>
        <Title>You&apos;re approved!</Title>
      </Box>
      <Box mb={12} pl={2}>
        <Typography variant="h4">
          We are so glad to see you at ImpactCred! ImpactCred is an ecosystem of
          investors, social enterprises and accelerators which is aligned in its
          goal to boost capital access to social enterprises both at the early
          stage and in between funding rounds. Thank you for considering
          investing in some of these hand-picked companies who are seeking
          competitively priced working capital and bridge financing. Your
          ImpactCred portfolio will boost your ability to have direct impact on
          the UN sustainable development goals while improving financial yield
          through alternative investments.
        </Typography>
      </Box>
      <Box pl={2}>
        {/* <Content variant="h4">
          Our recommendation is that you seek out companies with a “Below
          Average” Risk Rating with Kshetra, as these will best fit your
          financial goals. On the Dealflow, we’ll help you see how different
          investments in companies can help you achieve this risk balance.
        </Content> */}
      </Box>
      <Box mt={8} mb={5}>
        <Typography variant={isMobile ? 'h3' : 'h1'}>
          Complete Your Profile
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <Grid container spacing={5}>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>First Name</Typography>
              <TextField
                id="first"
                name="first"
                placeholder="First Name"
                variant="outlined"
                fullWidth
                value={formik.values.first}
                onChange={formik.handleChange}
                error={formik.touched.first && Boolean(formik.errors.first)}
                helperText={formik.touched.first && formik.errors.first}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Last Name</Typography>
              <TextField
                id="last"
                name="last"
                placeholder="Last Name"
                variant="outlined"
                fullWidth
                value={formik.values.last}
                onChange={formik.handleChange}
                error={formik.touched.last && Boolean(formik.errors.last)}
                helperText={formik.touched.last && formik.errors.last}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Email</Typography>
              <TextField
                id="email"
                name="email"
                placeholder="Email"
                variant="outlined"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Tooltip
                arrow
                display="flex"
                title="This information is collected if needed for a future KYC process"
              >
                <Typography variant="subtitle2">
                  Date of Birth
                  <HelpOutlineIcon
                    style={{ fontSize: '20px', margin: '-4px 4px' }}
                  />
                </Typography>
              </Tooltip>
              <TextField
                id="dob"
                name="dob"
                placeholder="Date of Birth"
                variant="outlined"
                fullWidth
                value={formik.values.dob}
                onChange={formik.handleChange}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Tooltip
                arrow
                display="flex"
                title="This information is collected if needed for a future KYC process"
              >
                <Typography variant="subtitle2">
                  Gender
                  <HelpOutlineIcon
                    style={{ fontSize: '20px', margin: '-4px 4px' }}
                  />
                </Typography>
              </Tooltip>
              <TextField
                id="gender"
                name="gender"
                variant="outlined"
                fullWidth
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                select
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Tooltip
                arrow
                display="flex"
                title="Skip this if you are an individual investor."
              >
                <Typography variant="subtitle2">
                  Organization
                  <HelpOutlineIcon
                    style={{ fontSize: '20px', margin: '-4px 4px' }}
                  />
                </Typography>
              </Tooltip>
              <TextField
                id="organization"
                name="organization"
                placeholder="Organization"
                variant="outlined"
                fullWidth
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>City</Typography>
              <TextField
                id="city"
                name="city"
                placeholder="City Name"
                variant="outlined"
                fullWidth
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Tooltip
                placement="right-right"
                arrow
                display="flex"
                title="Please complete the Country selection before choosing the State. State is not required if Organization is based outside of the US"
              >
                <Typography variant="subtitle2">
                  State
                  <HelpOutlineIcon
                    style={{ fontSize: '20px', margin: '-4px 4px' }}
                  />
                </Typography>
              </Tooltip>
              <Autocomplete
                fullWidth
                id="state"
                name="state"
                value={formik.values.state}
                onChange={(e, value) => {
                  formik.setFieldValue('state', value);
                  setStateError('');
                }}
                disabled={formik.values.country !== 'USA'}
                options={US_STATES}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="State Name"
                    error={Boolean(formik.touched.state && formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                )}
              />
              {StateError ? <InputFeedback error={StateError} /> : null}
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Country</Typography>
              <Autocomplete
                fullWidth
                id="country"
                name="country"
                value={formik.values.country}
                onChange={(e, value) => {
                  getCountry(e, value);
                }}
                options={COUNTRY_LIST}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Country Name"
                    error={Boolean(
                      formik.touched.country && formik.errors.country
                    )}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
        </FormWrapper>
      </form>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={7} />
        <Card title="Description" icon={iconAvatar}>
          <Typography variant="h4">
            In order to be able to showcase your profile on ImpactCred, please
            describe yourself. Ex: Organization Name is an Institutional
            Investor focused on Asia and Africa with an investment preference
            for the following SDGs: Decent Work and Economic Growth.
          </Typography>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
          />
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              startIcon={<img src={nextIcon} alt="next" />}
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Card>
        {/* <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onSkip}
          >
            Skip for now
          </Button>
        </Box> */}
      </form>
    </>
  );
};

export default CompleteProfile;
