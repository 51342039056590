import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';

import Card from '../../common/Card/Card';
import DealDetails from '../common/DealDetails';
import ImpactDetails from '../common/ImpactDetails';
import RiskAlignment from '../common/RiskAlignment';

import iconSend from '../../../assets/images/send.svg';
import iconAvatar from '../../../assets/images/avatarSmall.svg';
import iconDealdetail from '../../../assets/images/dealdetail.svg';
import iconArrow from '../../../assets/images/arrowLeft.svg';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-scroll';
const HomeEnterprise = () => {
  const dispatch = useDispatch();
  const isLayoutSM = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector(state => state.user);
  const enterpriseScore = useSelector(state => state.enterprise.RiskScores);
  const [activeFormAnswers, setActiveFormAnswers] = useState();
  const activeData = useSelector(state => state.user);
  useEffect(() => {
    user && user.id && dispatch(enterpriseActions.getForm(user.id));
    user &&
      user.id &&
      dispatch(enterpriseActions.enterpriseFiancialRiskScores(user.id));
    user &&
      user.id &&
      dispatch(enterpriseActions.getForm(user.id)).then(response => {
        setActiveFormAnswers(response.formAnswers);
      });
  }, [dispatch, user]);
  const [type, setType] = useState('details');
  const form = useSelector(state => state.enterprise.form);
  const handleChangeType = newType => {
    setType(newType);
  };
  console.log(form.formAnswers);
  return (
    <Wrapper>
      <Grid container spacing={isLayoutSM ? 2 : 5}>
        <Grid item xs={12} sm={4}>
          {user.first && user.last && (
            <Title variant="h1">{`${user.organization}`}</Title>
          )}
          {/* <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TypeButton
                  disableElevation
                  onClick={() => handleChangeType('details')}
                  className={type === 'details' && 'active'}
                  fullWidth
                >
                  Details
                </TypeButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TypeButton
                  disableElevation
                  onClick={() => handleChangeType('forum')}
                  className={type === 'forum' && 'active'}
                  fullWidth
                >
                  Forum
                </TypeButton>
              </Grid>
            </Grid>
          </Box> */}
          <Box mt={6}>
            <Card title="Table of Contents">
              <Typography>
                Navigate directly to detailed information about this deal!
              </Typography>
              <List component="nav" aria-label="secondary mailbox folders">
                <Link activeClass="active" to="de_d" spy={true} smooth={true}>
                  <ListItem button>
                    <ListItemText primary="Deal Details" />
                    <ListItemSecondaryAction>
                      <img src={iconArrow} alt="" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
                <Link activeClass="active" to="m_d" spy={true} smooth={true}>
                  <ListItem button>
                    <ListItemText primary="Impact Alignment" />
                    <ListItemSecondaryAction>
                      <img src={iconArrow} alt="" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
                <Link activeClass="active" to="f_A" spy={true} smooth={true}>
                  <ListItem button>
                    <ListItemText primary="Financial Alignment" />
                    <ListItemSecondaryAction>
                      <img src={iconArrow} alt="" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
                {/* <ListItem button>
                  <ListItemText primary="Recent Updates" />
                  <img src={iconArrow} alt="" />
                </ListItem> */}
                <Link activeClass="active" to="f_" spy={true} smooth={true}>
                  <ListItem button>
                    <ListItemText primary="Founding Team" />
                    <ListItemSecondaryAction>
                      <img src={iconArrow} alt="" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
                <Link activeClass="active" to="rb_" spy={true} smooth={true}>
                  <ListItem button>
                    <ListItemText primary="Recent Milestones" />
                    <ListItemSecondaryAction>
                      <img src={iconArrow} alt="" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
              </List>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          {/* <Box mt={8} mb={3}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              startIcon={<img src={iconSend} alt="invest" />}
            >
              Invest
            </Button>
          </Box> */}
          <DealDetails />
          <Box mt={4} />
          <ImpactDetails activeFormAnswers={activeFormAnswers} />
          <Box mt={4} />
          <RiskAlignment
            enterpriseScore={enterpriseScore}
            activeFormAnswers={activeFormAnswers}
            activeData={activeData}
          />
          <Box mt={4} />
          {/* <Card title="Recent Updates" icon={iconDealdetail}>
            <Typography variant="h4">
              Project description. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi.
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="h4">
              Project description. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi.
            </Typography>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="h4">
              Project description. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi.
            </Typography>
          </Card> */}
          <Box mt={4} />
          <div id="f_">
            <Card title="Founding team" icon={iconAvatar}>
              <Typography variant="h4">Team description</Typography>
              <Box my={2}>
                <Divider />
              </Box>
              <Typography variant="h4">
                {activeFormAnswers && activeFormAnswers.pillar6
                  ? activeFormAnswers.pillar6.yourTeamDescription
                  : 'N/A'}
              </Typography>
              {/* <TextField
              multiline
              rows={5}
              fullWidth
              variant="outlined"
            ></TextField> */}
            </Card>
          </div>
          <div id="rb_">
            <Card title="Recent Milestones" icon={iconAvatar}>
              {/*<Typography variant="h4">Team description</Typography>*/}
              {/*<Box my={2}>
                <Divider />
              </Box>*/}
              <Typography variant="h4">
                {activeFormAnswers && activeFormAnswers.pillar6
                  ? activeFormAnswers.pillar6.updatesanyrelevantPR
                  : 'N/A'}
              </Typography>
              {/* <TextField
              multiline
              rows={5}
              fullWidth
              variant="outlined"
            ></TextField> */}
            </Card>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  padding: ${theme.spacing(2, 5, 5, 2)};
  font-size: 11px;
  color: ${theme.palette.common.black};
`;

export const Title = styled(Typography)`
  font-weight: 700;
`;

export const TypeButton = styled(Button)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.common.black};
  border-radius: 100px;
  height: 20px;
  font-size: 11px;

  &.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.black};
  }
`;

export const ExploreButton = styled(Button)`
  height: 32px;
`;

export default HomeEnterprise;
