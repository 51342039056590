import { Route, Switch, Redirect } from 'react-router';
import IntakeForm from './IntakeForm/IntakeForm';
import Onboarding from './Onboarding/Onboarding';
import Profile from './Profile/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { investorActions } from '../../actions/investorActions';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PendingApproval from './PendingApproval/PendingApproval';

const Investor = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    user && user.id && dispatch(investorActions.getForm(user.id));
  }, [user, dispatch]);

  return (
    <Switch>
      <Route path="/investor/form" exact component={IntakeForm} />
      <Route path="/investor/onboarding" component={Onboarding} />
      <Route path="/investor/pending-approval" component={PendingApproval} />
      <PrivateRoute path="/investor/home" exact component={Profile} />
      <Redirect from="/investor" to="/investor/home" />
    </Switch>
  );
};

export default Investor;
