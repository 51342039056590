import { userConstants } from '../constants/user';
import jwt_decode from 'jwt-decode';

const token = JSON.parse(localStorage.getItem('TOKEN'));
let initialState = {};
if (token) {
  const { id, email, role } = jwt_decode(token);
  initialState = {
    id,
    email,
    role,
    users: [],
    enterpriseScore: [],
    investorScore: []
  };
}

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return state;
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case userConstants.LOGIN_FAILURE:
      return state;
    case userConstants.LOGOUT:
      return {};
    case userConstants.REGISTER_REQUEST:
      return state;
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case userConstants.REGISTER_FAILURE:
      return state;
    case userConstants.SET_USER:
      return action.user;
    case userConstants.GET_USER_REQUEST:
      return state;
    case userConstants.GET_USER_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case userConstants.GET_USER_FAILURE:
      return state;
    case userConstants.GETALL_REQUEST:
      return state;
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        ...action
      };
    case userConstants.GETALL_FAILURE:
      return state;
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return state;
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return state;
    case userConstants.RESET_PASSWORD_REQUEST:
      return state;
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action
      };
    case userConstants.RESET_PASSWORD_FAILURE:
      return state;
    case userConstants.DELETE_USER_REQUEST:
      return state;
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        ...action
      };
    case userConstants.DELETE_USER_FAILURE:
      return state;
    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case userConstants.UPDATE_USER_FAILURE:
      return { ...state, updateError: action.error };
    case userConstants.APPROVE_DENY_REQUEST:
    case userConstants.APPROVE_DENY_SUCCESS:
      return {
        ...state
      };
    case userConstants.APPROVE_DENY_FAILURE:
      return { ...state, updateError: action.error };
    case userConstants.UPDATE_TOKEN_SUCCESS:
      return state;
    case userConstants.ENTERPRISES_WITH_SCORE_REQUEST:
      return state;
    case userConstants.ENTERPRISES_WITH_SCORE_SUCCESS:
      return {
        ...state,
        enterpriseScore: [...action.enterpriseScore]
      };
    case userConstants.ENTERPRISES_WITH_SCORE_FAILURE:
      return state;
    case userConstants.INVESTOR_WITH_SCORE_REQUEST:
      return state;
    case userConstants.INVESTOR_WITH_SCORE_SUCCESS:
      return {
        ...state,
        investorScore: [...action.investorScore]
      };
    case userConstants.INVESTOR_WITH_SCORE_FAILURE:
      return state;
    default:
      return state;
  }
}
