import { investorConstants } from '../constants/investor';
import { investorService } from '../services/investorService';

export const investorActions = {
  getForm,
  createForm,
  updateForm,
  CheckInvestorForm,
  investorFiancialRiskScores,
  investorImpactRiskScores
};

function getForm(id) {
  return dispatch => {
    dispatch(request());
    return investorService.getForm(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: investorConstants.GET_FORM_REQUEST };
  }
  function success(form) {
    return { type: investorConstants.GET_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: investorConstants.GET_FORM_FAILURE, error };
  }
}

function investorFiancialRiskScores(id) {
  return dispatch => {
    dispatch(request());
    return investorService.investorFiancialRiskScores(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_REQUEST };
  }
  function success(RiskScores) {
    return {
      type: investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_SUCCESS,
      RiskScores
    };
  }
  function failure(error) {
    return {
      type: investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_FAILURE,
      error
    };
  }
}

function investorImpactRiskScores(id) {
  return dispatch => {
    dispatch(request());
    dispatch(deleteData());
    return investorService.investorImpactRiskScores(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };
  function deleteData() {
    return { type: investorConstants.RESET_STATE };
  }
  function request() {
    return { type: investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_REQUEST };
  }
  function success(ImpactRiskScores) {
    return {
      type: investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_SUCCESS,
      ImpactRiskScores
    };
  }
  function failure(error) {
    return {
      type: investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_FAILURE,
      error
    };
  }
}

function createForm(formAnswers) {
  return dispatch => {
    dispatch(request());

    investorService.createForm({ formAnswers }).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: investorConstants.CREATE_FORM_REQUEST };
  }
  function success(form) {
    return { type: investorConstants.CREATE_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: investorConstants.CREATE_FORM_FAILURE, error };
  }
}

function updateForm(id, formAnswers) {
  return dispatch => {
    dispatch(request());

    investorService.updateForm(id, { formAnswers }).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: investorConstants.UPDATE_FORM_REQUEST };
  }
  function success(form) {
    return { type: investorConstants.UPDATE_FORM_SUCCESS, form };
  }
  function failure(error) {
    return { type: investorConstants.UPDATE_FORM_FAILURE, error };
  }
}

function CheckInvestorForm() {
  return dispatch => {
    dispatch(request());
    return investorService.CheckInvestorForm().then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: investorConstants.CHECK_INVESTOR_FORM_REQUEST };
  }
  function success(checkform) {
    console.log(checkform);
    return { type: investorConstants.CHECK_INVESTOR_FORM_SUCCESS, checkform };
  }
  function failure(error) {
    return { type: investorConstants.CHECK_INVESTOR_FORM_FAILURE, error };
  }
}
