import React, { useMemo, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel
} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import imageBG from '../../../assets/images/circleBackground.svg';
import Card from '../../common/Card/Card';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { userRoles } from '../../../constants/user';
import { UserStatus } from '../../../constants';
import { investorActions } from '../../../actions/investorActions';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import { Link } from 'react-scroll';
import {
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from '../Onboarding/Onboarding.styled';
const geoPrItems = [
  {
    id: 'all',
    label: 'All Geographies'
  },
  {
    id: 'asia',
    label: 'Asia'
  },
  {
    id: 'australia',
    label: 'Australia'
  },
  { id: 'africa', label: 'Africa' },
  { id: 'northAmerica', label: 'North America' },
  { id: 'southAmerica', label: 'South America' },
  { id: 'europe', label: 'Europe' }
];

const GoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];
const SearchInvestor = () => {
  const isLayoutSM = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const investors = useSelector(state => state.investor.checkform);
  const [activeData, setActiveData] = useState();
  const [BioDes, setBioDes] = useState('');
  const [activeFormAnswers, setActiveFormAnswers] = useState();
  const investorFin = useSelector(state => state.investor.RiskScores);
  useEffect(() => {
    dispatch(investorActions.CheckInvestorForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExplore = item => {
    setActiveData(item);
    if (item.description) {
      setBioDes(item.description);
    } else {
      setBioDes('');
    }
    dispatch(investorActions.getForm(item.id)).then(response => {
      setActiveFormAnswers(response.formAnswers);
    });
    dispatch(investorActions.investorFiancialRiskScores(item.id));
  };
  return (
    <Wrapper style={{ height: investors.length == 0 ? '1000px' : 'auto' }}>
      {investors.length == 0 && (
        <Grid item style={{ heigth: '1000px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ heigth: '1000px', width: '100%' }}
            mt={10}
            mb={0}
          >
            <CircularProgress size={40} status={'loading'} />
          </Box>
        </Grid>
      )}
      {Object.keys(investors).length > 0 && (
        <Grid container spacing={isLayoutSM ? 2 : 5}>
          <Grid item xs={12} sm={4}>
            <Title variant="h1">Investing Ecosystem</Title>
            <Box mt={4}>
              {investors &&
                investors.status &&
                investors.data.map((item, idx) => (
                  <Box mb={2} key={`explore-${idx}`}>
                    <Card title={`${item.organization}`}>
                      <Typography variant="h5">
                        {item.description && item.description.length > 200
                          ? item.description.substring(0, 200) + '...'
                          : item.description}
                      </Typography>
                      <Box mt={2}>
                        <Link
                          activeClass="active"
                          to="m_d"
                          spy={true}
                          smooth={true}
                        >
                          <ExploreButton
                            color="primary"
                            variant="outlined"
                            fullWidth
                            onClick={() => handleExplore(item)}
                          >
                            Explore
                          </ExploreButton>
                        </Link>
                      </Box>
                    </Card>
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} id="m_d">
            {activeData && activeFormAnswers ? (
              <>
                <Box mt={2} mb={3}>
                  <Typography variant="h3">{`${activeData.first} ${activeData.last}`}</Typography>
                </Box>
                <Card title="Public Deal Details">
                  {/* <Grid container spacing={2}>
                    <Grid item sm={12} md={4} container justify="space-between">
                      <Box>
                        <Typography variant="h2">$100,000</Typography>
                        <Typography>INVESTED WITH IMPACTCRED</Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12} md={4} container justify="space-between">
                      <Box>
                        <Typography variant="h2">2 years</Typography>
                        <Typography>
                          AVERAGE TIME HORIZON FOR REPAYMENT
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12} md={4}>
                      <Typography variant="h2">13%</Typography>
                      <Typography>
                        RETURN HORIZONAVERAGE RETURN ON INVESTMENT
                      </Typography>
                    </Grid>
                  </Grid> */}
                  <Box mt={1} mb={2}>
                    <Divider />
                  </Box>
                  <Typography variant="h4">{BioDes}</Typography>
                  <Box mt={4} mb={1}>
                    <Divider />
                  </Box>
                  <Grid container>
                    <Grid item xs={6} container spacing={1}>
                      <Grid item>
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Neutral' && <CircleImage>Neutral</CircleImage>}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Act to Avoid Harm' && (
                            <CircleImage>Avoid Harm</CircleImage>
                          )}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Benefit Stakeholders' && (
                            <CircleImage>Benefit Stakeholders</CircleImage>
                          )}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Contribute to Solutions' && (
                            <CircleImage>Contribute 2 Solutions</CircleImage>
                          )}
                      </Grid>
                      <Grid item xs>
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Neutral' && (
                            <Typography>
                              Investor seeks companies that actively seek to
                              stay Neutral in their communities.
                            </Typography>
                          )}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Act to Avoid Harm' && (
                            <Typography>
                              Investor seeks companies that actively seek to
                              avoid harm in their communities
                            </Typography>
                          )}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Benefit Stakeholders' && (
                            <Typography>
                              Investor seeks companies that actively seek to
                              benefit stakeholders in their communities.
                            </Typography>
                          )}
                        {activeFormAnswers &&
                          activeFormAnswers.impactPortfolio &&
                          activeFormAnswers.impactPortfolio.impactProfile &&
                          activeFormAnswers.impactPortfolio.impactProfile ==
                            'Contribute to Solutions' && (
                            <Typography>
                              Investor seeks companies that actively seek to
                              contribute to solutions in their communities.
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                    <Grid item xs={6} container spacing={1}>
                      <Grid item>
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore <= 2 && (
                            <CircleImage>Below Average</CircleImage>
                          )}
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore >= 2.1 &&
                          investorFin.finalRiskScore <= 4 && (
                            <CircleImage>Average</CircleImage>
                          )}
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore >= 4.1 && (
                            <CircleImage>Above Average</CircleImage>
                          )}
                      </Grid>
                      <Grid item xs>
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore <= 2 && (
                            <Typography>
                              Investor’s goal is to seek out companies with a
                              “Below Average” Risk Rating.
                            </Typography>
                          )}
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore >= 2.1 &&
                          investorFin.finalRiskScore <= 4 && (
                            <Typography>
                              Investor’s goal is to seek out companies with a
                              “Average” Risk Rating.
                            </Typography>
                          )}
                        {investorFin &&
                          investorFin.finalRiskScore &&
                          investorFin.finalRiskScore >= 4.1 && (
                            <Typography>
                              Investor’s goal is to seek out companies with a
                              “Above Average” Risk Rating.
                            </Typography>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
                <Box mt={4} />
                {/*<Card title="My Impact Profile">
                  <Grid container>
                    <Grid item xs={12} container spacing={1}>
                      <Grid item>
                        <CircleImage>Targeting Change</CircleImage>
                      </Grid>
                      <Grid item xs>
                        <Typography>
                          My impact investment profile best matches the
                          Targeting Change profile.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>*/}
                <Box mt={4} />
                <Card title="Sustainability Goals">
                  <Typography>
                    Investor’s primary objectives involve Zero Hunger
                  </Typography>
                  <Grid container spacing={1}>
                    {activeFormAnswers &&
                      activeFormAnswers.investmentGoalsSdg &&
                      Object.keys(
                        activeFormAnswers.investmentGoalsSdg.sdgPreferences
                      ).length > 0 &&
                      activeFormAnswers.investmentGoalsSdg.sdgPreferences.map(
                        item => (
                          <Grid
                            item
                            key={`geoPre_-${item}`}
                            xs={12}
                            sm={6}
                            md={3}
                          >
                            <Box
                              style={{ marginLeft: '10px', marginTop: '30px' }}
                            >
                              <FormControlLabel
                                style={{ textTransform: 'capitalize' }}
                                checked={true}
                                control={
                                  <StyledRadio
                                    disableRipple
                                    icon={<StyledCheckedIconTick />}
                                    checkedIcon={<StyledCheckedTickIcon />}
                                  />
                                }
                                label={
                                  GoalsSdgItems.filter(e => e.id == item)[0]
                                    .label
                                }
                              />
                            </Box>
                          </Grid>
                        )
                      )}
                  </Grid>
                </Card>
                <Box mt={4} />
                <Card title="Geographic Preferences">
                  <Grid container spacing={1}>
                    {activeFormAnswers &&
                      activeFormAnswers.geoPreferences &&
                      activeFormAnswers.geoPreferences.map(item => (
                        <Grid
                          item
                          key={`geo-preferences-${item}`}
                          xs={12}
                          sm={6}
                          md={3}
                        >
                          <Box
                            style={{ marginLeft: '10px', marginTop: '30px' }}
                          >
                            <FormControlLabel
                              style={{ textTransform: 'capitalize' }}
                              checked={true}
                              control={
                                <StyledRadio
                                  disableRipple
                                  icon={<StyledCheckedIconTick />}
                                  checkedIcon={<StyledCheckedTickIcon />}
                                />
                              }
                              label={
                                geoPrItems.filter(e => e.id == item)[0].label
                              }
                            />
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Card>
              </>
            ) : (
              <Typography>Click on Explore to view more information</Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  padding: ${theme.spacing(2, 5, 5, 2)};
  font-size: 11px;
  color: ${theme.palette.common.black};

  & .MuiInputBase-root {
    height: 34px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 700;
`;

export const TypeButton = styled(Button)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.common.black};
  border-radius: 100px;
  height: 20px;
  font-size: 11px;

  &.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.black};
  }
`;

export const ExploreButton = styled(Button)`
  height: 32px;
`;

const ZeroHunger = styled.div`
  background: ${theme.palette.common.green1};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Zero Hunger';
    color: white;
    margin-left: 10px;
  }
`;

const LifeBelowWater = styled.div`
  background: ${theme.palette.common.main};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Life Below Water';
    color: white;
    margin-left: 10px;
  }
`;

const NoPoverty = styled.div`
  background: ${theme.palette.common.mainDark};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'No Poverty';
    color: white;
    margin-left: 10px;
  }
`;

const CircleImage = styled(Box)`
  color: white;
  width: 130px;
  height: 130px;
  padding: 18px;
  background-size: cover;
  background-image: url(${imageBG});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;

export default SearchInvestor;
