export default function authHeader() {
  // return authorization header with jwt token
  let token = JSON.parse(localStorage.getItem('TOKEN'));

  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}
