import { userConstants } from '../constants/user';
import { history } from '../helpers/history';
import { userService } from '../services/userService';

export const userActions = {
  login,
  logout,
  register,
  token,
  setRole,
  list,
  get,
  update,
  approveOrDeny,
  forgotPassword,
  resetPassword,
  getAllEnterprisesWithScore,
  getAllInvestorsWithScore,
  deleteUser
};

function login({ email, password }) {
  return dispatch => {
    dispatch(request());

    return userService.login({ email, password }).then(
      response => {
        return userService.get(response.data.id).then(data => {
          dispatch(success(data));
          return Promise.resolve(data);
        });
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function register(user) {
  return dispatch => {
    dispatch(request());

    return userService.register(user).then(
      response => {
        dispatch(success(response.data));
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error.toString()));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function forgotPassword(user) {
  return dispatch => {
    dispatch(request());

    return userService.forgotPassword(user).then(
      response => {
        dispatch(success(response.data));
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error.toString()));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(user) {
  return dispatch => {
    dispatch(request());

    return userService.resetPassword(user).then(
      response => {
        dispatch(success(response.data));
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error.toString()));
        return Promise.reject();
      }
    );
  };

  function request() {
    return { type: userConstants.RESET_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: userConstants.RESET_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    userService.logout();

    dispatch({ type: userConstants.LOGOUT });
  };
}

function token(body) {
  return dispatch => {
    userService.token(body).then(token => {
      dispatch(success(token));
    });
  };

  function success(token) {
    return { type: userConstants.UPDATE_TOKEN_SUCCESS, token };
  }
}

function update(id, body) {
  return dispatch => {
    dispatch(request());

    userService.update(id, body).then(
      () => {
        dispatch(success(body));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}

function approveOrDeny(id, status) {
  return dispatch => {
    dispatch(request());

    return userService.approveOrDeny(id, status).then(
      () => {
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.UPDATE_USER_REQUEST };
  }
  function success() {
    return { type: userConstants.UPDATE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}

function list() {
  return dispatch => {
    dispatch(request());

    userService.list().then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getAllEnterprisesWithScore() {
  return dispatch => {
    dispatch(request());

    userService.getAllEnterprisesWithScore().then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.ENTERPRISES_WITH_SCORE_REQUEST };
  }
  function success(enterpriseScore) {
    return {
      type: userConstants.ENTERPRISES_WITH_SCORE_SUCCESS,
      enterpriseScore
    };
  }
  function failure(error) {
    return { type: userConstants.ENTERPRISES_WITH_SCORE_FAILURE, error };
  }
}

function getAllInvestorsWithScore() {
  return dispatch => {
    dispatch(request());

    userService.getAllInvestorsWithScore().then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.INVESTOR_WITH_SCORE_REQUEST };
  }
  function success(investorScore) {
    return {
      type: userConstants.INVESTOR_WITH_SCORE_SUCCESS,
      investorScore
    };
  }
  function failure(error) {
    return { type: userConstants.INVESTOR_WITH_SCORE_FAILURE, error };
  }
}

function get(id) {
  return dispatch => {
    dispatch(request());

    userService.get(id).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error };
  }
}

function setRole(id, body, from) {
  return dispatch => {
    dispatch(request(body));

    userService.update(id, body).then(
      () => {
        userService.token(body).then(
          () => {
            dispatch(success(body));
            history.push(from);
          },
          error => {
            dispatch(failure(error.toString()));
          }
        );
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.UPDATE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error };
  }
}

function deleteUser(id, type) {
  return dispatch => {
    dispatch(request());

    return userService.deleteUser(id, type).then(
      () => {
        dispatch(success());
      },
      error => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.DELETE_USER_REQUEST };
  }
  function success() {
    return { type: userConstants.DELETE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.DELETE_USER_FAILURE, error };
  }
}
