import { investorConstants } from '../constants/investor';

const initialState = {
  form: {},
  checkform: [],
  RiskScores: [],
  ImpactRiskScores: []
};

export function investor(state = initialState, action) {
  switch (action.type) {
    case investorConstants.GET_FORM_REQUEST:
      return state;
    case investorConstants.GET_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case investorConstants.GET_FORM_FAILURE:
      return state;
    case investorConstants.CREATE_FORM_REQUEST:
      return state;
    case investorConstants.CREATE_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case investorConstants.CREATE_FORM_FAILURE:
      return state;
    case investorConstants.UPDATE_FORM_REQUEST:
      return state;
    case investorConstants.UPDATE_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      };
    case investorConstants.UPDATE_FORM_FAILURE:
      return state;
    case investorConstants.CHECK_INVESTOR_FORM_REQUEST:
      return state;
    case investorConstants.CHECK_INVESTOR_FORM_SUCCESS:
      return {
        ...state,
        checkform: {
          ...state.checkform,
          ...action.checkform
        }
      };
    case investorConstants.CHECK_INVESTOR_FORM_FAILURE:
      return state;
    case investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_REQUEST:
      return state;
    case investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_SUCCESS:
      return {
        ...state,
        RiskScores: {
          ...state.RiskScores,
          ...action.RiskScores
        }
      };
    case investorConstants.GET_INVESTOR_FIANCIAL_RISK_SCORE_FAILURE:
      return state;
    case investorConstants.RESET_STATE:
      return {
        form: {},
        checkform: [],
        RiskScores: [],
        ImpactRiskScores: []
      };
    case investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_REQUEST:
      return state;
    case investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_SUCCESS:
      return {
        ...state,
        ImpactRiskScores: {
          ...state.ImpactRiskScores,
          ...action.ImpactRiskScores
        }
      };
    case investorConstants.GET_INVESTOR_IMPACT_RISK_SCORE_FAILURE:
      return state;
    default:
      return state;
  }
}
