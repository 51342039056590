import { toastr } from 'react-redux-toastr';
import authHeader from '../helpers/authHeader';

export const adminService = { inviteUser };

const BASEURL = process.env.REACT_APP_API_URL;

function inviteUser(body) {
  const url = `/admin/invite-user`;

  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      toastr.error('Error', error);
      return Promise.reject(error);
    }

    toastr.success('Success', data.message);
    return data;
  });
}
