import {
  Box,
  Grid,
  Divider,
  useMediaQuery,
  Button,
  Checkbox,
  TextField,
  Tooltip
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import { Formik, Field, Form } from 'formik';
import { useMemo } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import iconMinus from '../../../assets/images/minusRect.svg';
import imageBG from '../../../assets/images/circleBackground.svg';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import classNames from 'classnames';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  StyledCheckedIcon,
  StyledIcon,
  StyledRadio,
  RadioLabel,
  BorderedRadioGroup,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const initialValues = {
  investmentGoalsSdg: {
    sdgPreferences: {}
  },
  geoPreferences: {},
  otherImpactGoals: {},
  deployviaImpactCredPrice0: '',
  deployviaImpactCredPrice1: '',
  deployviaImpactCredPrice2: '',
  deployviaImpactCred: {},
  achievingImpact: {}
};
const investmentGoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];

const geoSDGItems = [
  {
    id: '0',
    label: 'Decent Work and Economic Growth',
    perc: '20%'
  },
  {
    id: '1',
    label: 'Industry Innovation & Infrastructure',
    perc: '30%'
  },
  {
    id: '2',
    label: 'Climate Action',
    perc: '50%'
  }
];

const geoSDGItemsS = [
  {
    DecentWork: '',
    value: ''
  },
  {
    IndustryInnovation: '',
    value: ''
  },
  {
    ClimateAction: '',
    value: ''
  }
];

const geoPreferencesItems = [
  {
    id: 'all',
    label: 'All Geographies'
  },
  {
    id: 'asia',
    label: 'Asia'
  },
  {
    id: 'australia',
    label: 'Australia'
  },
  { id: 'africa', label: 'Africa' },
  { id: 'northAmerica', label: 'North America' },
  { id: 'southAmerica', label: 'South America' },
  { id: 'europe', label: 'Europe' }
];

const otherImpactGoalsItems = [
  { id: 'womenFocused', label: 'Women Focused' },
  { id: 'resourceDistribution', label: 'Resource Distribution' },
  { id: 'childrenFocused', label: 'Children Focused' },
  { id: 'economicAid', label: 'Economic Aid' }
];

const achievingImpact = [
  {
    id: 'Agriculture',
    label: 'Agriculture'
  },
  {
    id: 'Air',
    label: 'Air'
  },
  {
    id: 'Biodiversity & Ecosystems',
    label: 'Biodiversity & Ecosystems'
  },
  { id: 'Climate', label: 'Climate' },
  { id: 'Diversity & Inclusion', label: 'Diversity & Inclusion' },
  { id: 'Education', label: 'Education' },
  { id: 'Employment', label: 'Employment' },
  { id: 'Energy', label: 'Energy' },
  { id: 'Financial Services', label: 'Financial Services' },
  { id: 'Health', label: 'Health' },
  { id: 'Real Estate', label: 'Real Estate' },
  { id: 'Land', label: 'Land' },
  { id: 'Oceans & Coastal Zones', label: 'Oceans & Coastal Zones' },
  { id: 'Pollution', label: 'Pollution' },
  { id: 'Waste', label: 'Waste' },
  { id: 'Cross Category', label: 'Cross Category' },
  { id: 'Infrastructure', label: 'Infrastructure' }
];

const InvestingEcosystem2 = ({
  onSave,
  form,
  RiskScores,
  onNext,
  onPrev,
  onSkip,
  ImpactRiskScores
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [getRiskScores, setRiskScores] = useState([]);
  const [getImpactRiskScores, setImpactRiskScores] = useState([]);
  const [primarily, sdgprimarily] = useState('');
  const [AchievingImpact, setAchievingImpact] = useState('');
  const [sdgPreferencesValue, sdgPreferencesValueV] = useState('');
  const [DecentWorkP, setDecentWorkP] = useState('');
  const [IndustryInnovation, setIndustryInnovation] = useState('');
  const [ClimateAction, setClimateAction] = useState('');
  const [deployviaI, setdeployviaI] = useState('');
  useEffect(() => {
    setRiskScores(RiskScores);
    setImpactRiskScores(ImpactRiskScores);
  }, [RiskScores, ImpactRiskScores]);
  const initial = useMemo(() => ({ ...initialValues, ...form }), [form]);

  const onSubmit = useCallback(
    values => {
      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 0) {
        sdgPreferencesValueV('This field is required');
        return;
      } else {
        sdgPreferencesValueV('');
      }
      if (Object.keys(values.achievingImpact).length == 0) {
        setAchievingImpact('This field is required');
        return;
      } else {
        setAchievingImpact('');
      }

      // if (Object.keys(values.deployviaImpactCred).length == 0) {
      //   setdeployviaI('This field is required');
      //   return;
      // } else {
      //   setdeployviaI('');
      // }

      var SGDTotal =
        parseInt(DecentWorkP) +
        parseInt(IndustryInnovation) +
        parseInt(ClimateAction);

      // if (values.deployviaImpactCred.length != 3 || isNaN(SGDTotal)) {
      //   setdeployviaI('This field is required');
      //   return;
      // } else {
      //   setdeployviaI('');
      // }

      // if (SGDTotal != 100) {
      //   setdeployviaI('The percentage must be equal to 100% in 3 fields');
      //   return;
      // } else {
      //   setdeployviaI('');
      // }
      values.SDGItems = geoSDGItemsS;
      values.SDGItems[0].DecentWork = true;
      values.SDGItems[1].IndustryInnovation = true;
      values.SDGItems[2].ClimateAction = true;
      values.SDGItems[0].value = DecentWorkP;
      values.SDGItems[1].value = IndustryInnovation;
      values.SDGItems[2].value = ClimateAction;
      console.log(values.SDGItems);
      if (values.geoPreferences.length == 0) {
        sdgprimarily('This field is required');
        return;
      } else {
        sdgprimarily('');
      }

      values.investorFinancialRiskScore = getRiskScores;
      values.investorImpactRiskScore = getImpactRiskScores;
      if (values.geoPreferences.length == 1) {
        values.investorImpactRiskScore.numberofGeographies.answer = 1;
        values.investorImpactRiskScore.numberofGeographies.score = 5;
      } else if (values.geoPreferences.length == 2) {
        values.investorImpactRiskScore.numberofGeographies.answer = 2;
        values.investorImpactRiskScore.numberofGeographies.score = 4;
      } else if (values.geoPreferences.length == 3) {
        values.investorImpactRiskScore.numberofGeographies.answer = 3;
        values.investorImpactRiskScore.numberofGeographies.score = 3;
      } else if (values.geoPreferences.length == 4) {
        values.investorImpactRiskScore.numberofGeographies.answer = 4;
        values.investorImpactRiskScore.numberofGeographies.score = 2;
      } else if (values.geoPreferences.length > 4) {
        values.investorImpactRiskScore.numberofGeographies.answer =
          'More than 4';
        values.investorImpactRiskScore.numberofGeographies.score = 1;
      } else if (values.geoPreferences.length == 0) {
        values.investorImpactRiskScore.numberofGeographies.answer = '0';
        values.investorImpactRiskScore.numberofGeographies.score = '';
      }

      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 1) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer = 1;
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = 5;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 2
      ) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer = 2;
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = 4;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 3
      ) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer = 3;
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = 3;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 4
      ) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer = 4;
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = 2;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length > 4
      ) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer =
          'More than 4';
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = 1;
      } else if (
        Object.keys(values.investmentGoalsSdg.sdgPreferences).length == 0
      ) {
        values.investorImpactRiskScore.numberofSDGAbove10Percent.answer = '';
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score = '';
      }

      if (Object.keys(values.investmentGoalsSdg.sdgPreferences).length > 0) {
        var noPoverty =
          values.investmentGoalsSdg.sdgPreferences.indexOf('noPoverty');
        if (noPoverty != -1) {
          values.investorImpactRiskScore.SGDBreakdown.NoPoverty = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.NoPoverty = '';
        }

        var genderEquality =
          values.investmentGoalsSdg.sdgPreferences.indexOf('genderEquality');
        if (genderEquality != -1) {
          values.investorImpactRiskScore.SGDBreakdown.GenderEquality = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.GenderEquality = '';
        }
        var industryInnovation =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'industryInnovation'
          );

        if (industryInnovation != -1) {
          values.investorImpactRiskScore.SGDBreakdown.IndustryInnovationInfrastructure =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.IndustryInnovationInfrastructure =
            '';
        }
        var climateAction =
          values.investmentGoalsSdg.sdgPreferences.indexOf('climateAction');

        if (climateAction != -1) {
          values.investorImpactRiskScore.SGDBreakdown.climateAction = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.climateAction = '';
        }
        var zeroHunger =
          values.investmentGoalsSdg.sdgPreferences.indexOf('zeroHunger');

        if (zeroHunger != -1) {
          values.investorImpactRiskScore.SGDBreakdown.ZeroHunger = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.ZeroHunger = '';
        }
        var cleanWater =
          values.investmentGoalsSdg.sdgPreferences.indexOf('cleanWater');

        if (cleanWater != -1) {
          values.investorImpactRiskScore.SGDBreakdown.CleanWaterSanitation = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.CleanWaterSanitation = '';
        }
        var reducedInequality =
          values.investmentGoalsSdg.sdgPreferences.indexOf('reducedInequality');

        if (reducedInequality != -1) {
          values.investorImpactRiskScore.SGDBreakdown.ReducedInequality = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.ReducedInequality = '';
        }
        var lifeBelowWater =
          values.investmentGoalsSdg.sdgPreferences.indexOf('lifeBelowWater');

        if (lifeBelowWater != -1) {
          values.investorImpactRiskScore.SGDBreakdown.LifeBelowWater = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.LifeBelowWater = '';
        }
        var goodHealthAndWellBeing =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'goodHealthAndWellBeing'
          );

        if (goodHealthAndWellBeing != -1) {
          values.investorImpactRiskScore.SGDBreakdown.goodHealth = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.goodHealth = '';
        }
        var affordableAndCleanEnergy =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'affordableAndCleanEnergy'
          );

        if (affordableAndCleanEnergy != -1) {
          values.investorImpactRiskScore.SGDBreakdown.AffordableCleanEnergy = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.AffordableCleanEnergy =
            '';
        }
        var sustainableCities =
          values.investmentGoalsSdg.sdgPreferences.indexOf('sustainableCities');

        if (sustainableCities != -1) {
          values.investorImpactRiskScore.SGDBreakdown.SustainableCitiesCommunities =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.SustainableCitiesCommunities =
            '';
        }
        var lifeOnLand =
          values.investmentGoalsSdg.sdgPreferences.indexOf('lifeOnLand');

        if (lifeOnLand != -1) {
          values.investorImpactRiskScore.SGDBreakdown.LifeonLand = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.LifeonLand = '';
        }
        var peaceAndJusticeStrongInstitutions =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'peaceAndJusticeStrongInstitutions'
          );

        if (peaceAndJusticeStrongInstitutions != -1) {
          values.investorImpactRiskScore.SGDBreakdown.PeaceJusticeStrongInsitutions =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.PeaceJusticeStrongInsitutions =
            '';
        }
        var qualityEducatio =
          values.investmentGoalsSdg.sdgPreferences.indexOf('qualityEducatio');
        if (qualityEducatio != -1) {
          values.investorImpactRiskScore.SGDBreakdown.qualityEducation = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.qualityEducation = '';
        }
        var decentWorkAndEconomicGrowth =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'decentWorkAndEconomicGrowth'
          );

        if (decentWorkAndEconomicGrowth != -1) {
          values.investorImpactRiskScore.SGDBreakdown.DecentWorkandEconomicGrowth =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.DecentWorkandEconomicGrowth =
            '';
        }
        var responsibleProduction =
          values.investmentGoalsSdg.sdgPreferences.indexOf(
            'responsibleProduction'
          );

        if (responsibleProduction != -1) {
          values.investorImpactRiskScore.SGDBreakdown.ResponsibleProductionConsumption =
            (
              100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
            ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.ResponsibleProductionConsumption =
            '';
        }
        var partnerships =
          values.investmentGoalsSdg.sdgPreferences.indexOf('partnerships');
        if (partnerships != -1) {
          values.investorImpactRiskScore.SGDBreakdown.Partnerships = (
            100 / Object.keys(values.investmentGoalsSdg.sdgPreferences).length
          ).toFixed(2);
        } else {
          values.investorImpactRiskScore.SGDBreakdown.Partnerships = '';
        }
      }

      var impactProfileScore =
        values.investorImpactRiskScore.impactProfile.score * 0.15;
      var numberofGeographiesScore =
        values.investorImpactRiskScore.numberofGeographies.score * 0.6;
      var numberofSDGAbove10PercentScore =
        values.investorImpactRiskScore.numberofSDGAbove10Percent.score * 0.25;
      var ImpactScore =
        impactProfileScore +
        numberofGeographiesScore +
        numberofSDGAbove10PercentScore;
      var questionWeightSum = 0.15 + 0.6 + 0.25;
      ImpactScore = ImpactScore / questionWeightSum;
      values.investorImpactRiskScore.ImpactScore = parseFloat(
        ImpactScore.toFixed(2)
      );
      console.log(values.investorImpactRiskScore);
      onSave(values);
      onNext();
      dispatch(userActions.get(user.id));
    },
    [
      dispatch,
      onSave,
      onNext,
      user.id,
      getRiskScores,
      getImpactRiskScores,
      DecentWorkP,
      IndustryInnovation,
      ClimateAction
    ]
    //[getRiskScores, getImpactRiskScores]
  );
  const handleCheckedChange = e => {
    if (e.target.checked) {
      sdgPreferencesValueV('');
      sdgprimarily('');
    }
  };

  const setSGD = (value, id) => {
    if (id == 0) {
      if (value.length < 3) {
        setDecentWorkP(value.replace(/\D/, ''));
      }
    } else if (id == 1) {
      if (value.length < 3) {
        setIndustryInnovation(value.replace(/\D/, ''));
      }
    } else {
      if (value.length < 3) {
        setClimateAction(value.replace(/\D/, ''));
      }
    }
  };

  return (
    <>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Your Investing Ecosystem</strong>
        </Typography>
        <Typography variant="h2">
          <strong>Set My Goals</strong>
        </Typography>
      </Box>
      <Grid container direction="column" spacing={3}>
        {/*<Grid item>
          <Card title="Investor / Organization Name">
            <Grid container spacing={2}>
              <Grid item sm={12} md={4} container justify="space-between">
                <Box>
                  <Typography variant="h2">
                    <strong>10</strong>
                  </Typography>
                  <Typography variant="h6">ACTIVE SDG IMPACT AREAS </Typography>
                </Box>
                {!isMobile && <Divider orientation="vertical" />}
              </Grid>
              <Grid item sm={12} md={4} container justify="space-between">
                <Box>
                  <Typography variant="h2">
                    <strong>10</strong>
                  </Typography>
                  <Typography variant="h6">GEOGRAPHIES</Typography>
                </Box>
                {!isMobile && <Divider orientation="vertical" />}
              </Grid>
              <Grid item sm={12} md={4} container>
                <Typography variant="h2">
                  <strong>Act to Avoid Harm</strong>
                </Typography>
                <Typography variant="h6">MOST COMMON IMPACT PROFILE</Typography>
              </Grid>
            </Grid>
            <Box mt={1} mb={2}>
              <Divider />
            </Box>
          </Card>
        </Grid>*/}
        <Formik
          initialValues={initial}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <Grid item>
                <Card title="Define Your Investing Goals">
                  {/*<Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Based on the SDGs you selected, ImpactCred showcases your SDG portfolio goal."
                  >
                    <HelpOutlineIcon
                      style={{
                        fontSize: '25px',
                        margin: '0px 4px',
                        position: 'relative',
                        top: '-50px',
                        left: '24%'
                      }}
                    />
                  </Tooltip>*/}
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Selecting your SDG, industry and geographic preferences to share with the ImpactCred community helps the right investors, enterprises and accelerators find you more easily. Please see https://sdgs.un.org/goals for additional information on the UN Sustainable Development Goals."
                  >
                    <Typography variant="h4">
                      Looking specifically at the capital you wish to deploy
                      within ImpactCred, how would you define your goals for
                      impact returns?
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {sdgPreferencesValue ? (
                    <InputFeedback error={sdgPreferencesValue} />
                  ) : null}
                  <Grid container spacing={1}>
                    {investmentGoalsSdgItems.map(item => (
                      <Grid
                        item
                        key={`investment-goals-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="investmentGoalsSdg.sdgPreferences"
                            onClick={e => handleCheckedChange(e)}
                            value={item.id}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                onClick={e => handleCheckedChange(e)}
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                </Card>
              </Grid>
              <Grid item>
                <Card title="Define Your Investing Goals">
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Selecting your SDG, industry and geographic preferences to share with the ImpactCred community helps the right investors, enterprises and accelerators find you more easily. Please see https://impacttoolkit.thegiin.org/irisplus/ for additional information about the GIIN's impact categories as part of their IRIS+ impact management system."
                  >
                    <Typography variant="h4">
                      What are your target industries or categories for
                      achieving impact?
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {AchievingImpact ? (
                    <InputFeedback error={AchievingImpact} />
                  ) : null}
                  <Grid container spacing={1}>
                    {achievingImpact.map(item => (
                      <Grid
                        item
                        key={`investment-Impact-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="achievingImpact"
                            onClick={e => handleCheckedChange(e)}
                            value={item.id}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                onClick={e => handleCheckedChange(e)}
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                </Card>
              </Grid>
              {/*<Grid item display="flex">
                <Card title="Detail of SDG Involvement">
                  <Typography variant="h4">
                    Looking specifically at the capital you wish to deploy via
                    ImpactCred, how would you distribute your impact across each
                    of the SDGs?
                  </Typography>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {deployviaI ? <InputFeedback error={deployviaI} /> : null}
                  <Grid container display="flex">
                    {geoSDGItems.map(item => (
                      <Grid
                        item
                        key={`investment-goals-${item.id}`}
                        xs={12}
                        sm={12}
                        md={12}
                        display="flex"
                        justifyContent="space-Evenly"
                        spacing={20}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          m={3}
                        >
                          <Field
                            type="checkbox"
                            name="deployviaImpactCred"
                            value={item.label}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                checked={field.value === item.label}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography style={{ width: '44%' }}>
                            {item.label}
                          </Typography>
                          {item.id == 0 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={DecentWorkP}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                          {item.id == 1 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={IndustryInnovation}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                          {item.id == 2 && (
                            <TextField
                              style={{
                                marginBottom: '0'
                              }}
                              id="deployviaImpactCredPrice"
                              name={'deployviaImpactCredPrice' + item.id}
                              variant="outlined"
                              placeholder={item.perc}
                              type="number"
                              max="3"
                              value={ClimateAction}
                              onChange={e => {
                                setSGD(e.target.value, item.id);
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                    ))}
                  
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  
                </Card>
              </Grid>*/}
              <Grid item>
                <Card title="Define Your Geographic Preferences">
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Selecting your SDG, industry and geographic preferences to share with the ImpactCred community helps the right investors, enterprises and accelerators find you more easily. Choose the continents in which you are looking to deploy capital. If you are only interested in certain countries, you will still be able to view deals on a country basis."
                  >
                    <HelpOutlineIcon
                      style={{
                        fontSize: '25px',
                        margin: '0px 4px',
                        position: 'relative',
                        top: '-50px',
                        left: '32%'
                      }}
                    />
                  </Tooltip>
                  {primarily ? <InputFeedback error={primarily} /> : null}
                  <Grid container spacing={1}>
                    {geoPreferencesItems.map(item => (
                      <Grid
                        item
                        key={`geo-preferences-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="geoPreferences"
                            value={item.id}
                            onClick={e => handleCheckedChange(e)}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                onClick={e => handleCheckedChange(e)}
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  {/* <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      startIcon={<img src={iconNext} alt="next" />}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box> */}
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="button"
                      onClick={onPrev}
                    >
                      Prev
                    </Button>
                    <Box mr={3} />
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="submit"
                    >
                      Next
                    </Button>
                  </Box>
                </Card>
              </Grid>

              {/*<Grid item>
                <Card title="Our Recommendation">
                   <Grid container spacing={1}>
                    {otherImpactGoalsItems.map(item => (
                      <Grid
                        item
                        key={`other-impact-goals-${item.id}`}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <Box display="flex" alignItems="center">
                          <Field
                            type="checkbox"
                            name="otherImpactGoals"
                            value={item.id}
                          >
                            {({ field }) => (
                              <Checkbox
                                color="primary"
                                checked={field.value === item.id}
                                {...field}
                              />
                            )}
                          </Field>
                          <Typography>{item.label}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid> 
                  <Grid container>
                    <Grid item xs={2}>
                      <CircleImage>The Global Investor</CircleImage>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h4">
                        Our recommendation is that you seek out companies with a
                        “Global Investor”Impact Rating with ImpactCred, as these
                        will best fit your overall impact goals. Your specific
                        SDG Goal Impact is also defined below. On the Dealflow,
                        we’ll help you see how different investments in
                        companies can help you achieve this impact.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box mt={2} mb={2} display="flex">
                    <ZeroHunger width={0.5} />
                    <LifeBelowWater width={0.3} />
                    <NoPoverty width={0.2} />
                  </Box>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                </Card>
              </Grid>*/}
              {/* <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  onClick={onSkip}
                >
                  Skip for now
                </Button>
              </Box> */}
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default InvestingEcosystem2;

const ZeroHunger = styled.div`
  background: ${theme.palette.common.green1};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Zero Hunger';
    color: white;
    margin-left: 10px;
  }
`;

const LifeBelowWater = styled.div`
  background: ${theme.palette.common.main};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Life Below Water';
    color: white;
    margin-left: 10px;
  }
`;

const NoPoverty = styled.div`
  background: ${theme.palette.common.mainDark};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'No Poverty';
    color: white;
    margin-left: 10px;
  }
`;

const CircleImage = styled(Box)`
  color: white;
  width: 110px;
  height: 110px;
  padding: 20px;
  background-size: cover;
  background-image: url(${imageBG});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;
