import { Box, Radio, Divider, Typography } from '@material-ui/core';

import styled from 'styled-components';
import theme from '../../../styles/theme';
import tickMark from '../../../assets/images/tick.png';

export const Wrapper = styled(Box)`
  height: 100%;
  max-width: 918px;
  padding: 84px 60px;
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export const Bold = styled(Typography)`
  font-weight: 700;
`;

export const StyledDivider = styled(Divider)`
  display: inline;
  flex: 1;
`;

export const FileList = styled(Box)`
  background-color: ${theme.palette.common.lightGray};
`;

export const DownloadIcon = styled.img`
  margin-right: ${theme.spacing(2)}px;
`;

export const StyledCheckedTickIcon = () => {
  return (
    <img
      src={tickMark}
      alt="next"
      style={{
        height: '23px',
        marginTop: '-4px',
        marginRight: '3px'
      }}
    />
  );
};
export const StyledCheckedIconTick = () => {
  return (
    <svg width={24} height={24}>
      <circle cx={10} cy={10} r={10} fill={'#B1C4C3'}></circle>
    </svg>
  );
};

export const StyledRadio = styled(Radio)`
  padding: 0;
`;
