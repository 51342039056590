import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import plusIcon from '../../../assets/images/plus.svg';
import nextIcon from '../../../assets/images/next.svg';
import { COUNTRY_LIST, US_STATES } from '../../../constants/strings';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { FormWrapper } from './Onboarding.styled';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
const validationSchema = yup.object().shape({
  first: yup.string().required('First Name is required'),
  last: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  dob: yup.string().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  organization: yup.string().required('Organization Name is required'),
  city: yup.string().required('City Name is required'),
  state: yup.string(),
  country: yup.string().required('Country Name is required')
});

const KnowYourCustomer = ({ onNext }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first: user.first || '',
      last: user.last || '',
      email: user.email || '',
      dob: user.dob || '',
      gender: user.gender || '',
      organization: user.organization || '',
      city: user.city || '',
      state: user.state || '',
      country: user.country || ''
    },
    validationSchema,
    onSubmit: values => {
      dispatch(userActions.update(user.id, values));
      onNext();
    }
  });

  return (
    <>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Know Your Customer</strong>
        </Typography>
      </Box>
      <FormWrapper mb={5}>
        <Typography variant="h4">
          <strong>Proof of Identity</strong>
        </Typography>
        <TextField id="identity" name="identity" variant="outlined" select>
          <MenuItem value="">Select</MenuItem>
        </TextField>
        <Box mt={3} />
        <Typography variant="h5">
          <strong>Please use .PNG or .JPG files</strong>
        </Typography>
        <Box display="flex" mt={1}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<img src={plusIcon} alt="next" />}
          >
            Front
          </Button>
          <Box mr={4} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<img src={plusIcon} alt="next" />}
            type="submit"
          >
            Back
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Upload
          </Button>
        </Box>
      </FormWrapper>
      <FormWrapper mb={5}>
        <Typography variant="h4">
          <strong>Proof of Address</strong>
        </Typography>
        <TextField id="identity" name="identity" variant="outlined" select>
          <MenuItem value="">Select</MenuItem>
        </TextField>
        <Box mt={3} />
        <Typography variant="h5">
          <strong>Please use .PNG or .JPG files</strong>
        </Typography>
        <Box display="flex" mt={1}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<img src={plusIcon} alt="next" />}
          >
            Front
          </Button>
          <Box mr={4} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            startIcon={<img src={plusIcon} alt="next" />}
            type="submit"
          >
            Back
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Upload
          </Button>
        </Box>
      </FormWrapper>
      <form onSubmit={formik.handleSubmit}>
        <FormWrapper>
          <Grid container spacing={5}>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>First Name</Typography>
              <TextField
                id="first"
                name="first"
                placeholder="First Name"
                variant="outlined"
                fullWidth
                value={formik.values.first}
                onChange={formik.handleChange}
                error={formik.touched.first && Boolean(formik.errors.first)}
                helperText={formik.touched.first && formik.errors.first}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Last Name</Typography>
              <TextField
                id="last"
                name="last"
                placeholder="Last Name"
                variant="outlined"
                fullWidth
                value={formik.values.last}
                onChange={formik.handleChange}
                error={formik.touched.last && Boolean(formik.errors.last)}
                helperText={formik.touched.last && formik.errors.last}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Email</Typography>
              <TextField
                id="email"
                name="email"
                placeholder="Email"
                variant="outlined"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Date of Birth</Typography>
              <TextField
                id="dob"
                name="dob"
                placeholder="Date of Birth"
                variant="outlined"
                fullWidth
                value={formik.values.dob}
                onChange={formik.handleChange}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Gender</Typography>
              <TextField
                id="gender"
                name="gender"
                variant="outlined"
                fullWidth
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                select
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Organization</Typography>
              <TextField
                id="organization"
                name="organization"
                placeholder="Organization"
                variant="outlined"
                fullWidth
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>City</Typography>
              <TextField
                id="city"
                name="city"
                placeholder="City Name"
                variant="outlined"
                fullWidth
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Tooltip
                placement="right-right"
                arrow
                display="flex"
                title="Please complete the Country selection before choosing the State. State is not required if Organization is based outside of the US"
              >
                <Typography variant="subtitle2">
                  State
                  <HelpOutlineIcon
                    style={{ fontSize: '20px', margin: '-4px 4px' }}
                  />
                </Typography>
              </Tooltip>
              <Autocomplete
                fullWidth
                id="state"
                name="state"
                value={formik.values.state}
                onChange={(e, value) => {
                  formik.setFieldValue('state', value);
                }}
                disabled={formik.values.country !== 'USA'}
                options={US_STATES}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="State Name"
                    error={Boolean(formik.touched.state && formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Typography variant={'subtitle2'}>Country</Typography>
              <Autocomplete
                fullWidth
                id="country"
                name="country"
                value={formik.values.country}
                onChange={(e, value) => {
                  formik.setFieldValue('country', value);
                  if (value !== 'USA') {
                    formik.setFieldValue('state', '');
                  }
                }}
                options={COUNTRY_LIST}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Country Name"
                    error={Boolean(
                      formik.touched.country && formik.errors.country
                    )}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
            >
              Save
            </Button>
            <Box mr={2} />
            {/* <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={onNext}
            >
              Skip for now
            </Button> */}
          </Box>
        </FormWrapper>
      </form>
    </>
  );
};

export default KnowYourCustomer;
