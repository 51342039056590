import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Divider
} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import imageBG from '../../../assets/images/circleBackground.svg';

import Card from '../../common/Card/Card';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { userRoles } from '../../../constants/user';
import { UserStatus } from '../../../constants';
import { acceleratorActions } from '../../../actions/acceleratorAction';
import { LinearProgress, CircularProgress } from '@material-ui/core';
const SearchAccelerator = () => {
  const isLayoutSM = useMediaQuery(theme.breakpoints.down('sm'));
  const acceleratorproData = useSelector(state => state.accelerator.profile);
  const dispatch = useDispatch();
  const allacc = useSelector(state => state.accelerator.allacc);
  const [activeData, setActiveData] = useState();
  const [loder, isLoder] = useState(true);
  useEffect(() => {
    dispatch(acceleratorActions.getaccelerator());
    console.log(allacc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExplore = async item => {
    setActiveData(item);
    isLoder(true);
    await dispatch(acceleratorActions.getProfile(item.id));
  };
  if (allacc.length > 0) {
    isLoder(false);
  }
  return (
    <Wrapper style={{ height: allacc.length == 0 ? '1000px' : 'auto' }}>
      {allacc.length == 0 && (
        <Grid item style={{ heigth: '1000px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ heigth: '1000px', width: '100%' }}
            mt={10}
            mb={0}
          >
            <CircularProgress size={40} status={'loading'} />
          </Box>
        </Grid>
      )}
      {Object.keys(allacc).length > 0 && (
        <Grid container spacing={isLayoutSM ? 2 : 5}>
          <Grid item xs={12} sm={4}>
            <Title variant="h1">Accelerator Ecosystem</Title>
            <Box mt={4}>
              {allacc &&
                allacc.data &&
                allacc.data.map((item, idx) => (
                  <Box mb={2} key={`explore-${idx}`}>
                    <Card title={`${item.accelerator.organization}`}>
                      <Typography variant="h5">
                        {item.accelerator_profile &&
                        item.accelerator_profile.description.length > 200
                          ? item.accelerator_profile.description.substring(
                              0,
                              200
                            ) + '...'
                          : item.accelerator_profile.description}
                      </Typography>
                      <Box mt={2}>
                        <ExploreButton
                          color="primary"
                          variant="outlined"
                          fullWidth
                          onClick={() => handleExplore(item)}
                        >
                          Explore
                        </ExploreButton>
                      </Box>
                    </Card>
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            {activeData && (
              <>
                <Box mt={10} />
                <Card title={`${activeData.accelerator.organization}`}>
                  {/*<Grid container spacing={2}>
                    <Grid item sm={12} md={4} container justify="space-between">
                      <Box>
                        <Typography variant="h2">$100,000</Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12} md={4} container justify="space-between">
                      <Box>
                        <Typography variant="h2">2 years</Typography>
                        <Typography>
                          AVERAGE TIME HORIZON FOR REPAYMENT
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item sm={12} md={4}>
                      <Typography variant="h2">$2.2 million</Typography>
                      <Typography>TOTAL FOLLOW-ON CAPITAL RAISED</Typography>
                    </Grid>
                  </Grid>
                  <Box mt={1} mb={2}>
                    <Divider />
                  </Box>*/}
                  <Typography variant="h4">
                    {activeData.accelerator_profile
                      ? activeData.accelerator_profile.description
                      : 'N/A'}
                  </Typography>
                  {/*<Box mt={4} mb={1}>
                      <Divider />
                    </Box>
                    <Grid container>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item>
                          <CircleImage>Do Not Harm</CircleImage>
                        </Grid>
                        <Grid item xs>
                          <Typography>
                            Our deals generally actively seek to avoid harm in
                            their communities.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} container spacing={1}>
                        <Grid item>
                          <CircleImage>Below Average</CircleImage>
                        </Grid>
                        <Grid item xs>
                          <Typography>
                            Our deals generally fit a “Below Average” Risk Rating.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>*/}
                </Card>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  padding: ${theme.spacing(2, 5, 5, 2)};
  font-size: 11px;
  color: ${theme.palette.common.black};

  & .MuiInputBase-root {
    height: 34px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 700;
`;

export const TypeButton = styled(Button)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.common.black};
  border-radius: 100px;
  height: 20px;
  font-size: 11px;

  &.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.black};
  }
`;

export const ExploreButton = styled(Button)`
  height: 32px;
`;

const CircleImage = styled(Box)`
  color: white;
  width: 110px;
  height: 110px;
  padding: 20px;
  background-size: cover;
  background-image: url(${imageBG});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;

export default SearchAccelerator;
