import { toastr } from 'react-redux-toastr';
import authHeader from '../helpers/authHeader';

export const enterpriseService = {
  getForm,
  createForm,
  updateForm,
  getEnterprise,
  enterpriseFiancialRiskScores,
  enterpriseupdateMarketRiskPAD,
  enterpriseImpactRiskScores,
  matchEnterprise
};

const BASEURL = process.env.REACT_APP_API_URL;
const toastrOptions = {
  timeOut: 3000, // by setting to 0 it will prevent the auto close
  position: 'bottom-right'
};
function getForm(id) {
  const url = `/enterprise/form/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function enterpriseFiancialRiskScores(id) {
  const url = `/enterprise/enterprise-fiancial-risk-scores/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function enterpriseImpactRiskScores(id) {
  const url = `/enterprise/enterprise-impact-risk-scores/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function enterpriseupdateMarketRiskPAD(body) {
  const url = `/enterprise/update-market-risk-pub-av-data`;
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function createForm(body) {
  const url = `/enterprise/form`;
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Form answers have been saved', toastrOptions);
      return response.data;
    });
}

function updateForm(id, body) {
  const url = `/enterprise/form/${id}`;
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Form answers have been saved', toastrOptions);
      return response.data;
    });
}

function getEnterprise() {
  const url = `/enterprise/get-enterprise/`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function matchEnterprise(body) {
  const url = `/enterprise/match`;
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      toastr.error('Error', error);
      return Promise.reject(error);
    }

    //toastr.success('Success', data.message);
    return data;
  });
}
