import {
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  Grid
} from '@material-ui/core';
import { useFormik } from 'formik';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconAvatar from '../../../assets/images/avatarSmall.svg';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { acceleratorActions } from '../../../actions/acceleratorAction';
import { history } from '../../../helpers/history';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const HomeAccelerator = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const enterpriseInviteQuestAns = useSelector(
    state => state.accelerator.enterpriseInviteQuestAns_
  );
  useEffect(() => {
    user &&
      user.id &&
      params.accelerator_id &&
      dispatch(
        acceleratorActions.getEnterpriseInviteQueAns(
          user.id,
          params.accelerator_id,
          'user'
        )
      );
  }, [dispatch, user, params.accelerator_id]);
  // if (history.location.pathname == '/accelerator/home') {
  //   validationSchema = yup.object().shape({
  //     email: yup
  //       .string()
  //       .email('Enter a valid email')
  //       .required('Email is required')
  //   });
  // } else {
  //   validationSchema = yup.object().shape({
  //     referthiscompany: yup.string().required('This field is required'),
  //     commentonthemanagement: yup.string().required('This field is required'),
  //     managementcompetencies: yup.string().required('This field is required'),
  //     selectionprocess: yup.string().required('This field is required'),
  //     networksandindustry: yup.string().required('This field is required'),
  //     Doessomeoneofyourteam: yup.string().required('This field is required')
  //   });
  // }

  var validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    companyName: yup.string().required('This field is required'),
    referthiscompany: yup.string().required('This field is required'),
    commentonthemanagement: yup.string().required('This field is required'),
    managementcompetencies: yup.string().required('This field is required'),
    selectionprocess: yup.string().required('This field is required'),
    networksandindustry: yup.string().required('This field is required'),
    Doessomeoneofyourteam: yup.string().required('This field is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      companyName: enterpriseInviteQuestAns.companyName
        ? enterpriseInviteQuestAns.companyName
        : '',
      email: enterpriseInviteQuestAns.enterprise_email
        ? enterpriseInviteQuestAns.enterprise_email
        : '',
      Anythingelse: enterpriseInviteQuestAns.Anythingelse
        ? enterpriseInviteQuestAns.Anythingelse
        : '',
      Doessomeoneofyourteam: enterpriseInviteQuestAns.Doessomeoneofyourteam
        ? enterpriseInviteQuestAns.Doessomeoneofyourteam
        : '',
      accelerator_id: enterpriseInviteQuestAns.accelerator_id
        ? enterpriseInviteQuestAns.accelerator_id
        : '',
      believethismanagementteam:
        enterpriseInviteQuestAns.believethismanagementteam
          ? enterpriseInviteQuestAns.believethismanagementteam
          : '',
      commentonthemanagement: enterpriseInviteQuestAns.commentonthemanagement
        ? enterpriseInviteQuestAns.commentonthemanagement
        : '',
      managementcompetencies: enterpriseInviteQuestAns.managementcompetencies
        ? enterpriseInviteQuestAns.managementcompetencies
        : '',
      networksandindustry: enterpriseInviteQuestAns.networksandindustry
        ? enterpriseInviteQuestAns.networksandindustry
        : '',
      referthiscompany: enterpriseInviteQuestAns.referthiscompany
        ? enterpriseInviteQuestAns.referthiscompany
        : '',
      selectionprocess: enterpriseInviteQuestAns.selectionprocess
        ? enterpriseInviteQuestAns.selectionprocess
        : '',
      id: enterpriseInviteQuestAns.id ? enterpriseInviteQuestAns.id : ''
    },
    onSubmit: values => {
      if (history.location.pathname == '/accelerator/home') {
        values.url = window.location.origin + '/enterprise/email/' + user.id;
        dispatch(acceleratorActions.sendEnterpriseInvite(values, user));
      } else {
        values.accelerator_id = params.accelerator_id;
        dispatch(acceleratorActions.SaveEnterpriseInviteQueAns(values));
        history.push('/enterprise/home');
      }
      // values.accelerator_id = params.accelerator_id;
      // dispatch(acceleratorActions.SaveEnterpriseInviteQueAns(values));
      // history.push('/enterprise/home');
    }
  });

  return (
    <Wrapper>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Enterprise Invite</strong>
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Card title="Invite an Enterprise">
          <Typography variant="h4">
            Thank you for referring an innovative social enterprise to
            ImpactCred! We are grateful for the dedication of your team to help
            us identify great companies who can benefit from working capital
            loans!
          </Typography>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Typography variant="h5" mt={5} display="flex">
            Company Name
          </Typography>
          <Box mt={2} mb={2} display="flex" alignItems="center">
            <TextField
              variant="outlined"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.errors.companyName && formik.errors.companyName}
              helperText={
                formik.errors.companyName && formik.errors.companyName
              }
            />
          </Box>
          <Typography variant="h5" mt={5} display="flex">
            Please enter the email of the company contact
          </Typography>
          <Box mt={2} mb={2} display="flex" alignItems="center">
            <TextField
              variant="outlined"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email && formik.errors.email}
              helperText={formik.errors.email && formik.errors.email}
            />
          </Box>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </Card>
      </form>
      <Box mb={7} />
      <form onSubmit={formik.handleSubmit}>
        <Card title="Accelerator Reference" icon={iconAvatar}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4">
                Please respond to the following questions so we can evaluate
                your referral in a timely way:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                1) Why did you refer this company? What stands out about them?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="referthiscompany"
                name="referthiscompany"
                value={formik.values.referthiscompany}
                onChange={formik.handleChange}
                error={
                  formik.touched.referthiscompany &&
                  formik.errors.referthiscompany
                }
                helperText={
                  formik.touched.referthiscompany &&
                  formik.errors.referthiscompany
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                2) Please comment on the management team and how they have
                responded to your coaching during the program?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="commentonthemanagement"
                name="commentonthemanagement"
                value={formik.values.commentonthemanagement}
                onChange={formik.handleChange}
                error={
                  formik.touched.commentonthemanagement &&
                  formik.errors.commentonthemanagement
                }
                helperText={
                  formik.touched.commentonthemanagement &&
                  formik.errors.commentonthemanagement
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                3) What management competencies have your observed and
                validated?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="managementcompetencies"
                name="managementcompetencies"
                value={formik.values.managementcompetencies}
                onChange={formik.handleChange}
                error={
                  formik.touched.managementcompetencies &&
                  formik.errors.managementcompetencies
                }
                helperText={
                  formik.touched.managementcompetencies &&
                  formik.errors.managementcompetencies
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                4) For your selection process, how did you evaluate the market
                potential of this company?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="selectionprocess"
                name="selectionprocess"
                value={formik.values.selectionprocess}
                onChange={formik.handleChange}
                error={
                  formik.touched.selectionprocess &&
                  formik.errors.selectionprocess
                }
                helperText={
                  formik.touched.selectionprocess &&
                  formik.errors.selectionprocess
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                5) Why do you believe this management team will be able to
                execute on their vision?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="believethismanagementteam"
                name="believethismanagementteam"
                value={formik.values.believethismanagementteam}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                6) What networks and industry contacts do you believe this
                company possesses which are crucial to the sector and market
                they are operating in?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="networksandindustry"
                name="networksandindustry"
                value={formik.values.networksandindustry}
                onChange={formik.handleChange}
                error={
                  formik.touched.networksandindustry &&
                  formik.errors.networksandindustry
                }
                helperText={
                  formik.touched.networksandindustry &&
                  formik.errors.networksandindustry
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                7) Anything else you would like to say about this company or
                their execution capability?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="Anythingelse"
                name="Anythingelse"
                value={formik.values.Anythingelse}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                8) Does someone of your team have a strong personal relationship
                with this management team?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                id="Doessomeoneofyourteam"
                name="Doessomeoneofyourteam"
                value={formik.values.Doessomeoneofyourteam}
                onChange={formik.handleChange}
                error={
                  formik.touched.Doessomeoneofyourteam &&
                  formik.errors.Doessomeoneofyourteam
                }
                helperText={
                  formik.touched.Doessomeoneofyourteam &&
                  formik.errors.Doessomeoneofyourteam
                }
              />
            </Grid>
          </Grid>
        </Card>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
          >
            Invite Enterprise
          </Button>
        </Box>
      </form>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  height: 100%;
  max-width: 918px;
  padding: 84px 60px;
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export default HomeAccelerator;
