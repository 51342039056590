import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Button,
  useMediaQuery,
  List,
  Divider
} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Dropdown from 'react-multilevel-dropdown';
import Card from '../../common/Card/Card';
import DealDetails from '../common/DealDetails';
import ImpactDetails from '../common/ImpactDetails';
import RiskAlignment from '../common/RiskAlignment';
import { userActions } from '../../../actions/userActions';
import { investorActions } from '../../../actions/investorActions';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import { userRoles } from '../../../constants/user';
import { UserStatus } from '../../../constants';
import { Link } from 'react-scroll';
import iconAvatar from '../../../assets/images/avatarSmall.svg';
const investmentGoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];

const geoPreferencesItems = [
  {
    id: 'all',
    label: 'All Geographies'
  },
  {
    id: 'asia',
    label: 'Asia'
  },
  {
    id: 'australia',
    label: 'Australia'
  },
  { id: 'africa', label: 'Africa' },
  { id: 'northAmerica', label: 'North America' },
  { id: 'southAmerica', label: 'South America' },
  { id: 'europe', label: 'Europe' }
];
const SearchEnterprise = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const enterprise = useSelector(state => state.enterprise.enterprise);
  const enterpriseScore = useSelector(state => state.enterprise.RiskScores);
  const isLayoutSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [type, setType] = useState('impact');
  const [IsMenu, setIsMenu] = useState(false);
  const [activeData, setActiveData] = useState();
  const [activeFormAnswers, setActiveFormAnswers] = useState();
  const [EnterpData, setEnterpData] = useState([]);
  const [DealHL, setDealHL] = useState(true);
  const [Alph, setAlph] = useState(true);
  const RiskScores = useSelector(state => state.investor.RiskScores);
  useEffect(() => {
    dispatch(enterpriseActions.getEnterprise());
    dispatch(userActions.list());
    user.id && dispatch(investorActions.investorFiancialRiskScores(user.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEnterpData]);

  const enterprises = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(
        user =>
          user.role === userRoles.ENTERPRISE &&
          user.isApproved === UserStatus.APPROVED
      );
    }
    return [];
  }, [user]);

  const handleChangeType = newType => {
    setType(newType);
  };

  const handleExplore = item => {
    setActiveData(item);
    dispatch(enterpriseActions.enterpriseFiancialRiskScores(item.id));
    dispatch(enterpriseActions.getEnterprise());
    dispatch(enterpriseActions.getForm(item.id)).then(response => {
      setActiveFormAnswers(response.formAnswers);
    });
  };
  const sortEnterprises = (event, type) => {
    setEnterpData([]);
    if (event == 'Alphabetical') {
      setAlph(!Alph);
      const myData = enterprise.data.sort(function (a, b) {
        if (Alph) {
          if (a.organization.toLowerCase() < b.organization.toLowerCase()) {
            return -1;
          }
          if (a.organization.toLowerCase() > b.organization.toLowerCase()) {
            return 1;
          }
          return 0;
        } else {
          if (b.organization.toLowerCase() < a.organization.toLowerCase()) {
            return -1;
          }
          if (b.organization.toLowerCase() > a.organization.toLowerCase()) {
            return 1;
          }
          return 0;
        }
      });
      setEnterpData(myData);
      setIsMenu(true);
    } else if (event == 'Deal') {
      setDealHL(!DealHL);
      const myData = enterprise.data.sort(function (a, b) {
        if (DealHL) {
          return parseFloat(a.seekingLoan) - parseFloat(b.seekingLoan);
        } else {
          return parseFloat(b.seekingLoan) - parseFloat(a.seekingLoan);
        }
      });
      setEnterpData(myData);
      setIsMenu(true);
    } else if (event == 'Financial') {
      dispatch(
        enterpriseActions.matchEnterprise({
          type: 'risk-score',
          finalRiskScore: RiskScores.finalRiskScore
        })
      ).then(data => {
        console.log(EnterpData);
        if (EnterpData && EnterpData.length > 0) {
          var arr = [];
          for (var k in EnterpData) {
            var index = data.indexOf(EnterpData[k].id);
            if (index != -1) {
              arr.push(EnterpData[k]);
            }
            console.log(index);
          }
          setEnterpData(arr);
        }
      });
      setIsMenu(true);
    } else if (event == 'SGD') {
      console.log(type);
      if (enterprise && enterprise.data) {
        var data = enterprise.data.filter(item =>
          item.investmentGoalsSdg.sdgPreferences
            ? item.investmentGoalsSdg.sdgPreferences.indexOf(type) != -1
            : []
        );
        setEnterpData(data);
        console.log(data);
        console.log(enterprise.data);
      }
      setIsMenu(true);
    } else if (event == 'Geography') {
      console.log(type);
      if (enterprise && enterprise.data) {
        var data1 = enterprise.data.filter(
          item => item.geoPreferences.indexOf(type) != -1
        );
        setEnterpData(data1);
      }
      setIsMenu(true);
    } else if ('Accelerator' == event) {
      console.log('event', type);
      var name = type.organization;
      var data2 = enterprise.data.filter(item => item.referredBy == name);
      setEnterpData(data2);
      setIsMenu(true);
    } else {
      setIsMenu(false);
      setEnterpData([]);
    }
  };
  var accelerators = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(user => user.role === userRoles.ACCELERATOR);
    }
    return [];
  }, [user]);

  if (accelerators) {
    accelerators = accelerators.sort(function (a, b) {
      if (a.first) {
        if (a.first.toLowerCase() < b.first.toLowerCase()) {
          return -1;
        }
        if (a.first.toLowerCase() > b.first.toLowerCase()) {
          return 1;
        }
      }
    });
  }

  return (
    <Wrapper
      style={{ height: enterprise.length == 0 ? '1000px' : 'auto' }}
      id="m_d_top"
    >
      {enterprise.length == 0 && (
        <Grid item style={{ heigth: '1000px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ heigth: '1000px', width: '100%' }}
            mt={10}
            mb={0}
          >
            <CircularProgress size={40} status={'loading'} />
          </Box>
        </Grid>
      )}
      {Object.keys(enterprise).length > 0 && (
        <Grid container spacing={isLayoutSM ? 2 : 5}>
          <Grid item xs={12} sm={4}>
            <Title variant="h1">Ecosystem Dealflow</Title>
            {/*user.role == userRoles.INVESTOR && (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TypeButton
                      disableElevation
                      onClick={() => handleChangeType('impact')}
                      className={type === 'impact' && 'active'}
                      fullWidth
                    >
                      Impact
                    </TypeButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TypeButton
                      disableElevation
                      onClick={() => handleChangeType('financial')}
                      className={type === 'financial' && 'active'}
                      fullWidth
                    >
                      Financial
                    </TypeButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TypeButton
                      disableElevation
                      onClick={() => handleChangeType('goals')}
                      className={type === 'goals' && 'active'}
                      fullWidth
                    >
                      Goals
                    </TypeButton>
                  </Grid>
                </Grid>
              </Box>
            )*/}
            {user.role == userRoles.INVESTOR && (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">SORT</Typography>
                    <TextField select variant="outlined" fullWidth>
                      <MenuItem
                        value="all_value"
                        onClick={e => sortEnterprises('all_value', '')}
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        value="Alphabetical"
                        onClick={e => sortEnterprises('Alphabetical', '')}
                      >
                        Alphabetical
                      </MenuItem>
                      <MenuItem
                        value="Deal"
                        onClick={e => sortEnterprises('Deal', '')}
                      >
                        Deal Size
                      </MenuItem>
                      <MenuItem
                        value="sort2"
                        onClick={e => sortEnterprises('Financial', '')}
                      >
                        My Financial Matches
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">FILTER</Typography>
                    <Dropdown
                      title="Select"
                      style={{
                        background: '#d5f4f1',
                        marginTop: '8px',
                        height: '35px',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        border: '1px solid #b4c2c0'
                      }}
                    >
                      <Dropdown.Item style={{ minWidth: '300px!important' }}>
                        SGD Category
                        <Dropdown.Submenu
                          position="right"
                          style={{ minWidth: '300px!important' }}
                        >
                          {investmentGoalsSdgItems.map((item, idx) => (
                            <Dropdown.Item
                              key={idx}
                              style={{ color: '#000' }}
                              onClick={e => sortEnterprises('SGD', item.id)}
                            >
                              {item.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Geography
                        <Dropdown.Submenu
                          position="right"
                          style={{ width: '300px' }}
                        >
                          {geoPreferencesItems.map((item, idx) => (
                            <Dropdown.Item
                              key={idx}
                              style={{ color: '#000' }}
                              onClick={e =>
                                sortEnterprises('Geography', item.id)
                              }
                            >
                              {item.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        Accelerator
                        <Dropdown.Submenu
                          position="right"
                          style={{ width: '300px' }}
                        >
                          {accelerators.map((item, idx) => (
                            <Dropdown.Item
                              key={idx}
                              style={{ color: '#000' }}
                              onClick={e =>
                                sortEnterprises('Accelerator', item)
                              }
                            >
                              {item.first + ' ' + item.last}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Submenu>
                      </Dropdown.Item>
                    </Dropdown>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box mt={6}>
              {EnterpData &&
                IsMenu &&
                EnterpData.length > 0 &&
                EnterpData.map((item, idx) => (
                  <Box mb={2} key={`explore-${idx}`}>
                    <Card
                      title={`${item.organization ? item.organization : 'N/A'}`}
                    >
                      <Typography variant="h5">
                        {item.explainBusinessModel &&
                        item.explainBusinessModel.length > 200
                          ? item.explainBusinessModel.substring(0, 200) + '...'
                          : item.explainBusinessModel}
                      </Typography>
                      ;
                      <Box mt={2}>
                        <Link
                          activeClass="active"
                          to="m_d_top"
                          spy={true}
                          smooth={true}
                        >
                          <ExploreButton
                            color="primary"
                            variant="outlined"
                            fullWidth
                            onClick={() => handleExplore(item)}
                          >
                            Explore
                          </ExploreButton>
                        </Link>
                      </Box>
                    </Card>
                  </Box>
                ))}
            </Box>
            <Box mt={6}>
              {enterprise &&
                EnterpData.length == 0 &&
                enterprise.data &&
                !IsMenu &&
                enterprise.data.map((item, idx) => (
                  <Box mb={2} key={`explore-${idx}`}>
                    <Card
                      title={`${item.organization ? item.organization : 'N/A'}`}
                    >
                      <Typography variant="h5">
                        {item.explainBusinessModel &&
                        item.explainBusinessModel.length > 200
                          ? item.explainBusinessModel.substring(0, 200) + '...'
                          : item.explainBusinessModel}
                      </Typography>
                      ;
                      <Box mt={2}>
                        <Link
                          activeClass="active"
                          to="m_d_top"
                          spy={true}
                          smooth={true}
                        >
                          <ExploreButton
                            color="primary"
                            variant="outlined"
                            fullWidth
                            onClick={() => handleExplore(item)}
                          >
                            Explore
                          </ExploreButton>
                        </Link>
                      </Box>
                    </Card>
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            {activeData && activeFormAnswers ? (
              <>
                <Box mt={2} mb={3}>
                  <Typography variant="h3">{`${activeData.organization}`}</Typography>
                </Box>
                <DealDetails />
                <Box mt={4} />
                <ImpactDetails activeFormAnswers={activeFormAnswers} />
                <Box mt={4} />
                <RiskAlignment
                  enterpriseScore={enterpriseScore}
                  activeFormAnswers={activeFormAnswers}
                  activeData={activeData}
                />
                <Box mt={4} />
                <div id="f_">
                  <Card title="Founding team" icon={iconAvatar}>
                    <Typography variant="h4">Team description</Typography>
                    <Box my={1}>
                      <Divider />
                    </Box>
                    <Typography variant="h4">
                      {activeFormAnswers && activeFormAnswers.pillar6
                        ? activeFormAnswers.pillar6.yourTeamDescription
                        : 'N/A'}
                    </Typography>
                    {/* <TextField
                    multiline
                    rows={5}
                    fullWidth
                    variant="outlined"
                  ></TextField> */}
                  </Card>
                </div>
                <Box mt={4} />
                <div id="f_">
                  <Card title="Recent Milestones" icon={iconAvatar}>
                    <Typography variant="h4">
                      {activeFormAnswers && activeFormAnswers.pillar6
                        ? activeFormAnswers.pillar6.updatesanyrelevantPR
                        : 'N/A'}
                    </Typography>
                    {/* <TextField
                    multiline
                    rows={5}
                    fullWidth
                    variant="outlined"
                  ></TextField> */}
                  </Card>
                </div>
              </>
            ) : (
              <Typography>Click on Explore to view more information</Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  padding: ${theme.spacing(2, 5, 5, 2)};
  font-size: 11px;
  color: ${theme.palette.common.black};

  & .MuiInputBase-root {
    height: 34px;
  }
`;

export const Title = styled(Typography)`
  font-weight: 700;
`;

export const TypeButton = styled(Button)`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.common.black};
  border-radius: 100px;
  height: 20px;
  font-size: 11px;

  &.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.black};
  }
`;

export const ExploreButton = styled(Button)`
  height: 32px;
`;

export default SearchEnterprise;
