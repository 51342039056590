import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputLabel from '@material-ui/core/InputLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  COUNTRY_LIST,
  INTAKE_ENTERPRISE_TITLE,
  US_STATES
} from '../../../constants/strings';
import theme from '../../../styles/theme';
import nextIcon from '../../../assets/images/next.svg';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { history } from '../../../helpers/history';
import { userActions } from '../../../actions/userActions';
import { userRoles } from '../../../constants/user';

const USERTYPE = ['seeking funding'];
import classNames from 'classnames';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  first: yup.string().required('First Name is required'),
  last: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  organization: yup.string().required('Organization Name is required'),
  referredBy: yup.string().required('Accelerator Affiliation is required'),
  city: yup.string().required('City Name is required'),
  //state: yup.string(),
  country: yup.string().required('Country Name is required')
});

const IntakeForm = () => {
  const user = useSelector(state => state.user);
  useEffect(() => {
    dispatch(userActions.list());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userType, setUserType] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [StateError, setStateError] = useState('');

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first: user.first || '',
      last: user.last || '',
      email: user.email || '',
      organization: user.organization || '',
      referredBy: user.referredBy || '',
      city: user.city || '',
      state: user.state || '',
      country: user.country || ''
    },
    validationSchema,
    onSubmit: values => {
      if (values.country == 'USA') {
        if (!values.state) {
          setStateError('State Name is required');
          return false;
        }
      } else {
        setStateError('');
      }
      values.onboardingLeaveStep = 0;
      values.role = user.role;
      dispatch(userActions.update(user.id, values));
      history.push('/enterprise/onboarding');
    }
  });

  const accelerators = useMemo(() => {
    if (user && user.users) {
      return user.users.filter(
        user => user.role === userRoles.ACCELERATOR && user.organization
      );
    }
    return [];
  }, [user]);

  const getCountry = (e, value) => {
    formik.setFieldValue('country', value);
    if (value !== 'USA') {
      setStateError('');
      formik.setFieldValue('state', '');
    } else {
      setStateError('State Name is required');
    }
  };

  return (
    <Wrapper backgroundcolor={theme.palette.common.yellow}>
      <Title>{INTAKE_ENTERPRISE_TITLE}</Title>
      <form onSubmit={formik.handleSubmit}>
        <Content>
          <Box ml={isMobile ? 0 : 4}>
            <Box mb={8}>
              <Grid container spacing={5}>
                <Grid item md={8} sm={12} xs={12}>
                  <UserTypeWrapper mb={2.5} display="flex" alignItems="center">
                    <Typography variant="h5" style={{ whiteSpace: 'nowrap' }}>
                      I am...
                    </Typography>
                    <Select
                      value={userType}
                      onChange={event => setUserType(event.target.value)}
                      variant="outlined"
                      fullWidth
                    >
                      {USERTYPE.map((i, index) => (
                        <MenuItem value={index} key={`type-${index}`}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  </UserTypeWrapper>
                </Grid>
              </Grid>
            </Box>

            <Box mb={2.5}>
              <Grid container spacing={5}>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>First Name</Typography>
                  <TextField
                    id="first"
                    name="first"
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.first}
                    onChange={formik.handleChange}
                    error={formik.touched.first && Boolean(formik.errors.first)}
                    helperText={formik.touched.first && formik.errors.first}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>Last Name</Typography>
                  <TextField
                    id="last"
                    name="last"
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.last}
                    onChange={formik.handleChange}
                    error={formik.touched.last && Boolean(formik.errors.last)}
                    helperText={formik.touched.last && formik.errors.last}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2.5}>
              <Grid container spacing={5}>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>Email</Typography>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>Organization</Typography>
                  <TextField
                    id="organization"
                    name="organization"
                    placeholder="Organization"
                    variant="outlined"
                    fullWidth
                    value={formik.values.organization}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.organization &&
                      Boolean(formik.errors.organization)
                    }
                    helperText={
                      formik.touched.organization && formik.errors.organization
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>
                    Accelerator Affiliation
                  </Typography>
                  <TextField
                    select
                    id="referredBy"
                    name="referredBy"
                    variant="outlined"
                    fullWidth
                    value={formik.values.referredBy}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.referredBy &&
                      Boolean(formik.errors.referredBy)
                    }
                    helperText={
                      formik.touched.referredBy && formik.errors.referredBy
                    }
                  >
                    {accelerators &&
                      accelerators.length > 0 &&
                      accelerators.map((i, index) => (
                        <MenuItem value={i.organization} key={`type-${index}`}>
                          {i.organization}
                        </MenuItem>
                      ))}
                    {accelerators && accelerators.length == 0 && (
                      <MenuItem>N/A</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Typography variant={'subtitle2'}>City</Typography>
                  <TextField
                    id="city"
                    name="city"
                    placeholder="City Name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip
                    placement="right-right"
                    arrow
                    display="flex"
                    title="Please complete the Country selection before choosing the State. State is not required if Organization is based outside of the US"
                  >
                    <Typography variant="subtitle2">
                      State
                      <HelpOutlineIcon
                        style={{ fontSize: '20px', margin: '-4px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Autocomplete
                    fullWidth
                    id="state"
                    name="state"
                    value={formik.values.state}
                    onChange={(e, value) => {
                      formik.setFieldValue('state', value);
                    }}
                    disabled={formik.values.country !== 'USA'}
                    options={US_STATES}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="State Name"
                        error={Boolean(
                          formik.touched.state && formik.errors.state
                        )}
                        helperText={formik.touched.state && formik.errors.state}
                      />
                    )}
                  />
                  {StateError ? <InputFeedback error={StateError} /> : null}
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip
                    placement="right-right"
                    arrow
                    display="flex"
                    title="Please complete the country selection before the choosing the State"
                  >
                    <Typography variant="subtitle2">
                      State
                      <HelpOutlineIcon
                        style={{ fontSize: '20px', margin: '-4px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Autocomplete
                    fullWidth
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={(e, value) => {
                      getCountry(e, value);
                    }}
                    options={COUNTRY_LIST}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Country Name"
                        error={Boolean(
                          formik.touched.country && formik.errors.country
                        )}
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              startIcon={<img src={nextIcon} alt="next" />}
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Content>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  height: 100%;
  padding: 120px 227px 147px 112px;
  background-color: ${props => props.backgroundcolor};

  ${theme.breakpoints.down('md')} {
    padding: 60px;
  }
`;

const Title = styled(Typography)`
  font-size: 88px;
  font-weight: 500;
  max-width: 955px;
  margin-bottom: ${theme.spacing(7)}px;

  ${theme.breakpoints.down('md')} {
    font-size: 60px;
    margin-bottom: ${theme.spacing(3)}px;
  }

  ${theme.breakpoints.down('sm')} {
    font-size: 40px;
  }
`;

const Content = styled(Box)`
  background-color: ${theme.palette.common.white};
  padding: 140px 108px;
  max-width: 1101px;

  ${theme.breakpoints.down('md')} {
    padding: 100px 68px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 80px 18px;
  }

  ${theme.breakpoints.down('xs')} {
    padding: 40px 16px;
  }
`;

const UserTypeWrapper = styled(Box)`
  background-color: ${theme.palette.common.gray};
  padding: ${theme.spacing(1.5, 2)};
  width: 100%;

  .MuiInputBase-root {
    margin-top: 0;
  }
  .MuiTypography-root {
    margin-right: ${theme.spacing(4)}px;
  }
`;
export default IntakeForm;
