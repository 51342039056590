import { acceleratorConstants } from '../constants/accelerator';
import { acceleratorService } from '../services/acceleratorService';

export const acceleratorActions = {
  getProfile,
  AddUpdateProfile,
  sendEnterpriseInvite,
  SaveEnterpriseInviteQueAns,
  getEnterpriseInviteQueAns,
  getaccelerator
};

function getProfile(id) {
  return dispatch => {
    dispatch(request());
    return acceleratorService.getProfile(id).then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return {
      type: acceleratorConstants.ACCELERATOR_GET_PROFILE_REQUEST
    };
  }

  function success(data) {
    return {
      type: acceleratorConstants.ACCELERATOR_GET_PROFILE_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.ACCELERATOR_GET_PROFILE_FAILURE,
      error
    };
  }
}

function AddUpdateProfile(data) {
  return dispatch => {
    dispatch(request());

    acceleratorService
      .AddUpdateProfile({
        amount: data.amount,
        description: data.description,
        id: data.profileId
      })
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_REQUEST
    };
  }

  function success(data) {
    return {
      type: acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_FAILURE,
      error
    };
  }
}

function sendEnterpriseInvite(data, user) {
  return dispatch => {
    dispatch(request());

    acceleratorService
      .sendEnterpriseInvite({
        fromEmail: data.email,
        url: data.url,
        userData: user,
        Anythingelse: data.Anythingelse,
        Doessomeoneofyourteam: data.Doessomeoneofyourteam,
        accelerator_id: data.accelerator_id,
        believethismanagementteam: data.believethismanagementteam,
        commentonthemanagement: data.commentonthemanagement,
        managementcompetencies: data.managementcompetencies,
        networksandindustry: data.networksandindustry,
        referthiscompany: data.referthiscompany,
        selectionprocess: data.selectionprocess,
        companyName: data.companyName
      })
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: acceleratorConstants.ACCELERATOR_EMAIL_SEND_REQUEST
    };
  }

  function success(data) {
    return {
      type: acceleratorConstants.ACCELERATOR_EMAIL_SEND_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.ACCELERATOR_EMAIL_SEND_FAILURE,
      error
    };
  }
}

function SaveEnterpriseInviteQueAns(data) {
  return dispatch => {
    dispatch(request());
    acceleratorService
      .SaveEnterpriseInviteQueAns({
        Anythingelse: data.Anythingelse,
        Doessomeoneofyourteam: data.Doessomeoneofyourteam,
        accelerator_id: data.accelerator_id,
        believethismanagementteam: data.believethismanagementteam,
        commentonthemanagement: data.commentonthemanagement,
        managementcompetencies: data.managementcompetencies,
        networksandindustry: data.networksandindustry,
        referthiscompany: data.referthiscompany,
        selectionprocess: data.selectionprocess,
        id: data.id
      })
      .then(
        response => {
          dispatch(success(response));
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request() {
    return {
      type: acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_REQUEST
    };
  }

  function success(data) {
    return {
      type: acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_FAILURE,
      error
    };
  }
}

function getEnterpriseInviteQueAns(user_id, accelerator_id, flag) {
  return dispatch => {
    dispatch(request());
    return acceleratorService
      .getEnterpriseInviteQueAns(user_id, accelerator_id, flag)
      .then(
        response => {
          dispatch(success(response));
          return Promise.resolve(response);
        },
        error => {
          dispatch(failure(error));
          return Promise.reject();
        }
      );
  };

  function request() {
    return {
      type: acceleratorConstants.ENTERPRISE_GET_QUES_ANS_REQUEST
    };
  }

  function success(data) {
    return {
      type: acceleratorConstants.ENTERPRISE_GET_QUES_ANS_SUCCESS,
      data
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.ENTERPRISE_GET_QUES_ANS_FAILURE,
      error
    };
  }
}

function getaccelerator() {
  return dispatch => {
    dispatch(request());
    return acceleratorService.getaccelerator().then(
      response => {
        dispatch(success(response));
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject();
      }
    );
  };

  function request() {
    return {
      type: acceleratorConstants.GET_ACCELERATOR_REQUEST
    };
  }

  function success(accdata) {
    console.log(accdata);
    return {
      type: acceleratorConstants.GET_ACCELERATOR_SUCCESS,
      accdata
    };
  }

  function failure(error) {
    return {
      type: acceleratorConstants.GET_ACCELERATOR_FAILURE,
      error
    };
  }
}
