export const acceleratorConstants = {
  ACCELERATOR_GET_PROFILE_REQUEST: 'ACCELERATOR_GET_PROFILE_REQUEST',
  ACCELERATOR_GET_PROFILE_SUCCESS: 'ACCELERATOR_GET_PROFILE_SUCCESS',
  ACCELERATOR_GET_PROFILE_FAILURE: 'ACCELERATOR_GET_PROFILE_FAILURE',

  ACCELERATOR_ADD_UPDATE_PROFILE_REQUEST:
    'ACCELERATOR_ADD_UPDATE_PROFILE_REQUEST',
  ACCELERATOR_ADD_UPDATE_PROFILE_SUCCESS:
    'ACCELERATOR_ADD_UPDATE_PROFILE_SUCCESS',
  ACCELERATOR_ADD_UPDATE_PROFILE_FAILURE:
    'ACCELERATOR_ADD_UPDATE_PROFILE_FAILURE',

  ACCELERATOR_EMAIL_SEND_REQUEST: 'ACCELERATOR_EMAIL_SEND_REQUEST',
  ACCELERATOR_EMAIL_SEND_SUCCESS: 'ACCELERATOR_EMAIL_SEND_SUCCESS',
  ACCELERATOR_EMAIL_SEND_FAILURE: 'ACCELERATOR_EMAIL_SEND_FAILURE',

  ENTERPRISE_SAVE_QUES_ANS_REQUEST: 'ENTERPRISE_SAVE_QUES_ANS_REQUEST',
  ENTERPRISE_SAVE_QUES_ANS_SUCCESS: 'ENTERPRISE_SAVE_QUES_ANS_SUCCESS',
  ENTERPRISE_SAVE_QUES_ANS_FAILURE: 'ENTERPRISE_SAVE_QUES_ANS_FAILURE',

  ENTERPRISE_GET_QUES_ANS_REQUEST: 'ENTERPRISE_GET_QUES_ANS_REQUEST',
  ENTERPRISE_GET_QUES_ANS_SUCCESS: 'ENTERPRISE_GET_QUES_ANS_SUCCESS',
  ENTERPRISE_GET_QUES_ANS_FAILURE: 'ENTERPRISE_SGET_QUES_ANS_FAILURE',

  GET_ACCELERATOR_REQUEST: 'GET_ACCELERATOR_REQUEST',
  GET_ACCELERATOR_SUCCESS: 'GET_ACCELERATOR_SUCCESS',
  GET_ACCELERATOR_FAILURE: 'GET_ACCELERATOR_FAILURE'
};
