import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({ component: Component, role, ...rest }) {
  const user = useSelector(state => state.user);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user.email) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          );
        }

        if (!user.role) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/role-select' }} />;
        }

        // check if route is restricted by role

        if (role && role !== user.role) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: `/${user.role.toLowerCase()}` }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
}

export default PrivateRoute;
