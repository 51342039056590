import {
  createMuiTheme,
  unstable_createMuiStrictModeTheme
} from '@material-ui/core';

const THEMES = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: '#fff',
        backgroundColor: '#12433e',
        width: '100%'
      }
    },
    MuiInputBase: {
      root: {
        height: 40,
        marginTop: 8,
        backgroundColor: '#D5F4F1'
      },
      multiline: {
        height: 'auto',
        backgroundColor: '#D5F4F1'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: `10.5px 14px`
      }
    },
    MuiButton: {
      root: {
        height: 40,
        textTransform: 'none'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontWeight: 400
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
      }
    }
  },
  palette: {
    common: {
      main: '#59D3C8',
      mainDark: '#1A8B7F',
      cyan: '#59D3C8',
      yellow: '#F0DD1E',
      red: '#FA646A',
      green1: '#12433E',
      green2: '#425D5B',
      green3: '#B1C4C3',
      green4: '#D5E6E4',
      text: '#425D5B',
      gray: '#F2F7F7',
      lightGray: '#ECECEE',
      black: '#051413'
    },
    primary: {
      light: '#59D3C8',
      main: '#59D3C8',
      dark: '#1A8B7F',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#D5E6E4',
      main: '#D5E6E4',
      dark: '#D7D7DD',
      contrastText: '#000'
    }
  },
  typography: {
    fontFamily: 'Rubik',
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 35,
      lineHeight: '43px'
    },
    subtitle2: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 'bold',
      color: '#051413'
    },
    caption: {
      fontWeight: 'bold',
      fontSize: 30
    },
    button: {
      fontSize: 16,
      lineHeight: '24px'
    },
    h1: {
      fontSize: 40,
      lineHeight: '48px'
    },
    h2: {
      fontSize: 32,
      lineHeight: '40px'
    },
    h3: {
      fontSize: 27,
      lineHeight: '32px'
    },
    h4: {
      fontSize: 20,
      lineHeight: '32px'
    },
    h5: {
      fontSize: 16,
      lineHeight: '24px'
    },
    h6: {
      fontSize: 11,
      lineHeight: '16px'
    }
  }
};

const theme =
  process.env.NODE_ENV === 'production'
    ? createMuiTheme(THEMES)
    : unstable_createMuiStrictModeTheme(THEMES);

export default theme;
