export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  SET_ROLE_REQUEST: 'SET_ROLE_REQUEST',
  SET_ROLE_SUCCESS: 'SET_ROLE_SUCCESS',
  SET_ROLE_FAILURE: 'SET_ROLE_FAILURE',

  SET_USER: 'SET_USER',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  APPROVE_DENY_REQUEST: 'APPROVE_DENY_REQUEST',
  APPROVE_DENY_SUCCESS: 'APPROVE_DENY_SUCCESS',
  APPROVE_DENY_FAILURE: 'APPROVE_DENY_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  UPDATE_TOKEN_SUCCESS: 'UPDATE_TOKEN_SUCCESS',

  ENTERPRISES_WITH_SCORE_REQUEST: 'ENTERPRISES_WITH_SCORE_REQUEST',
  ENTERPRISES_WITH_SCORE_SUCCESS: 'ENTERPRISES_WITH_SCORE_SUCCESS',
  ENTERPRISES_WITH_SCORE_FAILURE: 'ENTERPRISES_WITH_SCORE_FAILURE',

  INVESTOR_WITH_SCORE_REQUEST: 'INVESTOR_WITH_SCORE_REQUEST',
  INVESTOR_WITH_SCORE_SUCCESS: 'INVESTOR_WITH_SCORE_SUCCESS',
  INVESTOR_WITH_SCORE_FAILURE: 'INVESTOR_WITH_SCORE_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE'
};

export const userRoles = {
  ENTERPRISE: 'ENTERPRISE',
  ACCELERATOR: 'ACCELERATOR',
  INVESTOR: 'INVESTOR'
};

export const adminRole = 'ADMIN';
