import { toastr } from 'react-redux-toastr';
import authHeader from '../helpers/authHeader';

export const investorService = {
  getForm,
  createForm,
  updateForm,
  CheckInvestorForm,
  investorFiancialRiskScores,
  investorImpactRiskScores
};
const toastrOptions = {
  timeOut: 3000, // by setting to 0 it will prevent the auto close
  position: 'bottom-right'
};
const BASEURL = process.env.REACT_APP_API_URL;

function getForm(id) {
  const url = `/investor/form/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function investorFiancialRiskScores(id) {
  const url = `/investor/investor-fiancial-risk-scores/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function investorImpactRiskScores(id) {
  const url = `/investor/investor-impact-risk-scores/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function createForm(body) {
  const url = `/investor/form`;

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Form answers have been saved', toastrOptions);
      return response.data;
    });
}

function updateForm(id, body) {
  const url = `/investor/form/${id}`;

  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      toastr.success('Success', 'Form answers have been saved', toastrOptions);
      return response.data;
    });
}

function CheckInvestorForm() {
  const url = `/investor/check-investor-form`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      toastr.error('Error', error);
      return Promise.reject(error);
    }
    return data;
  });
}
