import {
  Box,
  Grid,
  Divider,
  useMediaQuery,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import { useFormik } from 'formik';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useState, useEffect } from 'react';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  StyledCheckedIcon,
  StyledIcon,
  StyledRadio,
  RadioLabel,
  BorderedRadioGroup,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
const questionWeight = [
  {
    question: 'Risk Tolerance',
    Weight: 1
  },
  {
    question: 'Liquidity Needs',
    Weight: 0
  },
  {
    question: 'Time Horizon',
    Weight: 0
  },
  {
    question: 'Primary Return Objective',
    Weight: 0
  }
];
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  investmentGoals: yup.object().shape({
    riskTolerance: yup.string().required('Risk Tolerance is required'),
    liquidityNeeds: yup.string().required('Liquidity NEEDS is required'),
    timeHorizon: yup.string().required('Time Horizon is required')
  }),
  impactPortfolio: yup.object().shape({
    primaryReturnObjective: yup
      .string()
      .required('Primary Return Objective is required'),
    primaryInvestmentobjective: yup
      .string()
      .required('Primary Investment Objective is required'),
    investmentPreference: yup
      .string()
      .required('Investment Preference is required'),
    impactProfile: yup.string().required('Impact Profile is required')
  })
});
const initialValues = {
  investmentGoals: {
    riskTolerance: '',
    liquidityNeeds: '',
    timeHorizon: ''
  },
  impactPortfolio: {
    primaryReturnObjective: '',
    primaryInvestmentobjective: '',
    investmentPreference: '',
    impactProfile: ''
  }
};

const investorFinancialRiskScore = {
  investor_user_id: '',
  riskTolerance: { answer: '', score: '' },
  liquidityNeeds: { answer: '', score: '' },
  timeHorizon: { answer: '', score: '' },
  primaryReturnObjective: { answer: '', score: '' },
  finalRiskScore: ''
};

const investorImpactRiskScore = {
  investor_user_id: '',
  numberofSDGAbove10Percent: { answer: '', score: '' },
  numberofGeographies: { answer: '', score: '' },
  impactProfile: { answer: '', score: '' },
  SGDBreakdown: {
    NoPoverty: '',
    ZeroHunger: '',
    goodHealth: '',
    qualityEducation: '',
    GenderEquality: '',
    IndustryInnovationInfrastructure: '',
    ClimateAction: '',
    CleanWaterSanitation: '',
    ReducedInequality: '',
    LifeBelowWater: '',
    AffordableCleanEnergy: '',
    SustainableCitiesCommunities: '',
    LifeonLand: '',
    PeaceJusticeStrongInsitutions: '',
    DecentWorkandEconomicGrowth: '',
    ResponsibleProductionConsumption: '',
    Partnerships: ''
  },
  ImpactScore: ''
};

const InvestingEcosystem1 = ({
  onSave,
  form,
  RiskScores,
  onNext,
  onPrev,
  onSkip,
  ImpactRiskScores,
  accelerators,
  investors,
  enterpriseSeekLoan
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [getRiskScores, setRiskScores] = useState([]);
  const [getImpactRiskScores, setImpactRiskScores] = useState([]);
  const activeData = useSelector(state => state.user);
  useEffect(() => {
    if (Object.keys(RiskScores).length == 0) {
      setRiskScores(investorFinancialRiskScore);
    } else {
      setRiskScores(RiskScores);
    }
    if (Object.keys(ImpactRiskScores).length == 0) {
      setImpactRiskScores(investorImpactRiskScore);
    } else {
      setImpactRiskScores(ImpactRiskScores);
    }
    window.scrollTo(0, 0);
  }, [RiskScores, ImpactRiskScores]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues, ...form },
    validationSchema,
    onSubmit: values => {
      var questionWeightSum = 0;
      questionWeight.forEach(element => {
        questionWeightSum += element.Weight;
      });
      values.investorFinancialRiskScore = investorFinancialRiskScore;
      values.investorImpactRiskScore = investorImpactRiskScore;
      if (values.investmentGoals.riskTolerance == 'Conservative') {
        values.investorFinancialRiskScore.riskTolerance.score = 2;
      } else if (values.investmentGoals.riskTolerance == 'Moderate') {
        values.investorFinancialRiskScore.riskTolerance.score = 4;
      } else if (values.investmentGoals.riskTolerance == 'Aggressive') {
        values.investorFinancialRiskScore.riskTolerance.score = 6;
      } else if (values.investmentGoals.riskTolerance == 'Speculative') {
        values.investorFinancialRiskScore.riskTolerance.score = 8;
      }
      if (values.investmentGoals.liquidityNeeds == 'Within 3 Months') {
        values.investorFinancialRiskScore.liquidityNeeds.score = 2;
      } else if (values.investmentGoals.liquidityNeeds == '4-6 Months') {
        values.investorFinancialRiskScore.liquidityNeeds.score = 4;
      } else if (values.investmentGoals.liquidityNeeds == '7-9 Months') {
        values.investorFinancialRiskScore.liquidityNeeds.score = 6;
      } else if (values.investmentGoals.liquidityNeeds == '10-12 Months') {
        values.investorFinancialRiskScore.liquidityNeeds.score = 8;
      } else if (
        values.investmentGoals.liquidityNeeds == 'More than 12 Months'
      ) {
        values.investorFinancialRiskScore.liquidityNeeds.score = 5;
      }

      if (values.investmentGoals.timeHorizon == 'Within 3 Months') {
        values.investorFinancialRiskScore.timeHorizon.score = 2;
      } else if (values.investmentGoals.timeHorizon == '4-6 Months') {
        values.investorFinancialRiskScore.timeHorizon.score = 4;
      } else if (values.investmentGoals.timeHorizon == '7-9 Months') {
        values.investorFinancialRiskScore.timeHorizon.score = 6;
      } else if (values.investmentGoals.timeHorizon == '10-12 Months') {
        values.investorFinancialRiskScore.timeHorizon.score = 8;
      } else if (values.investmentGoals.timeHorizon == 'More than 12 Months') {
        values.investorFinancialRiskScore.timeHorizon.score = 5;
      }

      if (
        values.impactPortfolio.primaryReturnObjective == 'Zero Return / Grant'
      ) {
        values.investorFinancialRiskScore.primaryReturnObjective.score = 8;
      } else if (
        values.impactPortfolio.primaryReturnObjective == 'Below Market'
      ) {
        values.investorFinancialRiskScore.primaryReturnObjective.score = 6;
      } else if (
        values.impactPortfolio.primaryReturnObjective == 'Market Rate'
      ) {
        values.investorFinancialRiskScore.primaryReturnObjective.score = 4;
      } else if (
        values.impactPortfolio.primaryReturnObjective == 'Above Market'
      ) {
        values.investorFinancialRiskScore.primaryReturnObjective.score = 2;
      }
      values.investorFinancialRiskScore.riskTolerance.answer =
        values.investmentGoals.riskTolerance;
      values.investorFinancialRiskScore.liquidityNeeds.answer =
        values.investmentGoals.liquidityNeeds;
      values.investorFinancialRiskScore.timeHorizon.answer =
        values.investmentGoals.timeHorizon;
      values.investorFinancialRiskScore.primaryReturnObjective.answer =
        values.impactPortfolio.primaryReturnObjective;

      var riskTolerance =
        values.investorFinancialRiskScore.riskTolerance.score * 1;
      var liquidityNeeds =
        values.investorFinancialRiskScore.liquidityNeeds.score * 0;
      var timeHorizon = values.investorFinancialRiskScore.timeHorizon.score * 0;
      var primaryReturnObjective =
        values.investorFinancialRiskScore.primaryReturnObjective.score * 0;
      if (Object.keys(RiskScores).length != 0) {
        values.investorFinancialRiskScore.id = getRiskScores.id;
        values.investorFinancialRiskScore.investor_user_id =
          getRiskScores.investor_user_id;
      }

      if (Object.keys(ImpactRiskScores).length != 0) {
        values.investorImpactRiskScore.id = getImpactRiskScores.id;
        values.investorImpactRiskScore.investor_user_id =
          getImpactRiskScores.investor_user_id;
      }

      if (values.impactPortfolio.impactProfile == 'Neutral') {
        values.investorImpactRiskScore.impactProfile.answer =
          values.impactPortfolio.impactProfile;
        values.investorImpactRiskScore.impactProfile.score = 2;
      } else if (
        values.impactPortfolio.impactProfile == 'Benefit Stakeholders'
      ) {
        values.investorImpactRiskScore.impactProfile.answer =
          values.impactPortfolio.impactProfile;
        values.investorImpactRiskScore.impactProfile.score = 4;
      } else if (values.impactPortfolio.impactProfile == 'Act to Avoid Harm') {
        values.investorImpactRiskScore.impactProfile.answer =
          values.impactPortfolio.impactProfile;
        values.investorImpactRiskScore.impactProfile.score = 3;
      } else if (
        values.impactPortfolio.impactProfile == 'Contribute to Solutions'
      ) {
        values.investorImpactRiskScore.impactProfile.answer =
          values.impactPortfolio.impactProfile;
        values.investorImpactRiskScore.impactProfile.score = 1;
      }
      var finalRiskScore = riskTolerance + liquidityNeeds + timeHorizon;
      //finalRiskScore = finalRiskScore / questionWeightSum;
      console.log(finalRiskScore);
      //console.log(questionWeightSum);
      values.investorFinancialRiskScore.finalRiskScore = parseFloat(
        finalRiskScore.toFixed(2)
      );
      console.log(values);
      onSave(values);
      onNext();
      onSkip();
    }
  });
  const toolTipText = () => (
    <div>
      <p style={{ width: '100%' }}>
        Per The Impact Management Project (IMP) classification guide, the below
        are the categories of impact goals for any impact-driven enterprise or
        investor:
      </p>
      <p style={{ width: '100%' }}>
        <b>Act to Avoid Harm.</b> The enterprise prevents or reduces significant
        effects on important negative outcomes for people and the planet.
      </p>
      <p style={{ width: '100%' }}>
        <b>Benefit Stakeholders.</b> The enterprise not only acts to avoid harm,
        but also generates various effects on positive outcomes for people and
        the planet.
      </p>
      <p style={{ width: '100%' }}>
        <b>Contribute to Solutions </b> The enterprise not only acts to avoid
        harm, but also generates one or more significant effect(s) on positive
        outcomes for otherwise underserved people and the planet.
      </p>
      <p style={{ width: '100%' }}>
        For more information, please refer to: Impact Classes | Impact
        Management Project. Enterprises are expected to track their impact
        according to industry standard impact reporting standards.
      </p>
    </div>
  );
  var SeekLoan = 0;
  if (enterpriseSeekLoan.data) {
    SeekLoan = enterpriseSeekLoan.data.reduce(function (accumulator, item) {
      return accumulator + parseInt(item.seekingLoan);
    }, 0);
  }
  var info =
    activeData.first + ' ' + activeData.last + '/' + activeData.organization;
  return (
    <>
      <Box mb={6}>
        <Typography variant="h1">
          <strong>Your Investing Ecosystem</strong>
          <Tooltip
            arrow
            placement="right-start"
            display="flex"
            title="Your Investing Ecosystem is the collective group of investors to which you identify; Investor Groups on ImpactCred range from a loose affiliation based on investment goals to a membership affiliation to which you have subscribed in order to be able to share intelligence, receive specific deals and deploy capital in alignment with values."
          >
            <HelpOutlineIcon style={{ fontSize: '25px', margin: '0px 4px' }} />
          </Tooltip>
        </Typography>
        <Typography variant="h2">
          <strong>Set My Goals</strong>
        </Typography>
        <Typography variant="h4">
          Set your investor profile by defining your financial and impact goals
          on ImpactCred’s deal platform.
        </Typography>
      </Box>
      <Grid container direction="column" spacing={3} style={{ width: '100%' }}>
        <Grid item>
          <Card title={info}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={4} container justify="space-between">
                <Box>
                  <Typography variant="h2">
                    <strong>
                      {localStorage.getItem('SeekLoan') ? (
                        <NumberFormat
                          value={localStorage.getItem('SeekLoan')}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      ) : (
                        <CircularProgress size={40} status={'loading'} />
                      )}
                    </strong>
                  </Typography>
                  <Typography variant="h6">TOTAL DEALFLOW</Typography>
                </Box>
                {!isMobile && <Divider orientation="vertical" />}
              </Grid>
              <Grid item sm={12} md={4} container justify="space-between">
                <Box>
                  <Typography variant="h2">
                    <strong>
                      {accelerators.length ? (
                        accelerators.length
                      ) : (
                        <CircularProgress size={40} status={'loading'} />
                      )}
                    </strong>
                  </Typography>
                  <Typography variant="h6">
                    ACCELERATOR RELATIONSHIPS
                  </Typography>
                </Box>
                {!isMobile && <Divider orientation="vertical" />}
              </Grid>
              <Grid item sm={12} md={4}>
                <Typography variant="h2">
                  <strong>
                    {investors.length ? (
                      investors.length
                    ) : (
                      <CircularProgress size={40} status={'loading'} />
                    )}
                  </strong>
                </Typography>
                <Typography variant="h6">ACTIVE INVESTORS</Typography>
              </Grid>
            </Grid>
            <Box mt={1} mb={2}>
              <Divider />
            </Box>
            <Typography variant="h4">
              Your organization has access to over{' '}
              {localStorage.getItem('SeekLoan') ? (
                <NumberFormat
                  value={localStorage.getItem('SeekLoan')}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                />
              ) : (
                <CircularProgress size={40} status={'loading'} />
              )}{' '}
              in dealflow,{'  '}
              {investors.length ? (
                investors.length
              ) : (
                <CircularProgress size={40} status={'loading'} />
              )}
              {'  '} active investors, and{' '}
              {accelerators.length ? (
                accelerators.length
              ) : (
                <CircularProgress size={40} status={'loading'} />
              )}{' '}
              accelerators involved with available companies.
            </Typography>
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                arrow
                placement="right-start"
                display="flex"
                title="Your Investing Ecosystem is the collective group of investors to which you identify; Investor Groups on ImpactCred range from a loose affiliation based on investment goals to a membership affiliation to which you have subscribed in order to be able to share intelligence, receive specific deals and deploy capital in alignment with values."
              >
                <HelpOutlineIcon
                  style={{ fontSize: '25px', margin: '0px 4px' }}
                />
              </Tooltip>
            </Box>
          </Card>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid item>
            <Card title="Define Your Investing Goals">
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Typography variant="h4">
                    Looking specifically at the capital you wish to deploy
                    within ImpactCred, how would you define your goals for
                    financial returns?
                  </Typography>
                  <Box my={3}>
                    <Divider />
                  </Box>
                  {formik.errors.investmentGoals &&
                  formik.errors.investmentGoals.riskTolerance ? (
                    <InputFeedback
                      error={formik.errors.investmentGoals.riskTolerance}
                    />
                  ) : null}
                  <Box display="flex" justifyContent="space-around">
                    <RadioLabel>Conservative</RadioLabel>
                    <RadioLabel>Moderate</RadioLabel>
                    <RadioLabel>Aggressive</RadioLabel>
                    <RadioLabel>Speculative</RadioLabel>
                  </Box>
                  <RadioGroup
                    id="riskTolerance"
                    name="investmentGoals.riskTolerance"
                    value={formik.values.investmentGoals.riskTolerance}
                    onChange={formik.handleChange}
                    error={formik.errors.investmentGoals}
                  >
                    <BorderedRadioGroup
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        value="Conservative"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="Moderate"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="Aggressive"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="Speculative"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                    </BorderedRadioGroup>
                  </RadioGroup>
                  <Box mt={3} mb={4}>
                    <Typography variant="h6">RISK TOLERANCE</Typography>
                  </Box>
                  {formik.errors.investmentGoals &&
                  formik.errors.investmentGoals.liquidityNeeds ? (
                    <InputFeedback
                      error={formik.errors.investmentGoals.liquidityNeeds}
                    />
                  ) : null}
                  <Box display="flex" justifyContent="space-around">
                    <RadioLabel>Within 3 Months</RadioLabel>
                    <RadioLabel>4-6 Months</RadioLabel>
                    <RadioLabel>7-9 Months</RadioLabel>
                    <RadioLabel>10-12 Months</RadioLabel>
                    <RadioLabel>More than 12 Months</RadioLabel>
                  </Box>
                  <RadioGroup
                    id="liquidityNeeds"
                    name="investmentGoals.liquidityNeeds"
                    value={formik.values.investmentGoals.liquidityNeeds}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.investmentGoals &&
                      formik.touched.investmentGoals.liquidityNeeds &&
                      formik.errors.investmentGoals &&
                      formik.errors.investmentGoals.liquidityNeeds
                    }
                  >
                    <BorderedRadioGroup
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        value="Within 3 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="4-6 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="7-9 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="10-12 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="More than 12 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                    </BorderedRadioGroup>
                  </RadioGroup>
                  <Box mt={3} mb={4}>
                    <Typography variant="h6">LIQUIDITY NEEDS</Typography>
                  </Box>
                  {formik.errors.investmentGoals &&
                  formik.errors.investmentGoals.timeHorizon ? (
                    <InputFeedback
                      error={formik.errors.investmentGoals.timeHorizon}
                    />
                  ) : null}
                  <Box display="flex" justifyContent="space-around">
                    <RadioLabel>Within 3 Months</RadioLabel>
                    <RadioLabel>4-6 Months</RadioLabel>
                    <RadioLabel>7-9 Months</RadioLabel>
                    <RadioLabel>10-12 Months</RadioLabel>
                    <RadioLabel>More than 12 Months</RadioLabel>
                  </Box>
                  <RadioGroup
                    id="timeHorizon"
                    name="investmentGoals.timeHorizon"
                    value={formik.values.investmentGoals.timeHorizon}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.investmentGoals &&
                      formik.touched.investmentGoals.timeHorizon &&
                      formik.errors.investmentGoals &&
                      formik.errors.investmentGoals.timeHorizon
                    }
                  >
                    <BorderedRadioGroup
                      display="flex"
                      justifyContent="space-around"
                    >
                      <FormControlLabel
                        value="Within 3 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="4-6 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="7-9 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="10-12 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        value="More than 12 Months"
                        control={
                          <StyledRadio
                            disableRipple
                            icon={<StyledIcon />}
                            checkedIcon={<StyledCheckedIcon />}
                          />
                        }
                      />
                    </BorderedRadioGroup>
                  </RadioGroup>
                  <Box mt={3} mb={4}>
                    <Typography variant="h6">TIME HORIZON</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box my={2}>
                <Divider />
              </Box>
              {/* <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  startIcon={<img src={iconNext} alt="next" />}
                  type="submit"
                >
                  Next
                </Button>
              </Box> */}
            </Card>
          </Grid>
          <Grid item>
            <Card title="Additional Information - Impact Portfolio">
              <Tooltip
                arrow
                placement="right-start"
                display="flex"
                title="These questions seek to map your investor preferences. We understand some of these terms are used differently by different investors. Our goal here is to help you self-identify your goals for your core investment portfolio. If your portfolio spans across multiple investments, you are welcome to choose multiple choices."
              >
                <HelpOutlineIcon
                  style={{
                    fontSize: '25px',
                    margin: '0px 4px',
                    position: 'relative',
                    top: '-50px',
                    left: '37%'
                  }}
                />
              </Tooltip>
              <Grid
                container
                direction="column"
                spacing={2}
                style={{ padding: '0px' }}
              >
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Please identify the type of return that you typically seek. We understand the market is a rate that is used differently by different investors. It refers to a rate where you are not trading off any return while specifically seeking impact."
                  >
                    <Typography variant="h4">
                      Primary Return Objective
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <RadioGroup
                    id="primaryReturnObjective"
                    name="impactPortfolio.primaryReturnObjective"
                    value={formik.values.impactPortfolio.primaryReturnObjective}
                    onChange={formik.handleChange}
                    style={{ padding: '10px' }}
                  >
                    {formik.errors.impactPortfolio &&
                    formik.errors.impactPortfolio.primaryReturnObjective ? (
                      <InputFeedback
                        error={
                          formik.errors.impactPortfolio.primaryReturnObjective
                        }
                      />
                    ) : null}

                    <Grid container>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Zero Return / Grant"
                          label="Zero Return / Grant"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Below Market"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Below Market"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Market Rate"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Market Rate"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Above Market"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Above Market"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Please identify the type of growth for your investment that you typically seek."
                  >
                    <Typography variant="h4">
                      Primary Investment Objective
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <RadioGroup
                    id="primaryInvestmentobjective"
                    name="impactPortfolio.primaryInvestmentobjective"
                    value={
                      formik.values.impactPortfolio.primaryInvestmentobjective
                    }
                    onChange={formik.handleChange}
                    error={
                      formik.touched.impactPortfolio &&
                      formik.touched.impactPortfolio
                        .primaryInvestmentobjective &&
                      formik.errors.impactPortfolio &&
                      formik.errors.impactPortfolio.primaryInvestmentobjective
                    }
                    style={{ padding: '10px' }}
                  >
                    {formik.errors.impactPortfolio &&
                    formik.errors.impactPortfolio.primaryInvestmentobjective ? (
                      <InputFeedback
                        error={
                          formik.errors.impactPortfolio
                            .primaryInvestmentobjective
                        }
                      />
                    ) : null}
                    <Grid container>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Conservative"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Conservative"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Moderate"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Moderate"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Moderate Growth"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Moderate Growth"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Aggressive Growth"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Aggressive Growth"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right-start"
                    display="flex"
                    title="Please identify the type of instrument that you typically invest in."
                  >
                    <Typography variant="h4">
                      Primary Investment Preference
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <RadioGroup
                    id="investmentPreference"
                    name="impactPortfolio.investmentPreference"
                    value={formik.values.impactPortfolio.investmentPreference}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.impactPortfolio &&
                      formik.touched.impactPortfolio.investmentPreference &&
                      formik.errors.impactPortfolio &&
                      formik.errors.impactPortfolio.investmentPreference
                    }
                    style={{ padding: '10px' }}
                  >
                    {formik.errors.impactPortfolio &&
                    formik.errors.impactPortfolio.investmentPreference ? (
                      <InputFeedback
                        error={
                          formik.errors.impactPortfolio.investmentPreference
                        }
                      />
                    ) : null}
                    <Grid container>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Short-term Debt"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Short-term Debt"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Long-term Debt"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Long-term Debt"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Convertible Debt"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Convertible Debt"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Venture Capital and Equity"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Venture Capital and Equity "
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Private Equity"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Private Equity"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Blended"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Blended"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right-top"
                    display="flex"
                    title={toolTipText()}
                  >
                    <Typography variant="h4">
                      Primary Impact Profile
                      <HelpOutlineIcon
                        style={{ fontSize: '25px', margin: '-6px 4px' }}
                      />
                    </Typography>
                  </Tooltip>
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <RadioGroup
                    id="impactProfile"
                    name="impactPortfolio.impactProfile"
                    value={formik.values.impactPortfolio.impactProfile}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.impactPortfolio &&
                      formik.touched.impactPortfolio.impactProfile &&
                      formik.errors.impactPortfolio &&
                      formik.errors.impactPortfolio.impactProfile
                    }
                    style={{ padding: '10px' }}
                  >
                    {formik.errors.impactPortfolio &&
                    formik.errors.impactPortfolio.impactProfile ? (
                      <InputFeedback
                        error={formik.errors.impactPortfolio.impactProfile}
                      />
                    ) : null}
                    <Grid container>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Neutral"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Neutral"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Act to Avoid Harm"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Act to Avoid Harm"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Benefit Stakeholders"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Benefit Stakeholders"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormControlLabel
                          value="Contribute to Solutions"
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label="Contribute to Solutions"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Box mt={4} mb={2}>
                <Divider />
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="button"
                  onClick={onPrev}
                >
                  Prev
                </Button>
                <Box mr={3} />
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                >
                  Next
                </Button>
              </Box>
            </Card>
          </Grid>
          {/* <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={onSkip}
            >
              Skip for now
            </Button>
          </Box> */}
        </form>
      </Grid>
    </>
  );
};

export default InvestingEcosystem1;
