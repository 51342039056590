import { useCallback, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import avatar from '../../../assets/images/avatar.svg';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import { userRoles } from '../../../constants/user';
const Avatar = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(state => state.user);
  const handleOpenMenu = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogout = useCallback(() => {
    dispatch(userActions.logout());
    history.push('/');
  }, [dispatch]);

  const OpenProfile = useCallback(() => {
    history.push('/accelerator/profile');
  }, []);

  const OpenAcceleratorHome = useCallback(() => {
    history.push('/accelerator/home');
  }, []);

  return (
    <>
      <Box onClick={handleOpenMenu}>
        <img src={avatar} alt="avatar" />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={OpenProfile}>Profile</MenuItem>
        {user.role == userRoles.ACCELERATOR && (
          <MenuItem onClick={OpenAcceleratorHome}>Enterprise Invite</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Avatar;
