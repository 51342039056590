import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Card from '../../common/Card/Card';
import iconDealdetail from '../../../assets/images/dealdetail.svg';
import theme from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
const DealDetails = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useSelector(state => state.enterprise.form);
  return (
    <div id="de_d">
      <Card title="Deal Details" icon={iconDealdetail}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4} container justify="space-between">
            <Box>
              <Typography variant="h2">
                {form && form.formAnswers && form.formAnswers.financingNeeds ? (
                  <NumberFormat
                    value={form.formAnswers.financingNeeds.seekingLoan}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                ) : (
                  'N/A'
                )}
              </Typography>
              <Typography>SEEKING INVESTMENT</Typography>
            </Box>
            {!isMobile && <Divider orientation="vertical" />}
          </Grid>
          {/* <Grid item sm={12} md={4} container justify="space-between">
          <Box>
            <Typography variant="h2">12.22%</Typography>
            <Typography>RATE OF INTEREST</Typography>
          </Box>
          {!isMobile && <Divider orientation="vertical" />}
        </Grid> */}

          <Grid item sm={12} md={4}>
            <Typography variant="h2">
              {form && form.formAnswers && form.formAnswers.financingNeeds
                ? form.formAnswers.financingNeeds.repayment
                : 'N/A'}
            </Typography>
            <Typography>RETURN HORIZON</Typography>
          </Grid>
        </Grid>
        <Box mt={1} mb={2}>
          <Divider />
        </Box>
        <Typography variant="h4">
          {form && form.formAnswers && form.formAnswers.pillar1Earnings
            ? form.formAnswers.pillar1Earnings.explainBusinessModel
            : 'N/A'}
        </Typography>
        {/* <Box mt={1} mb={1}>
          <Divider />
        </Box>
        <Typography color="primary" variant="h3">
          ACCELERATOR LINK
        </Typography> */}
      </Card>
    </div>
  );
};

export default DealDetails;
