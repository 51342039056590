import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { enterpriseActions } from '../../actions/enterpriseActions';
import IntakeForm from './IntakeForm/IntakeForm';
import Onboarding from './Onboarding/Onboarding';
import PendingApproval from './PendingApproval/PendingApproval';
import Rejected from './PendingApproval/Rejected';
import HomeEnterprise from './Home/Home';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import EnterpriseEmailQuesAnswer from '../Accelerator/Home/Home';
const Enterprise = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  useEffect(() => {
    user && user.id && dispatch(enterpriseActions.getForm(user.id));
  }, [dispatch, user]);

  return (
    <Switch>
      <PrivateRoute path="/enterprise/home" exact component={HomeEnterprise} />
      <Route path="/enterprise/form" component={IntakeForm} />
      <Route path="/enterprise/onboarding" component={Onboarding} />
      <Route path="/enterprise/rejected" component={Rejected} />
      <Route
        path="/enterprise/email/:accelerator_id"
        component={EnterpriseEmailQuesAnswer}
      />
      <Route path="/enterprise/pending-approval" component={PendingApproval} />
      <Redirect from="/enterprise" to="/enterprise/home" />
    </Switch>
  );
};

export default Enterprise;
