import { toastr } from 'react-redux-toastr';
import authHeader from '../helpers/authHeader';

export const userService = {
  login,
  logout,
  register,
  list,
  listByRole,
  get,
  update,
  approveOrDeny,
  token,
  forgotPassword,
  resetPassword,
  getAllEnterprisesWithScore,
  getAllInvestorsWithScore,
  deleteUser
};

const BASEURL = process.env.REACT_APP_API_URL;
const toastrOptions = {
  timeOut: 3000, // by setting to 0 it will prevent the auto close
  position: 'bottom-right'
};
function login(body) {
  const url = `/auth/login`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('TOKEN', JSON.stringify(response.data.token));
      toastr.success('Success', 'Login Success.', toastrOptions);
      return response.data;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('TOKEN');
}

function register(body) {
  const url = `/auth/register`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('TOKEN', JSON.stringify(response.data.token));
      console.log(response.data);
      toastr.success('Success', 'Registration Success.', toastrOptions);
      return response.data;
    });
}

function forgotPassword(body) {
  const url = `/auth/forgot-password`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      toastr.success(
        'Success',
        'An email has been sent on your registered email. Please check your email inbox.',
        toastrOptions
      );
      return response.data;
    });
}

function resetPassword(body) {
  const url = `/auth/reset-password`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      toastr.success('Success', 'Password reset successfully', toastrOptions);
      return response.data;
    });
}

function token(body) {
  let url = `/auth/token`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      localStorage.setItem('TOKEN', JSON.stringify(response.data.token));
      return response.data;
    });
}

function list() {
  const url = `/user/`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function listByRole(body) {
  const url = `/user/`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function get(id) {
  let url = `/user/${id}`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function getAllEnterprisesWithScore() {
  let url = `/user/all-enterprises/with-score`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function getAllInvestorsWithScore() {
  let url = `/user/all-investors/with-score`;

  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response.data;
    });
}

function update(id, body) {
  let url = `/user/${id}`;

  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      //toastr.success('Success', 'Saved successfully.', toastrOptions);
      return response.data;
    });
}

function approveOrDeny(id, status) {
  const url = `/user/${id}/approve`;

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      approve: status
    })
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (response.data.message) {
        toastr.success('Success', response.data.message);
      }
      return response.data;
    });
}

function deleteUser(id, type) {
  const url = `/user/${id}/delete/${type}`;

  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      type: type
    })
  };

  return fetch(BASEURL + url, requestOptions)
    .then(handleResponse)
    .then(response => {
      if (response.data.message) {
        toastr.success('Success', response.data.message);
      }
      return response.data;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      toastr.error('Error', error, toastrOptions);
      return Promise.reject(error);
    }

    //toastr.success('Success', data.message);
    return data;
  });
}
