import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { UserStatus } from '../../../constants';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../common/Card/Card';
import { investorActions } from '../../../actions/investorActions';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { userActions } from '../../../actions/userActions';
import { toastr } from 'react-redux-toastr';
import {
  EnhancedTableHead,
  stableSort,
  getComparator
} from '../../common/Table/Table';
import Chip from '@material-ui/core/Chip';
import { Grid } from '@material-ui/core';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem
} from '@material-ui/core';
const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'organization',
    numeric: false,
    disablePadding: false,
    label: 'Organization'
  },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: '', numeric: false, disablePadding: false, label: 'Impact Score' },
  { id: '', numeric: false, disablePadding: false, label: 'Financial Score' },
  // { id: 'dob', numeric: false, disablePadding: false, label: 'Date of Birth' },
  // { id: 'gender', numeric: false, disablePadding: false, label: 'Gender' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  denyButton: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.gray
  }
}));

export default function Investors({ rows, onAction }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [EditScore, setEditScore] = React.useState(false);
  const [content, setcontent] = React.useState(false);
  const [UserInfo, setUserInfo] = React.useState([]);
  const investorForm = useSelector(state => state.investor.form.formAnswers);
  const investorRiskScores = useSelector(state => state.investor.RiskScores);
  const investorRiskImpactScores = useSelector(
    state => state.investor.ImpactRiskScores
  );
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleContent = row => {
    setUserInfo(row);
    dispatch(investorActions.getForm(row.id));
    setcontent(true);
  };
  const handleClickOpen = row => {
    setEditScore(true);
    dispatch(investorActions.investorFiancialRiskScores(row.id));
    dispatch(investorActions.investorImpactRiskScores(row.id));
  };

  const handleClose = () => {
    setEditScore(false);
    setcontent(false);
  };
  const DeleteUser = id => {
    console.log(id);
    if (confirm('Are you sure you want to delete this user')) {
      dispatch(userActions.deleteUser(id, 'Investors')).then(data => {
        toastr.success('Success', 'User deleted successful');
        dispatch(userActions.getAllInvestorsWithScore());
      });
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.id + 'tbl'}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.first} {row.last}
                    </TableCell>
                    <TableCell>{row.organization}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.adminUserImpactScore}</TableCell>
                    <TableCell>{row.adminUserfinalFinRiskScore}</TableCell>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '2px',
                              backgroundColor: '#4791db'
                            }}
                            onClick={e => handleContent(row)}
                          >
                            View
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '5px',
                              backgroundColor: '#4791db'
                            }}
                            onClick={e => handleClickOpen(row)}
                          >
                            View Score
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            fontSize: '13px',
                            height: '25px',
                            padding: '2px'
                          }}
                          className={classes.denyButton}
                          onClick={e => DeleteUser(row.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={EditScore}
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View Score
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Financial Risk Recommendation">
              <Box mb={2} mt={3}>
                <Typography variant="h4">Risk Tolerance</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskScores && investorRiskScores.riskTolerance
                    ? investorRiskScores.riskTolerance.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskScores && investorRiskScores.riskTolerance
                    ? investorRiskScores.riskTolerance.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Liquidity Needs</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskScores && investorRiskScores.liquidityNeeds
                    ? investorRiskScores.liquidityNeeds.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskScores && investorRiskScores.liquidityNeeds
                    ? investorRiskScores.liquidityNeeds.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Time Horizon</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskScores && investorRiskScores.timeHorizon
                    ? investorRiskScores.timeHorizon.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskScores && investorRiskScores.timeHorizon
                    ? investorRiskScores.timeHorizon.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Primary Return Objective</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskScores &&
                  investorRiskScores.primaryReturnObjective
                    ? investorRiskScores.timeHorizon.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskScores &&
                  investorRiskScores.primaryReturnObjective
                    ? investorRiskScores.primaryReturnObjective.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Financial Risk Score</Typography>
                <Typography variant="h5">
                  {investorRiskScores && investorRiskScores.finalRiskScore
                    ? investorRiskScores.finalRiskScore
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
            <Card title="Impact Recommendation">
              <Box mb={2} mt={3}>
                <Typography variant="h4">SDG Breakdown</Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">No Poverty</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.NoPoverty
                            ? investorRiskImpactScores.SGDBreakdown.NoPoverty +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Gender Equality</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.GenderEquality
                            ? investorRiskImpactScores.SGDBreakdown
                                .GenderEquality + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Industry Innovation & Infrastructure
                        </TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .IndustryInnovationInfrastructure
                            ? investorRiskImpactScores.SGDBreakdown
                                .IndustryInnovationInfrastructure + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Climate Action</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.climateAction
                            ? investorRiskImpactScores.SGDBreakdown
                                .climateAction + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Zero Hunger</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.ZeroHunger
                            ? investorRiskImpactScores.SGDBreakdown.ZeroHunger +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Clean Water & Sanitation</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .CleanWaterSanitation
                            ? investorRiskImpactScores.SGDBreakdown
                                .CleanWaterSanitation + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Reduced Inequality</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .ReducedInequality
                            ? investorRiskImpactScores.SGDBreakdown
                                .ReducedInequality + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Life Below Water</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.LifeBelowWater
                            ? investorRiskImpactScores.SGDBreakdown
                                .LifeBelowWater + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Good Health and Well Being</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.goodHealth
                            ? investorRiskImpactScores.SGDBreakdown.goodHealth +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Affordable & Clean Energy</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .AffordableCleanEnergy
                            ? investorRiskImpactScores.SGDBreakdown
                                .AffordableCleanEnergy + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sustainable Cities & Communities</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .SustainableCitiesCommunities
                            ? investorRiskImpactScores.SGDBreakdown
                                .SustainableCitiesCommunities + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Life on Land</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.LifeonLand
                            ? investorRiskImpactScores.SGDBreakdown.LifeonLand +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Peace & Justice Strong Insitutions
                        </TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .PeaceJusticeStrongInsitutions
                            ? investorRiskImpactScores.SGDBreakdown
                                .PeaceJusticeStrongInsitutions + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quality Education</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.qualityEducation
                            ? investorRiskImpactScores.SGDBreakdown
                                .qualityEducation + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Decent Work and Economic Growth</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .DecentWorkandEconomicGrowth
                            ? investorRiskImpactScores.SGDBreakdown
                                .DecentWorkandEconomicGrowth + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Responsible Production & Consumption
                        </TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown
                            .ResponsibleProductionConsumption
                            ? investorRiskImpactScores.SGDBreakdown
                                .ResponsibleProductionConsumption + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Partnerships</TableCell>
                        <TableCell align="left">
                          {investorRiskImpactScores &&
                          investorRiskImpactScores.SGDBreakdown &&
                          investorRiskImpactScores.SGDBreakdown.Partnerships
                            ? investorRiskImpactScores.SGDBreakdown
                                .Partnerships + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">100%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Number of SDG Above 10%</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.numberofSDGAbove10Percent
                    ? investorRiskImpactScores.numberofSDGAbove10Percent.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.numberofSDGAbove10Percent
                    ? investorRiskImpactScores.numberofSDGAbove10Percent.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Number of Geographies</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.numberofGeographies
                    ? investorRiskImpactScores.numberofGeographies.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.numberofGeographies
                    ? investorRiskImpactScores.numberofGeographies.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Impact Profile (IP)</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.impactProfile
                    ? investorRiskImpactScores.impactProfile.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {investorRiskImpactScores &&
                  investorRiskImpactScores.impactProfile
                    ? investorRiskImpactScores.impactProfile.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Impact Score</Typography>
                <Typography variant="h5">
                  {investorRiskImpactScores
                    ? investorRiskImpactScores.ImpactScore
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </DialogContent>
          {/*<DialogActions>
            <Button onClick={handleClose} color="danger" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>*/}
        </Dialog>
      </div>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={content}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Personal info">
              <Typography variant="h4">Name</Typography>
              {UserInfo && UserInfo.first && UserInfo.last
                ? UserInfo.first + '' + UserInfo.last
                : 'N/A'}
              <Divider />
              <Typography variant="h4">Email</Typography>
              {UserInfo && UserInfo.email ? UserInfo.email : 'N/A'}
              <Divider />
              <Typography variant="h4">DOB</Typography>
              {UserInfo && UserInfo.dob ? UserInfo.dob : 'N/A'}
              <Divider />
              <Typography variant="h4">Gender</Typography>
              {UserInfo && UserInfo.gender ? UserInfo.gender : 'N/A'}
              <Divider />
              <Typography variant="h4">City</Typography>
              {UserInfo && UserInfo.city ? UserInfo.city : 'N/A'}
              <Divider />
              <Typography variant="h4">State</Typography>
              {UserInfo && UserInfo.state ? UserInfo.state : 'N/A'}
              <Divider />
              <Typography variant="h4">Country</Typography>
              {UserInfo && UserInfo.country ? UserInfo.country : 'N/A'}
              <Divider />
              <Typography variant="h4">Organization</Typography>
              {UserInfo && UserInfo.organization
                ? UserInfo.organization
                : 'N/A'}
              <Divider />
            </Card>
            <Card title="Description">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  In order to be able to showcase your profile on ImpactCred,
                  please describe yourself. Ex: Organization Name is an
                  Institutional Investor focused on Asia and Africa with a
                  sector on Decent Work and Economic Growth.
                </Typography>
                <Typography variant="h5">
                  {UserInfo && UserInfo.description
                    ? UserInfo.description
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
            <Card title="Define Your Investing Goals">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Looking specifically at the capital you wish to deploy within
                  Kshetra, how would you define your goals for financial
                  returns? We’ll use this information to send you target
                  dealflow opportunities that fit your goals.
                </Typography>
                <Typography variant="h5">
                  RISK TOLERANCE:
                  {investorForm &&
                  investorForm.investmentGoals &&
                  investorForm.investmentGoals.riskTolerance
                    ? investorForm.investmentGoals.riskTolerance
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  LIQUIDITY NEEDS:
                  {investorForm &&
                  investorForm.investmentGoals &&
                  investorForm.investmentGoals.liquidityNeeds
                    ? investorForm.investmentGoals.liquidityNeeds
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  TIME HORIZON:
                  {investorForm &&
                  investorForm.investmentGoals &&
                  investorForm.investmentGoals.timeHorizon
                    ? investorForm.investmentGoals.timeHorizon
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
            <Card title="Additional Information - Impact Portfolio">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Looking specifically at the capital you wish to deploy within
                  Kshetra, how would you define your goals for financial
                  returns? We’ll use this information to send you target
                  dealflow opportunities that fit your goals.
                </Typography>
                <Typography variant="h5">
                  Primary Return Objective:
                  {investorForm &&
                  investorForm.impactPortfolio &&
                  investorForm.impactPortfolio.primaryReturnObjective
                    ? investorForm.impactPortfolio.primaryReturnObjective
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Primary Investment Objective:
                  {investorForm &&
                  investorForm.impactPortfolio &&
                  investorForm.impactPortfolio.primaryInvestmentobjective
                    ? investorForm.impactPortfolio.primaryInvestmentobjective
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Investment Preference:
                  {investorForm &&
                  investorForm.impactPortfolio &&
                  investorForm.impactPortfolio.investmentPreference
                    ? investorForm.impactPortfolio.investmentPreference
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Impact Profile:
                  {investorForm &&
                  investorForm.impactPortfolio &&
                  investorForm.impactPortfolio.impactProfile
                    ? investorForm.impactPortfolio.impactProfile
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
            <Card title="Define Your Investing Goals">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Looking specifically at the capital you wish to deploy within
                  Kshetra, how would you define your goals for impact returns?
                  We’ll use this information to send you target dealflow
                  opportunities that fit your goals.
                </Typography>
                <Typography variant="h5">
                  {investorForm &&
                  investorForm.investmentGoalsSdg &&
                  investorForm.investmentGoalsSdg.sdgPreferences
                    ? investorForm.investmentGoalsSdg.sdgPreferences.toString()
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
            <Card title="Define Your Investing Goals">
              <Box mb={2} mt={3}>
                <Typography variant="h4">Detail of SDG Involvement</Typography>
                <Typography variant="h5">N/A</Typography>
              </Box>
            </Card>
            <Card title="Define Your Geographic Preferences">
              <Box mb={2} mt={3}>
                <Typography variant="h5">
                  {investorForm && investorForm.geoPreferences
                    ? investorForm.geoPreferences.toString()
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
