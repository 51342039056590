import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import iconPie from '../../../assets/images/pie.svg';
import iconDownload from '../../../assets/images/download.svg';
import { Bold, DownloadIcon, FileList } from './Onboarding.styled';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useDispatch, useSelector } from 'react-redux';
import { storage } from '../../../firebase/firebaseConfig';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import HighlightOff from '@material-ui/icons/HighlightOff';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const questionWeight = [
  {
    question:
      'Which of the following represents the range for your annual revenue?',
    Weight: 0.1
  },
  {
    question: 'What is your Gross Margin from revenue?',
    Weight: 0.1
  },
  {
    question: 'What is your Net Margin from revenue?',
    Weight: 0.1
  },
  {
    question:
      'Wat has been your YOY growth of revenue for the last three full years?',
    Weight: 0.1
  },
  {
    question: 'How old is your company?',
    Weight: 0.05
  },
  {
    question:
      'What is your leverage ratio(debt/total assets) based on your current Balance Sheet?',
    Weight: 0.1
  },
  {
    question: 'What is your projected annual debt service coverage ratio?',
    Weight: 0.05
  },
  {
    question: 'Do you have other senior loans?',
    Weight: 0.05
  },
  {
    question:
      'Which range represents the Founding Teams combined year of industry expertise as relevant to this business?',
    Weight: 0.075
  },
  {
    question: 'Do you have a Board of Advisors or Directors?',
    Weight: 0.025
  },
  {
    question:
      'How many accelerators or incubators have you graduated from or are on track to graduate from?',
    Weight: 0.05
  },
  {
    question: 'What is your projected annual debt service coverage ratio?',
    Weight: 0.05
  }
];
const validationSchema = yup.object().shape({
  mainRiskSources: yup.string().required('This field is required'),
  majorChallengesBusinessOutlookDuringOrPostCovid: yup
    .string()
    .required('This field is required'),
  howDoYouBoostCompanyResiliencePostCovid: yup
    .string()
    .required('This field is required'),
  companyWebsite: yup.string().required('This field is required'),
  howManyPayingCustomersDoYouHave: yup
    .string()
    .required('This field is required'),
  howDoyouAcquireCustomers: yup.string().required('This field is required'),
  recurringRevenutPercentage: yup.string().required('This field is required'),
  mainCompetitors: yup.string().required('This field is required'),
  longTermVision: yup.string().required('This field is required'),
  awardsReceived: yup.string().required('This field is required'),
  fraudOrBankruptcy: yup.string().required('This field is required'),
  workingCapital: yup.string().required('This field is required'),
  currentRateOfInterest: yup.string().required('This field is required')
});

const initialValues = {
  mainRiskSources: '',
  majorChallengesBusinessOutlookDuringOrPostCovid: '',
  howDoYouBoostCompanyResiliencePostCovid: '',
  latestMarketProjectsAttachmentLinks: [],
  companyWebsite: '',
  companyPaperworkAttachmentLinks: [],
  howManyPayingCustomersDoYouHave: '',
  howDoyouAcquireCustomers: '',
  recurringRevenutPercentage: '',
  mainCompetitors: '',
  longTermVision: '',
  awardsReceived: '',
  fraudOrBankruptcy: '',
  workingCapital: '',
  currentRateOfInterest: ''
};
const Pillar5 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [latestMarketProject, setlatestMarketProjects] = useState([]);
  const [MarProErr, setMarProErr] = useState('');
  const [IncorPaperwork, setIncorPaperwork] = useState('');
  const [incorporationpaperwork, setincorporationpaperwork] = useState([]);
  const [documentType, setdocumentType] = useState('');
  const [getRiskScores, setRiskScores] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (value.latestMarketProject) {
      setlatestMarketProjects(value.latestMarketProject);
    }
    if (value.incorporationpaperwork) {
      setincorporationpaperwork(value.incorporationpaperwork);
    }
    setRiskScores(RiskScores);
  }, [value, RiskScores]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    validationSchema,
    onSubmit: values => {
      if (latestMarketProject.length == 0) {
        setMarProErr('This field is required!');
        return false;
      }
      if (incorporationpaperwork.length == 0) {
        setIncorPaperwork('This field is required!');
        return false;
      }
      var questionWeightSum = 0;
      var Weight = 0;
      questionWeight.forEach(element => {
        questionWeightSum += element.Weight;
      });
      values.latestMarketProject = latestMarketProject;
      values.incorporationpaperwork = incorporationpaperwork;
      //pillar1Earnings calculation
      //pillar1Earnings calculation
      var revenue =
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.revenue
          .score * 0.1;
      var netMargin =
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.netMargin
          .score * 0.1;
      var yoyGrowth =
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.yoyGrowth
          .score * 0.1;
      var grossMargin =
        getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings.grossMargin
          .score * 0.1;

      //pillar2Team calculation
      var boardOfadvisors =
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team.boardOfadvisors
          .score * 0.025;
      var fundingTeamExperience =
        getRiskScores.enterpriseFinancialRiskScore.pillar2Team
          .fundingTeamExperience.score * 0.075;

      //pillar3Fundamentals calculation
      var dscr =
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.dscr
          .score * 0.05;
      var leverage =
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.leverage
          .score * 0.1;
      var otherSeniorLoans =
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals
          .otherSeniorLoans.score * 0.05;
      var yearSinceIncorporation =
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals
          .yearSinceIncorporation.score * 0.05;

      //pillar4Qualitative calculation
      var acceptanceIntoAccelertor =
        getRiskScores.enterpriseFinancialRiskScore.pillar4Qualitative
          .acceptanceIntoAccelertor.score * 0.05;
      var finalFinRiskScore =
        revenue +
        netMargin +
        yoyGrowth +
        grossMargin +
        boardOfadvisors +
        fundingTeamExperience +
        dscr +
        leverage +
        otherSeniorLoans +
        yearSinceIncorporation +
        acceptanceIntoAccelertor;
      console.log(finalFinRiskScore);
      // var finalFinRiskScore =
      //   parseFloat(
      //     getRiskScores.enterpriseFinancialRiskScore.pillar1Earnings
      //       .finalEarningsScore
      //   ) +
      //   parseFloat(
      //     getRiskScores.enterpriseFinancialRiskScore.pillar2Team.finalTeamScore
      //   ) +
      //   parseFloat(
      //     getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals
      //       .finalFundamentalsScore
      //   ) +
      //   parseFloat(
      //     getRiskScores.enterpriseFinancialRiskScore.pillar4Qualitative
      //       .finalQualitativeScore
      //   );
      // if (
      //   getRiskScores.enterpriseFinancialRiskScore.pillar5MarketRisk
      //     .finalMarketRiskScore
      // ) {
      //   finalFinRiskScore =
      //     finalFinRiskScore +
      //     parseFloat(
      //       getRiskScores.enterpriseFinancialRiskScore.pillar5MarketRisk.finalMarketRiskScore.toFixed(
      //         2
      //       )
      //     );
      // }

      // if (
      //   getRiskScores.enterpriseFinancialRiskScore.pillar6PubliclyAvailableData
      //     .finalPubliclyAvailableDataScore
      // ) {
      //   finalFinRiskScore =
      //     finalFinRiskScore +
      //     parseFloat(
      //       getRiskScores.enterpriseFinancialRiskScore.pillar6PubliclyAvailableData.finalPubliclyAvailableDataScore.toFixed(
      //         2
      //       )
      //     );
      // }
      //finalFinRiskScore = finalFinRiskScore / questionWeightSum;
      getRiskScores.enterpriseFinancialRiskScore.finalFinRiskScore = parseFloat(
        finalFinRiskScore.toFixed(1)
      );
      getRiskScores.enterpriseFinancialRiskScore.adminUserfinalFinRiskScore =
        parseFloat(finalFinRiskScore.toFixed(1));
      console.log(getRiskScores.enterpriseFinancialRiskScore);
      console.log(questionWeightSum, '-=-=-=-=-=---questionWeightSum');
      onSave({
        pillar5MarketRisk: values,
        riskScore: getRiskScores
      });
      onNext();
    }
  });

  const latestMarketProjectA = event => {
    var ref = 'enterprise/latest-market-project';
    onUploadSubmission(event.target.files, 'latestMP', ref);
  };

  const incorporationpaperworkA = event => {
    var ref = 'enterprise/incorporation-paperwork';
    onUploadSubmission(event.target.files, 'inpaper', ref);
  };

  const onUploadSubmission = (files, type, ref) => {
    for (let i = 0; i < files.length; i++) {
      var fileName =
        files[i].name.split('.')[0] +
        '~' +
        Date.now() +
        '.' +
        files[i].name.split('.')[1];
      const uploadTask = storage.ref(`${ref}/${fileName}`).put(files[i]);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref(ref)
            .child(fileName)
            .getDownloadURL()
            .then(url => {
              setdocumentType(type);
              if (type == 'latestMP') {
                var imageArray = {
                  name: fileName,
                  url: url,
                  progress: progress
                };
                console.log(Object.keys(value).length);
                var valueesData = Object.keys(value).length
                  ? value
                  : initialValues;
                setlatestMarketProjects(prevState => [
                  ...prevState,
                  imageArray
                ]);
                valueesData.mainRiskSources = formik.values.mainRiskSources;
                valueesData.majorChallengesBusinessOutlookDuringOrPostCovid =
                  formik.values.majorChallengesBusinessOutlookDuringOrPostCovid;
                valueesData.howDoYouBoostCompanyResiliencePostCovid =
                  formik.values.howDoYouBoostCompanyResiliencePostCovid;
                valueesData.companyWebsite = formik.values.companyWebsite;
                valueesData.howManyPayingCustomersDoYouHave =
                  formik.values.howManyPayingCustomersDoYouHave;
                valueesData.howDoyouAcquireCustomers =
                  formik.values.howDoyouAcquireCustomers;
                valueesData.recurringRevenutPercentage =
                  formik.values.recurringRevenutPercentage;
                valueesData.mainCompetitors = formik.values.mainCompetitors;
                valueesData.longTermVision = formik.values.longTermVision;
                valueesData.awardsReceived = formik.values.awardsReceived;
                valueesData.fraudOrBankruptcy = formik.values.fraudOrBankruptcy;
                valueesData.workingCapital = formik.values.workingCapital;
                valueesData.currentRateOfInterest =
                  formik.values.currentRateOfInterest;
                valueesData.latestMarketProject = [
                  ...latestMarketProject,
                  imageArray
                ];
                onSave({
                  pillar5MarketRisk: valueesData
                });
                setMarProErr('');
              } else if (type == 'inpaper') {
                var imageArray1 = {
                  name: fileName,
                  url: url,
                  progress: progress
                };
                setIncorPaperwork('');
                var valueesData1 = Object.keys(value).length
                  ? value
                  : initialValues;
                setincorporationpaperwork(prevState => [
                  ...prevState,
                  imageArray1
                ]);
                valueesData1.incorporationpaperwork = [
                  ...incorporationpaperwork,
                  imageArray1
                ];
                valueesData1.mainRiskSources = formik.values.mainRiskSources;
                valueesData1.majorChallengesBusinessOutlookDuringOrPostCovid =
                  formik.values.majorChallengesBusinessOutlookDuringOrPostCovid;
                valueesData1.howDoYouBoostCompanyResiliencePostCovid =
                  formik.values.howDoYouBoostCompanyResiliencePostCovid;
                valueesData1.companyWebsite = formik.values.companyWebsite;
                valueesData1.howManyPayingCustomersDoYouHave =
                  formik.values.howManyPayingCustomersDoYouHave;
                valueesData1.howDoyouAcquireCustomers =
                  formik.values.howDoyouAcquireCustomers;
                valueesData1.recurringRevenutPercentage =
                  formik.values.recurringRevenutPercentage;
                valueesData1.mainCompetitors = formik.values.mainCompetitors;
                valueesData1.longTermVision = formik.values.longTermVision;
                valueesData1.awardsReceived = formik.values.awardsReceived;
                valueesData1.fraudOrBankruptcy =
                  formik.values.fraudOrBankruptcy;
                valueesData1.workingCapital = formik.values.workingCapital;
                valueesData1.currentRateOfInterest =
                  formik.values.currentRateOfInterest;
                onSave({
                  pillar5MarketRisk: valueesData1
                });
              }
            });
        }
      );
    }
  };

  const removeDocument = (data, type, refs) => {
    console.log(type);
    var ref = refs + data.name;
    var desertRef = storage.ref(ref);
    // Delete the file
    desertRef
      .delete()
      .then(function () {
        console.log('deleteed');
        if (type == 'CA') {
          var links1 = latestMarketProject.filter(e => e.name != data.name);
          if (links1.length == 0) {
            setMarProErr('This field is required!');
          }
          setlatestMarketProjects(links1);
        }
        if (type == 'YO') {
          var links2 = incorporationpaperwork.filter(e => e.name != data.name);
          if (links2.length == 0) {
            setIncorPaperwork('This field is required!');
          }
          setincorporationpaperwork(links2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Card title="Pillar 5 of 5: Market Risk" icon={iconPie}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4">
            What are the main sources of risk to your business?
          </Typography>
        </Box>
        <Divider />
        <Box mt={2} mb={6}>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="mainRiskSources"
            name="mainRiskSources"
            value={formik.values.mainRiskSources}
            onChange={formik.handleChange}
            error={
              formik.touched.mainRiskSources && formik.errors.mainRiskSources
            }
            helperText={
              formik.touched.mainRiskSources && formik.errors.mainRiskSources
            }
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h4">
            What are your major challenges to business outlook during and post
            COVID? How has COVID affect your revenues?
          </Typography>
        </Box>
        <Divider />
        <Box mt={2} mb={6}>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="majorChallengesBusinessOutlookDuringOrPostCovid"
            name="majorChallengesBusinessOutlookDuringOrPostCovid"
            value={
              formik.values.majorChallengesBusinessOutlookDuringOrPostCovid
            }
            onChange={formik.handleChange}
            error={
              formik.touched.majorChallengesBusinessOutlookDuringOrPostCovid &&
              formik.errors.majorChallengesBusinessOutlookDuringOrPostCovid
            }
            helperText={
              formik.touched.majorChallengesBusinessOutlookDuringOrPostCovid &&
              formik.errors.majorChallengesBusinessOutlookDuringOrPostCovid
            }
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h4">
            How do you boost your company’s resilience post-COVID?
          </Typography>
        </Box>
        <Divider />
        <Box mt={2} mb={6}>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="howDoYouBoostCompanyResiliencePostCovid"
            name="howDoYouBoostCompanyResiliencePostCovid"
            value={formik.values.howDoYouBoostCompanyResiliencePostCovid}
            onChange={formik.handleChange}
            error={
              formik.touched.howDoYouBoostCompanyResiliencePostCovid &&
              formik.errors.howDoYouBoostCompanyResiliencePostCovid
            }
            helperText={
              formik.touched.howDoYouBoostCompanyResiliencePostCovid &&
              formik.errors.howDoYouBoostCompanyResiliencePostCovid
            }
          />
        </Box>
        <Box mb={2} mt={6}>
          <Typography variant="h4">
            Please upload your latest market projections for the next 3-5 years.
          </Typography>
        </Box>
        <Divider />
        {documentType == 'latestMP' && progress != 0 && progress != 100 && (
          <LinearProgress variant="determinate" value={progress} />
        )}
        {latestMarketProject.map((item, index) => (
          <FileList
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={1}
            key={`${index}`}
          >
            <Box display="flex">
              <DownloadIcon src={iconDownload} alt="download" />
              <Typography>
                {item.name.split('~')[0] +
                  '.' +
                  item.name.split('~')[1].split('.')[1]}
              </Typography>
            </Box>
            {/* <Typography>A description of the deal and terms</Typography> */}
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disableElevation
            >
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                id={item.name}
                style={{ color: '#000' }}
              >
                Download
              </a>
            </Button>
            <Box
              display="flex"
              onClick={e =>
                removeDocument(item, 'CA', 'enterprise/latest-market-project/')
              }
            >
              <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
            </Box>
          </FileList>
        ))}
        {MarProErr && <InputFeedback error={MarProErr} />}
        <Box display="flex" alignItems="center" mt={4}>
          <Typography
            variant="h4"
            style={{ cursor: 'pointer' }}
            htmlFor="icon-button-file"
          >
            <input
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={event => {
                latestMarketProjectA(event);
              }}
            />
            <label htmlFor="raised-button-file">+ Add document</label>
          </Typography>
        </Box>
        <Box mt={10} mb={4}>
          <Bold>General Due-Diligence Information</Bold>
        </Box>
        <Box mb={10}>
          <Bold>Company Identification</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">What is your company website?</Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              placeholder="example.com"
              variant="outlined"
              id="companyWebsite"
              name="companyWebsite"
              value={formik.values.companyWebsite}
              onChange={formik.handleChange}
              error={
                formik.touched.companyWebsite && formik.errors.companyWebsite
              }
              helperText={
                formik.touched.companyWebsite && formik.errors.companyWebsite
              }
            />
          </Box>
          <Box mb={2} mt={6}>
            <Typography variant="h4">
              Please attach your company incorporation paperwork.
            </Typography>
          </Box>
          {IncorPaperwork && <InputFeedback error={IncorPaperwork} />}
          <Divider />
          {documentType == 'inpaper' && progress != 0 && progress != 100 && (
            <LinearProgress variant="determinate" value={progress} />
          )}
          {incorporationpaperwork.map((item, index) => (
            <FileList
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems="center"
              px={3}
              py={1}
              key={`${index}`}
            >
              <Box display="flex">
                <DownloadIcon src={iconDownload} alt="download" />
                <Typography>
                  {item.name.split('~')[0] +
                    '.' +
                    item.name.split('~')[1].split('.')[1]}
                </Typography>
              </Box>
              {/* <Typography>A description of the deal and terms</Typography> */}
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disableElevation
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={item.name}
                  style={{ color: '#000' }}
                >
                  Download
                </a>
              </Button>
              <Box
                display="flex"
                onClick={e =>
                  removeDocument(
                    item,
                    'YO',
                    'enterprise/incorporation-paperwork/'
                  )
                }
              >
                <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
              </Box>
            </FileList>
          ))}
          <Box mt={2}>
            <Typography
              variant="h4"
              style={{ cursor: 'pointer' }}
              htmlFor="icon-button"
            >
              <input
                style={{ display: 'none' }}
                id="raised-button"
                multiple
                type="file"
                onChange={event => {
                  incorporationpaperworkA(event);
                }}
              />
              <label htmlFor="raised-button">+ Add document</label>
            </Typography>
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Customers</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              How many paying customers do you have?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              variant="outlined"
              id="howManyPayingCustomersDoYouHave"
              name="howManyPayingCustomersDoYouHave"
              value={formik.values.howManyPayingCustomersDoYouHave}
              onChange={formik.handleChange}
              error={
                formik.touched.howManyPayingCustomersDoYouHave &&
                formik.errors.howManyPayingCustomersDoYouHave
              }
              helperText={
                formik.touched.howManyPayingCustomersDoYouHave &&
                formik.errors.howManyPayingCustomersDoYouHave
              }
            />
          </Box>
          <Box mt={4} mb={2}>
            <Typography variant="h4">How do you acquire customers?</Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="howDoyouAcquireCustomers"
              name="howDoyouAcquireCustomers"
              value={formik.values.howDoyouAcquireCustomers}
              onChange={formik.handleChange}
              error={
                formik.touched.howDoyouAcquireCustomers &&
                formik.errors.howDoyouAcquireCustomers
              }
              helperText={
                formik.touched.howDoyouAcquireCustomers &&
                formik.errors.howDoyouAcquireCustomers
              }
            />
          </Box>
          <Box mt={4} mb={2}>
            <Typography variant="h4">
              What percentage of your revenue is recurring?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              variant="outlined"
              id="recurringRevenutPercentage"
              name="recurringRevenutPercentage"
              value={formik.values.recurringRevenutPercentage}
              onChange={formik.handleChange}
              error={
                formik.touched.recurringRevenutPercentage &&
                formik.errors.recurringRevenutPercentage
              }
              helperText={
                formik.touched.recurringRevenutPercentage &&
                formik.errors.recurringRevenutPercentage
              }
            />
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Competitors</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">Who are your main competitors?</Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="mainCompetitors"
              name="mainCompetitors"
              value={formik.values.mainCompetitors}
              onChange={formik.handleChange}
              error={
                formik.touched.mainCompetitors && formik.errors.mainCompetitors
              }
              helperText={
                formik.touched.mainCompetitors && formik.errors.mainCompetitors
              }
            />
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Long-term Strategy / Vision</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              What is your vision for your company?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="longTermVision"
              name="longTermVision"
              value={formik.values.longTermVision}
              onChange={formik.handleChange}
              error={
                formik.touched.longTermVision && formik.errors.longTermVision
              }
              helperText={
                formik.touched.longTermVision && formik.errors.longTermVision
              }
            />
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Awards and Support</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              Please note any awards you have received.
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="awardsReceived"
              name="awardsReceived"
              value={formik.values.awardsReceived}
              onChange={formik.handleChange}
              error={
                formik.touched.awardsReceived && formik.errors.awardsReceived
              }
              helperText={
                formik.touched.awardsReceived && formik.errors.awardsReceived
              }
            />
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Bankruptcy</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              Has your management team or your company ever been involved in
              bankruptcy?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="fraudOrBankruptcy"
              name="fraudOrBankruptcy"
              value={formik.values.fraudOrBankruptcy}
              onChange={formik.handleChange}
              error={
                formik.touched.fraudOrBankruptcy &&
                formik.errors.fraudOrBankruptcy
              }
              helperText={
                formik.touched.fraudOrBankruptcy &&
                formik.errors.fraudOrBankruptcy
              }
            />
          </Box>
        </Box>
        <Box mb={10}>
          <Bold>Current Loan Details</Bold>
          <Box mt={1} mb={2}>
            <Typography variant="h4">
              What is your current source of working capital, if any?
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              id="workingCapital"
              name="workingCapital"
              value={formik.values.workingCapital}
              onChange={formik.handleChange}
              error={
                formik.touched.workingCapital && formik.errors.workingCapital
              }
              helperText={
                formik.touched.workingCapital && formik.errors.workingCapital
              }
            />
          </Box>
          <Box mt={4} mb={2}>
            <Tooltip
              arrow
              placement="right-start"
              display="flex"
              title="Please enter 0% if you currently do not have any debt."
            >
              <Typography variant="h4">
                What is your current rate of interest?
                <HelpOutlineIcon
                  style={{ fontSize: '20px', margin: '-4px 4px' }}
                />
              </Typography>
            </Tooltip>
          </Box>
          <Divider />
          <Box mt={2}>
            <TextField
              variant="outlined"
              id="currentRateOfInterest"
              name="currentRateOfInterest"
              value={formik.values.currentRateOfInterest}
              onChange={formik.handleChange}
              error={
                formik.touched.currentRateOfInterest &&
                formik.errors.currentRateOfInterest
              }
              helperText={
                formik.touched.currentRateOfInterest &&
                formik.errors.currentRateOfInterest
              }
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onPrev}
          >
            Prev
          </Button>
          <Box mr={3} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onSkip}
        >
          Skip for now
        </Button>
      </Box> */}
    </Card>
  );
};

export default Pillar5;
