import { adminConstants } from '../constants/admin';

const initialState = {
  inviteUser: {}
};

export function adminR(state = initialState, action) {
  switch (action.type) {
    case adminConstants.SEND_INVITE_REQUEST:
      return state;
    case adminConstants.SEND_INVITE_SUCCESS:
      return {
        ...state,
        inviteUser: {
          ...state.data,
          ...action.data
        }
      };
    case adminConstants.SEND_INVITE_FAILURE:
      return state;
    default:
      return state;
  }
}
