import React from 'react';
import styled from 'styled-components';
import Header from '../Header/Header';

import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

import { useDispatch } from 'react-redux';
import { userActions } from '../../../actions/userActions';

const Layout = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('TOKEN'));
    if (token) {
      const { id, exp } = jwt_decode(token);
      if (Date.now() >= exp * 1000) {
        dispatch(userActions.logout());
      } else {
        dispatch(userActions.get(id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
      <Content>{props.children}</Content>
    </>
  );
};

const Content = styled.div`
  background-color: #f2f2f2;
`;

export default Layout;
