import React, { useCallback, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  MenuList,
  Paper,
  Popper,
  Grow,
  ClickAwayListener
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import logo from '../../../assets/images/logo.svg';
import Avatar from './Avatar';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import { history } from '../../../helpers/history';
import { adminRole } from '../../../constants/user';
import { UserStatus } from '../../../constants';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { userRoles } from '../../../constants/user';
const Header = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const user = useSelector(state => state.user);
  const [anchor, setAnchor] = React.useState(false);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const handleMenu = () => {
    setAnchor(!anchor);
    console.log(anchor);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event, path) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (path) {
      history.push(path);
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  const handleLogout = useCallback(() => {
    dispatch(userActions.logout());
    history.push('/');
  }, [dispatch]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const OpenAcceleratorHome = useCallback(() => {
    history.push('/accelerator/home');
  }, []);

  const homePage = () => {
    console.log(user.role);
    if (user.role == userRoles.INVESTOR) {
      history.push('/investor/home');
    }
    if (user.role == userRoles.ACCELERATOR) {
      history.push('/accelerator/home');
    }
    if (user.role == userRoles.ENTERPRISE) {
      history.push('/enterprise/home');
    }
  };
  return (
    <Wrapper display="flex" justifyContent="space-between">
      {isMobile ? (
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mr={1}
            style={{ width: '100%', padding: '0' }}
            onClick={e => homePage()}
          >
            <Logo src={logo} alt="logo" />
            <Box display="inline">
              <Typography variant="subtitle1" display="inline">
                impact
              </Typography>
              <Typography variant="subtitle1" display="inline" color="primary">
                cred
              </Typography>
            </Box>
            {user.email && (
              <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                style={{ float: 'right', position: 'absolute', right: '19px' }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom'
                  }}
                >
                  <Paper>
                    {user.role != adminRole &&
                    user.email &&
                    user.role &&
                    user.isApproved == UserStatus.APPROVED &&
                    location.pathname != '/enterprise/onboarding' &&
                    location.pathname != '/enterprise/form' &&
                    location.pathname != '/investor/form' &&
                    location.pathname != '/accelerator/form' &&
                    location.pathname != '/investor/onboarding' ? (
                      <ClickAwayListener onClickAway={e => handleClose(e, '')}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            onClick={e => handleClose(e, 'search-enterprise')}
                          >
                            Enterprises
                          </MenuItem>
                          <MenuItem
                            onClick={e => handleClose(e, 'search-investor')}
                          >
                            Investors
                          </MenuItem>
                          <MenuItem
                            onClick={e => handleClose(e, 'search-accelerator')}
                          >
                            Accelerators
                          </MenuItem>
                          <MenuItem
                            onClick={e => handleClose(e, 'accelerator/profile')}
                          >
                            Profile
                          </MenuItem>
                          {user.role == userRoles.ACCELERATOR && (
                            <MenuItem onClick={OpenAcceleratorHome}>
                              Enterprise Invite
                            </MenuItem>
                          )}
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    ) : (
                      <ClickAwayListener onClickAway={e => handleClose(e, '')}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    )}
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Grid>
      ) : (
        <>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                mr={5}
                style={{ width: '42%', cursor: 'pointer' }}
                onClick={e => homePage()}
              >
                <Logo src={logo} alt="logo" />
                <Box display="inline">
                  <Typography variant="subtitle1" display="inline">
                    impact
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="inline"
                    color="primary"
                  >
                    cred
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {user.role != adminRole &&
              user.email &&
              user.role &&
              user.isApproved == UserStatus.APPROVED &&
              location.pathname != '/enterprise/onboarding' &&
              location.pathname != '/enterprise/form' &&
              location.pathname != '/investor/form' &&
              location.pathname != '/accelerator/form' &&
              location.pathname != '/investor/onboarding' && (
                <>
                  <Grid item>
                    <NavItem mr={3}>
                      <Typography
                        variant="caption"
                        onClick={() => history.push('/search-enterprise')}
                      >
                        Enterprises
                      </Typography>
                    </NavItem>
                  </Grid>
                  <Grid item>
                    <NavItem mr={3}>
                      <Typography
                        variant="caption"
                        onClick={() => history.push('/search-investor')}
                      >
                        Investors
                      </Typography>
                    </NavItem>
                  </Grid>
                  <Grid item>
                    <NavItem>
                      <Typography
                        variant="caption"
                        onClick={() => history.push('/search-accelerator')}
                      >
                        Accelerators
                      </Typography>
                    </NavItem>
                  </Grid>
                </>
              )}
          </Grid>
          {user.email && (
            <Box
              alignItems="center"
              display="flex"
              style={{ cursor: 'pointer' }}
            >
              <span style={{ fontWeight: 'bold', marginRight: 10 }}>
                {user.email}
              </span>
              <Avatar />
            </Box>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  padding: ${theme.spacing(1, 3, 1, 3)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  background ${theme.palette.common.white};
  z-index: 1;
`;

const Logo = styled.img`
  height: 60,
  margin-right: ${theme.spacing(2)}
`;

const NavItem = styled(Box)`
  cursor: pointer;
`;

export default Header;
