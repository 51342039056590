export const investorConstants = {
  GET_FORM_REQUEST: 'INVESTOR_GET_FORM_REQUEST',
  GET_FORM_SUCCESS: 'INVESTOR_GET_FORM_SUCCESS',
  GET_FORM_FAILURE: 'INVESTOR_GET_FORM_FAILURE',

  CREATE_FORM_REQUEST: 'INVESTOR_CREATE_FORM_REQUEST',
  CREATE_FORM_SUCCESS: 'INVESTOR_CREATE_FORM_SUCCESS',
  CREATE_FORM_FAILURE: 'INVESTOR_CREATE_FORM_FAILURE',

  UPDATE_FORM_REQUEST: 'INVESTOR_UPDATE_FORM_REQUEST',
  UPDATE_FORM_SUCCESS: 'INVESTOR_UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE: 'INVESTOR_UPDATE_FORM_FAILURE',

  CHECK_INVESTOR_FORM_REQUEST: 'CHECK_INVESTOR_FORM_REQUEST',
  CHECK_INVESTOR_FORM_SUCCESS: 'CHECK_INVESTOR_FORM_SUCCESS',
  CHECK_INVESTOR_FORM_FAILURE: 'CHECK_INVESTOR_FORM_FAILURE',

  GET_INVESTOR_FIANCIAL_RISK_SCORE_REQUEST:
    'GET_INVESTOR_FIANCIAL_RISK_SCORE_REQUEST',
  GET_INVESTOR_FIANCIAL_RISK_SCORE_SUCCESS:
    'GET_INVESTOR_FIANCIAL_RISK_SCORE_SUCCESS',
  GET_INVESTOR_FIANCIAL_RISK_SCORE_FAILURE:
    'GET_INVESTOR_FIANCIAL_RISK_SCORE_FAILURE',

  GET_INVESTOR_IMPACT_RISK_SCORE_REQUEST:
    'GET_INVESTOR_IMPACT_RISK_SCORE_REQUEST',
  GET_INVESTOR_IMPACT_RISK_SCORE_SUCCESS:
    'GET_INVESTOR_IMPACT_RISK_SCORE_SUCCESS',
  GET_INVESTOR_IMPACT_RISK_SCORE_FAILURE:
    'GET_INVESTOR_IMPACT_RISK_SCORE_FAILURE',
  RESET_STATE: 'RESET_STATE'
};
