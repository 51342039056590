import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconNext from '../../../assets/images/next.svg';
import iconPie from '../../../assets/images/pie.svg';
import iconDownload from '../../../assets/images/download.svg';
import { storage } from '../../../firebase/firebaseConfig';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import HighlightOff from '@material-ui/icons/HighlightOff';
import {
  DownloadIcon,
  FileList,
  StyledDivider,
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from './Onboarding.styled';
const InputFeedback = ({ error }) =>
  error ? (
    <div style={{ color: '#ff0000' }} className={classNames('input-feedback')}>
      {error}
    </div>
  ) : null;
const validationSchema = yup.object().shape({
  howOldIsYourCompany: yup.string().required('This field is required'),
  annualDebtServiceCoverageRation: yup
    .string()
    .required('This field is required'),
  doYouHaveOtherSeniorLoans: yup.string().required('This field is required'),
  howManySeniorLoansDoYouCurrentlyHaveOnTheBooks: yup
    .string()
    .required('This field is required'),
  //seniorLoanAgreementAttachmentLinks: yup.array().of(yup.string()),
  leverageratio: yup.string().required('This field is required')
});

const initialValues = {
  howOldIsYourCompany: '',
  annualDebtServiceCoverageRation: '',
  doYouHaveOtherSeniorLoans: '',
  howMuchTotalDebtLeverageDoYouAlreadyHave: '',
  howManySeniorLoansDoYouCurrentlyHaveOnTheBooks: '',
  seniorLoanAgreementAttachmentLinks: [],
  leverageratio: ''
};

const projectedannual = [
  {
    id: '0',
    label: '4+x',
    score: '2',
    Weight: '0.05'
  },
  {
    id: '1',
    label: '3+x',
    score: '4',
    Weight: '0.05'
  },
  {
    id: '2',
    label: '2.5+x',
    score: '6',
    Weight: '0.05'
  },
  {
    id: '3',
    label: '2.5x',
    score: '8',
    Weight: '0.05'
  },
  {
    id: '4',
    label: 'Less than 2.5x',
    score: '10',
    Weight: '0.05'
  }
];

const howOldCompany = [
  {
    id: '0',
    label: 'More than 6',
    score: '2',
    Weight: '0.05'
  },
  {
    id: '1',
    label: '4-6',
    score: '4',
    Weight: '0.05'
  },
  {
    id: '2',
    label: '2-4',
    score: '6',
    Weight: '0.05'
  },
  {
    id: '3',
    label: '1-2',
    score: '8',
    Weight: '0.05'
  },
  {
    id: '4',
    label: 'Less than 1',
    score: '10',
    Weight: '0.05'
  }
];

const leverageratio = [
  {
    id: '0',
    label: 'Less than or equal to  10%',
    score: '2',
    Weight: '0.1'
  },
  {
    id: '1',
    label: '10.01 - 20%',
    score: '4',
    Weight: '0.1'
  },
  {
    id: '2',
    label: '20.01 - 30%',
    score: '6',
    Weight: '0.1'
  },
  {
    id: '3',
    label: '30.01 - 40%',
    score: '8',
    Weight: '0.1'
  },
  {
    id: '4',
    label: 'Greater than 40%',
    score: '10',
    Weight: '0.1'
  }
];

const Pillar3 = ({ onSave, value, RiskScores, onNext, onPrev, onSkip }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [seniorLoanAgreement, setseniorLoanAgreement] = useState([]);
  const [getRiskScores, setRiskScores] = useState([]);
  const [SLV, setSLV] = useState('');
  useEffect(() => {
    if (value.seniorLoanAgreementAttachmentLinks) {
      setseniorLoanAgreement(value.seniorLoanAgreementAttachmentLinks);
    }
    setRiskScores(RiskScores);
  }, [value, RiskScores]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: Object.keys(value).length ? value : initialValues,
    validationSchema,
    onSubmit: values => {
      var finalFundamentalsScoreAD = '';
      var finalFundamentalsScoreLV = '';
      var OtherSeniorLoansSScore = '';
      var finalFundamentalsScoreHOC = '';
      if (seniorLoanAgreement.length == 0) {
        //setSLV('Please provide relevant agreements.');
        //return false;
      } else {
        //setSLV('');
      }
      var Weight = 0;
      values.seniorLoanAgreementAttachmentLinks = seniorLoanAgreement;
      var howOldCompanyScore = howOldCompany.filter(
        e => e.label == values.howOldIsYourCompany
      );
      console.log(howOldCompanyScore);
      if (howOldCompanyScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.yearSinceIncorporation.score =
          howOldCompanyScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.yearSinceIncorporation.answer =
          howOldCompanyScore[0].label;
        finalFundamentalsScoreHOC =
          howOldCompanyScore[0].Weight * howOldCompanyScore[0].score;
        Weight += parseFloat(howOldCompanyScore[0].Weight);
      }

      var projectedannualScore = projectedannual.filter(
        e => e.label == values.annualDebtServiceCoverageRation
      );
      if (projectedannualScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.dscr.score =
          projectedannualScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.dscr.answer =
          projectedannualScore[0].label;
        finalFundamentalsScoreAD =
          projectedannualScore[0].Weight * projectedannualScore[0].score;
        Weight += parseFloat(projectedannualScore[0].Weight);
      }

      var leverageratioScore = leverageratio.filter(
        e => e.label == values.leverageratio
      );
      if (leverageratioScore.length != 0) {
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.leverage.score =
          leverageratioScore[0].score;
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.leverage.answer =
          leverageratioScore[0].label;
        finalFundamentalsScoreLV =
          leverageratioScore[0].Weight * leverageratioScore[0].score;
        Weight += parseFloat(leverageratioScore[0].Weight);
      }

      if (values.doYouHaveOtherSeniorLoans == 'true') {
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.otherSeniorLoans.score =
          '6';
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.otherSeniorLoans.answer =
          'Yes';
        OtherSeniorLoansSScore = 0.05 * 6;
        Weight += 0.05;
      } else {
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.otherSeniorLoans.score =
          '2';
        getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.otherSeniorLoans.answer =
          'No';
        OtherSeniorLoansSScore = 0.05 * 2;
        Weight += 0.05;
      }
      var finalFundamentalsScore =
        finalFundamentalsScoreAD +
        finalFundamentalsScoreLV +
        OtherSeniorLoansSScore +
        finalFundamentalsScoreHOC;
      finalFundamentalsScore = finalFundamentalsScore / Weight;
      getRiskScores.enterpriseFinancialRiskScore.pillar3Fundamentals.finalFundamentalsScore =
        parseFloat(finalFundamentalsScore).toFixed(1);
      console.log(getRiskScores);
      onSave({
        pillar3Fundamentals: values,
        riskScore: getRiskScores
      });
      onNext();
    }
  });

  const seniorLoanAgreementA = event => {
    var ref = 'enterprise/senior-loan-agreement';
    onUploadSubmission(event.target.files, 'financials', ref);
  };

  const onUploadSubmission = (files, type, ref) => {
    for (let i = 0; i < files.length; i++) {
      var fileName =
        files[i].name.split('.')[0] +
        '~' +
        Date.now() +
        '.' +
        files[i].name.split('.')[1];
      const uploadTask = storage.ref(`${ref}/${fileName}`).put(files[i]);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref(ref)
            .child(fileName)
            .getDownloadURL()
            .then(url => {
              var imageArray = {
                name: fileName,
                url: url,
                progress: progress
              };
              var valueesData = Object.keys(value).length
                ? value
                : initialValues;
              setseniorLoanAgreement(prevState => [...prevState, imageArray]);
              valueesData.seniorLoanAgreementAttachmentLinks = [
                ...seniorLoanAgreement,
                imageArray
              ];
              valueesData.annualDebtServiceCoverageRation =
                formik.values.annualDebtServiceCoverageRation;
              valueesData.doYouHaveOtherSeniorLoans =
                formik.values.doYouHaveOtherSeniorLoans;
              valueesData.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks =
                formik.values.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks;
              valueesData.howOldIsYourCompany =
                formik.values.howOldIsYourCompany;
              valueesData.leverageratio = formik.values.leverageratio;
              setSLV('');
              onSave({
                pillar3Fundamentals: valueesData
              });
            });
        }
      );
    }
  };
  const removeDocument = data => {
    var ref = 'enterprise/senior-loan-agreement/' + data.name;
    var desertRef = storage.ref(ref);
    // Delete the file
    desertRef
      .delete()
      .then(function () {
        console.log('deleteed');
        var links1 = seniorLoanAgreement.filter(e => e.name != data.name);
        if (links1 == 0) {
          setSLV('Please provide relevant agreements.');
        }
        setseniorLoanAgreement(links1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Card title="Pillar 2 of 5: Fundamentals" icon={iconPie}>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Typography variant="h4">
            How old is your company (in years)?
          </Typography>
        </Box>
        <Divider />
        {/* <Box mt={2}>
          <TextField
            variant="outlined"
            id="howOldIsYourCompany"
            name="howOldIsYourCompany"
            placeholder="In year"
            value={formik.values.howOldIsYourCompany}
            onChange={formik.handleChange}
            error={
              formik.touched.howOldIsYourCompany &&
              formik.errors.howOldIsYourCompany
            }
            helperText={
              formik.touched.howOldIsYourCompany &&
              formik.errors.howOldIsYourCompany
            }
          />
        </Box> */}
        <Box mt={3} mb={3}>
          {formik.errors.howOldIsYourCompany &&
          formik.errors.howOldIsYourCompany ? (
            <InputFeedback error={formik.errors.howOldIsYourCompany} />
          ) : null}
          <RadioGroup
            id="howOldIsYourCompany"
            name="howOldIsYourCompany"
            value={formik.values.howOldIsYourCompany}
            onChange={formik.handleChange}
            error={
              formik.touched.howOldIsYourCompany &&
              formik.errors.howOldIsYourCompany
            }
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              {howOldCompany.map(item => (
                <Box mr={1} key={`investment-goals_1-${item.id}`}>
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label={item.label}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
        </Box>
        <Box mb={1.5} mt={6}>
          <Tooltip
            arrow
            placement="right-start"
            display="flex"
            title="Assume that you are approved for the loan amount that you are seeking at 12% annual interest."
          >
            <Typography variant="h4">
              What is your projected annual debt service coverage ratio? (net
              income/loan interest)
              <HelpOutlineIcon
                style={{ fontSize: '15px', margin: '-4px 4px' }}
              />
            </Typography>
          </Tooltip>
        </Box>
        <Divider />
        <Box mt={3} mb={3}>
          {formik.errors.annualDebtServiceCoverageRation &&
          formik.errors.annualDebtServiceCoverageRation ? (
            <InputFeedback
              error={formik.errors.annualDebtServiceCoverageRation}
            />
          ) : null}
          <RadioGroup
            id="annualDebtServiceCoverageRation"
            name="annualDebtServiceCoverageRation"
            value={formik.values.annualDebtServiceCoverageRation}
            onChange={formik.handleChange}
            error={
              formik.touched.annualDebtServiceCoverageRation &&
              formik.errors.annualDebtServiceCoverageRation
            }
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              {projectedannual.map(item => (
                <Box mr={1} key={`investment-goals_1-${item.id}`}>
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label={item.label}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
        </Box>
        <Box mb={1.5}>
          <Typography variant="h4">Do you have other senior loans?</Typography>
        </Box>
        <Divider />
        <Box mt={3} mb={3}>
          {formik.errors.doYouHaveOtherSeniorLoans &&
          formik.errors.doYouHaveOtherSeniorLoans ? (
            <InputFeedback error={formik.errors.doYouHaveOtherSeniorLoans} />
          ) : null}
          <RadioGroup
            id="doYouHaveOtherSeniorLoans"
            name="doYouHaveOtherSeniorLoans"
            value={formik.values.doYouHaveOtherSeniorLoans}
            onChange={formik.handleChange}
            error={
              formik.touched.doYouHaveOtherSeniorLoans &&
              formik.errors.doYouHaveOtherSeniorLoans
            }
            style={{ padding: '10px' }}
          >
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
              <Box mr={3}>
                <FormControlLabel
                  value="true"
                  control={
                    <StyledRadio
                      disableRipple
                      icon={<StyledCheckedIconTick />}
                      checkedIcon={<StyledCheckedTickIcon />}
                    />
                  }
                  label="Yes"
                />
              </Box>
              <FormControlLabel
                value="false"
                control={
                  <StyledRadio
                    disableRipple
                    icon={<StyledCheckedIconTick />}
                    checkedIcon={<StyledCheckedTickIcon />}
                  />
                }
                label="No"
              />
            </Box>
          </RadioGroup>
        </Box>
        <Box mb={1.5} mt={6}>
          <Typography variant="h4">
            What is your leverage ratio (total debt/ net worth) based on your
            current Balance Sheet?
          </Typography>
        </Box>
        <Divider />
        <Box mt={3} mb={3}>
          {formik.errors.leverageratio && formik.errors.leverageratio ? (
            <InputFeedback error={formik.errors.leverageratio} />
          ) : null}
          <RadioGroup
            id="leverageratio"
            name="leverageratio"
            value={formik.values.leverageratio}
            onChange={formik.handleChange}
            style={{ padding: '10px' }}
          >
            <Box flexDirection={isMobile ? 'column' : 'row'}>
              {leverageratio.map(item => (
                <Box mr={1} key={`leverageratio-${item.id}`}>
                  <FormControlLabel
                    value={item.label}
                    control={
                      <StyledRadio
                        disableRipple
                        icon={<StyledCheckedIconTick />}
                        checkedIcon={<StyledCheckedTickIcon />}
                      />
                    }
                    label={item.label}
                    style={{ marginBottom: '15px' }}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
        </Box>
        <Box mb={2}>
          {/*SLV && SLV ? <InputFeedback error={SLV} /> : null*/}
          <Typography variant="h4">
            How many senior loans do you currently have on the books? Please
            provide relevant agreements.
          </Typography>
        </Box>
        <Divider />
        <Box mt={2}>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            id="howManySeniorLoansDoYouCurrentlyHaveOnTheBooks"
            name="howManySeniorLoansDoYouCurrentlyHaveOnTheBooks"
            value={formik.values.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks}
            onChange={formik.handleChange}
            error={
              formik.touched.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks &&
              formik.errors.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks
            }
            helperText={
              formik.touched.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks &&
              formik.errors.howManySeniorLoansDoYouCurrentlyHaveOnTheBooks
            }
          />
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Typography
            variant="h4"
            style={{ cursor: 'pointer' }}
            htmlFor="icon-button-file"
          >
            <input
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={event => {
                seniorLoanAgreementA(event);
              }}
            />
            <label htmlFor="raised-button-file">+ Add document</label>
          </Typography>
          <StyledDivider />
        </Box>
        {progress != 0 && progress != 100 && (
          <LinearProgress variant="determinate" value={progress} />
        )}
        {seniorLoanAgreement.map((item, index) => (
          <FileList
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            px={3}
            py={1}
            key={`${index}`}
          >
            <Box display="flex">
              <DownloadIcon src={iconDownload} alt="download" />
              <Typography>
                {item.name.split('~')[0] +
                  '.' +
                  item.name.split('~')[1].split('.')[1]}
              </Typography>
            </Box>
            {/* <Typography>A description of the deal and terms</Typography> */}
            <Button
              color="secondary"
              variant="contained"
              size="small"
              disableElevation
            >
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                id={item.name}
                style={{ color: '#000' }}
              >
                Download
              </a>
            </Button>
            <Box display="flex" onClick={e => removeDocument(item)}>
              <HighlightOff style={{ color: 'red', cursor: 'pointer' }} />
            </Box>
          </FileList>
        ))}
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onPrev}
          >
            Prev
          </Button>
          <Box mr={3} />
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
          >
            Next
          </Button>
        </Box>
      </form>
      {/* <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onSkip}
        >
          Skip for now
        </Button>
      </Box> */}
    </Card>
  );
};

export default Pillar3;
