import { Box, Button } from '@material-ui/core';
import Pillar0 from './Pillar0';
import Pillar1 from './Pillar1';
import Pillar2 from './Pillar2';
import Pillar3 from './Pillar3';
import Pillar4 from './Pillar4';
import { Bold, Wrapper } from './Onboarding.styled';
import Pillar5 from './Pillar5';
import EnterpriseEcosystem from './EnterpriseEcosystem';
import { useCallback, useEffect, useState } from 'react';
import { history } from '../../../helpers/history';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../actions/userActions';
const MAX_STEP = 7;

const Content = props => {
  const { onNext, onPrev } = props;
  return (
    <>
      {props.children}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onPrev}
        >
          Prev
        </Button>
        <Box mr={3} />
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onNext}
        >
          Next
        </Button>
      </Box>
    </>
  );
};
const Onboarding = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const form = useSelector(state => state.enterprise.form);
  const enterpriseRiskScores = useSelector(
    state => state.enterprise.RiskScores
  );
  const enterpriseImpactScores = useSelector(
    state => state.enterprise.impactRisk
  );
  const [step, setStep] = useState(0);
  const [isSkip, setisSkip] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    user && user.id && dispatch(enterpriseActions.getForm(user.id));
    user &&
      user.id &&
      dispatch(enterpriseActions.enterpriseFiancialRiskScores(user.id));
    user &&
      user.id &&
      dispatch(enterpriseActions.enterpriseImpactRiskScores(user.id));
    if (user.onboardingLeaveStep >= 0 && user.onboardingLeaveStep) {
      setStep(user.onboardingLeaveStep);
    }
  }, [dispatch, user, isSkip]);

  const onSave = useCallback(
    data => {
      if (form.id) {
        dispatch(
          enterpriseActions.updateForm(form.id, {
            ...form.formAnswers,
            ...data
          })
        );
      } else {
        dispatch(enterpriseActions.createForm(data));
      }
    },
    [dispatch, form.formAnswers, form.id]
  );

  const onPrev = useCallback(() => {
    if (step !== 0) {
      setStep(step - 1);
    }
  }, [step]);

  const onNext = useCallback(async () => {
    if (step === MAX_STEP - 1) {
      var onboardingLeaveStep = 'complete';
      delete user['users'];
      delete user['type'];
      await dispatch(
        userActions.update(user.id, { ...user, onboardingLeaveStep })
      );
      history.push('/enterprise/pending-approval');
      return;
    } else {
      setStep(step + 1);
      setisSkip(true);
      onboardingLeaveStep = step + 1;
      delete user['users'];
      delete user['type'];
      dispatch(userActions.update(user.id, { ...user, onboardingLeaveStep }));
      user &&
        user.id &&
        dispatch(enterpriseActions.enterpriseFiancialRiskScores(user.id));
    }
  }, [step, setisSkip, user, dispatch]);

  const onSkip = useCallback(() => {
    dispatch(userActions.update(user.id, { onboardingLeaveStep: step }));
    history.push('/enterprise/pending-approval');
    return;
  }, [dispatch, step, user.id]);
  console.log(enterpriseRiskScores);
  return (
    <Wrapper>
      {step != 6 && (
        <Box mb={7}>
          <Bold variant="h1">Your Business Profile</Bold>
        </Box>
      )}
      {step > 0 && step < 6 && (
        <Box mt={7} mb={6}>
          <Bold variant="h4">
            Thanks for taking time to tell us more about how you are changing
            the world! For us to evaluate your readiness for investment, please
            fill out the following details. To learn more about our due
            diligence process, please refer to www.impactcred.com. Completing a
            profile on ImpactCred could take up to 60 minutes. Your responses
            will be saved as you go along and you can come back and complete
            your application at any time.
          </Bold>
        </Box>
      )}

      {step === 0 && (
        <Pillar0
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.financingNeeds) || {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}

      {step === 1 && (
        <Pillar1
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.pillar1Earnings) || {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}

      <Box mb={4} />

      {step === 3 && (
        <Pillar2
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.pillar2Team) || {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}

      <Box mb={4} />

      {step === 2 && (
        <Pillar3
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form &&
              form.formAnswers &&
              form.formAnswers.pillar3Fundamentals) ||
            {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}

      <Box mb={4} />

      {step === 4 && (
        <Pillar4
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.pillar4Qualitative) ||
            {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}

      <Box mb={4} />

      {step === 5 && (
        <Pillar5
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.pillar5MarketRisk) ||
            {}
          }
          RiskScores={enterpriseRiskScores}
        />
      )}
      {step === 6 && (
        <EnterpriseEcosystem
          onSave={onSave}
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          value={
            (form && form.formAnswers && form.formAnswers.pillar5MarketRisk) ||
            {}
          }
          RiskScores={enterpriseRiskScores}
          ImpactRiskScores={enterpriseImpactScores}
        />
      )}
    </Wrapper>
  );
};

export default Onboarding;
