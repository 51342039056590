import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { UserStatus } from '../../../constants';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Card from '../../common/Card/Card';
import iconDownload from '../../../assets/images/download.svg';
import { enterpriseActions } from '../../../actions/enterpriseActions';
import { acceleratorActions } from '../../../actions/acceleratorAction';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { userActions } from '../../../actions/userActions';
import { toastr } from 'react-redux-toastr';

import {
  DownloadIcon,
  FileList,
  StyledDivider,
  Bold
} from '../../Enterprise/Onboarding/Onboarding.styled';
import theme from '../../../styles/theme';

import {
  EnhancedTableHead,
  stableSort,
  getComparator
} from '../../common/Table/Table';
import Chip from '@material-ui/core/Chip';
import { Grid } from '@material-ui/core';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem
} from '@material-ui/core';
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'organization',
    numeric: false,
    disablePadding: false,
    label: 'Organization'
  },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
  { id: '', numeric: false, disablePadding: false, label: 'Impact Score' },
  { id: '', numeric: false, disablePadding: false, label: 'Financial Score' },
  // { id: 'dob', numeric: false, disablePadding: false, label: 'Date of Birth' },
  // { id: 'gender', numeric: false, disablePadding: false, label: 'Gender' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'refer', numeric: false, disablePadding: false, label: 'Referred by' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
  { id: '', numeric: false, disablePadding: false, label: '' },
  { id: '', numeric: false, disablePadding: false, label: '' },
  { id: '', numeric: false, disablePadding: false, label: '' },
  { id: '', numeric: false, disablePadding: false, label: '' }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  denyButton: {
    backgroundColor: theme.palette.common.red,
    color: theme.palette.common.gray
  }
}));

export default function Enterprises({ rows, onAction }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ViewScore, setViewScore] = React.useState(false);
  const [content, setcontent] = React.useState(false);
  const [RefInvite, setRefInvite] = React.useState(false);
  const [marketRisk, setMarketRisk] = React.useState('');
  const [pubAd, setPubAd] = React.useState('');
  const dispatch = useDispatch();
  const [UserInfo, setUserInfo] = React.useState([]);
  const enterpriseForm = useSelector(state => state.enterprise.form);
  const enterpriseInviteQue = useSelector(
    state => state.accelerator.enterpriseInviteQuestAns_
  );
  const enterpriseRiskScores = useSelector(
    state => state.enterprise.RiskScores
  );
  const enterpriseImpactScores = useSelector(
    state => state.enterprise.impactRisk
  );
  useEffect(() => {
    console.log(enterpriseRiskScores);
    if (enterpriseRiskScores.enterpriseFinancialRiskScore) {
      setMarketRisk(
        enterpriseRiskScores.enterpriseFinancialRiskScore.pillar5MarketRisk
          .finalMarketRiskScore
      );
      setPubAd(
        enterpriseRiskScores.enterpriseFinancialRiskScore
          .pillar6PubliclyAvailableData.finalPubliclyAvailableDataScore
      );
    }
  }, [enterpriseRiskScores]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleContent = row => {
    setUserInfo(row);
    dispatch(enterpriseActions.getForm(row.id));
    setcontent(true);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClickOpen = row => {
    setViewScore(true);
    dispatch(enterpriseActions.enterpriseFiancialRiskScores(row.id));
    dispatch(enterpriseActions.enterpriseImpactRiskScores(row.id));
  };

  const handleClose = () => {
    setViewScore(false);
    setcontent(false);
    setRefInvite(false);
  };

  const DeleteUser = id => {
    console.log(id);
    if (confirm('Are you sure you want to delete this user')) {
      dispatch(userActions.deleteUser(id, 'Enterprises')).then(data => {
        toastr.success('Success', 'User deleted successful');
        dispatch(userActions.getAllEnterprisesWithScore());
      });
    }
  };

  const handleViewRef = item => {
    setRefInvite(true);
    dispatch(
      acceleratorActions.getEnterpriseInviteQueAns(item.email, null, 'admin')
    );
  };
  const updateData = data => {
    data.enterpriseFinancialRiskScore.pillar5MarketRisk.finalMarketRiskScore =
      marketRisk;
    data.enterpriseFinancialRiskScore.pillar6PubliclyAvailableData.finalPubliclyAvailableDataScore =
      pubAd;
    var adminRiskS =
      data.enterpriseFinancialRiskScore.pillar5MarketRisk.finalMarketRiskScore *
        0.1 +
      data.enterpriseFinancialRiskScore.pillar6PubliclyAvailableData
        .finalPubliclyAvailableDataScore *
        0.1;
    var finalRS =
      data.enterpriseFinancialRiskScore.finalFinRiskScore + adminRiskS;
    data.enterpriseFinancialRiskScore.adminUserfinalFinRiskScore = parseFloat(
      finalRS.toFixed(1)
    );

    dispatch(enterpriseActions.enterpriseupdateMarketRiskPAD(data)).then(
      data => {
        dispatch(userActions.getAllEnterprisesWithScore());
      }
    );
    setViewScore(false);
  };
  const emptyRows =
    (rows &&
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)) ||
    0;
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {rows &&
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={'tbl_' + row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.first} {row.last}
                      </TableCell>
                      <TableCell>{row.organization}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>{row.country}</TableCell>
                      <TableCell>{row.adminUserImpactScore}</TableCell>
                      <TableCell>{row.adminUserfinalFinRiskScore}</TableCell>
                      <TableCell component="th" scope="row">
                        <Chip
                          color={row.isApproved === 1 ? 'primary' : 'secondary'}
                          label={
                            row.isApproved === UserStatus.APPROVED
                              ? 'Approved'
                              : row.isApproved === UserStatus.PENDING
                              ? 'Pending'
                              : 'Denied'
                          }
                        />
                      </TableCell>
                      <TableCell>{row.referredBy}</TableCell>
                      <TableCell>
                        <Grid item width="200">
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '2px',
                              backgroundColor: '#4791db',
                              width: '150px'
                            }}
                            onClick={e => handleViewRef(row)}
                          >
                            View Referral Info
                          </Button>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid item>
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '2px',
                              backgroundColor: '#4791db'
                            }}
                            onClick={e => handleContent(row)}
                          >
                            View
                          </Button>
                        </Grid>
                      </TableCell>
                      <TableCell width="100">
                        <Grid item>
                          <Button
                            size="sm"
                            variant="contained"
                            color="primary"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '5px',
                              backgroundColor: '#4791db',
                              width: '90px'
                            }}
                            onClick={e => handleClickOpen(row)}
                          >
                            View Score
                          </Button>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid item>
                          {row.isApproved !== 1 && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="sm"
                              style={{
                                fontSize: '13px',
                                height: '25px',
                                padding: '2px',
                                backgroundColor: '#4791db'
                              }}
                              onClick={onAction(row.id, UserStatus.APPROVED)}
                            >
                              Approve
                            </Button>
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid item>
                          {row.isApproved !== 0 && (
                            <Button
                              variant="contained"
                              style={{
                                fontSize: '13px',
                                height: '25px',
                                padding: '2px'
                              }}
                              className={classes.denyButton}
                              onClick={onAction(row.id, UserStatus.DENIED)}
                            >
                              Deny
                            </Button>
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid item>
                          <Button
                            variant="contained"
                            style={{
                              fontSize: '13px',
                              height: '25px',
                              padding: '2px'
                            }}
                            className={classes.denyButton}
                            onClick={e => DeleteUser(row.id)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
            {rows == 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={10}>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    Loading Records…
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows && rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={RefInvite}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View Referral Info
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Referral Info">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Why did you refer this company? What stands out about them?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue && enterpriseInviteQue.referthiscompany
                    ? enterpriseInviteQue.referthiscompany
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Please comment on the management team and how they have
                  responded to your coaching during the program?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue &&
                  enterpriseInviteQue.commentonthemanagement
                    ? enterpriseInviteQue.commentonthemanagement
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What management competencies have your observed and validated?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue &&
                  enterpriseInviteQue.managementcompetencies
                    ? enterpriseInviteQue.managementcompetencies
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  For your selection process, how did you evaluate the market
                  potential of this company?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue && enterpriseInviteQue.selectionprocess
                    ? enterpriseInviteQue.selectionprocess
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Why do you believe this management team will be able to
                  execute on their vision?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue &&
                  enterpriseInviteQue.believethismanagementteam
                    ? enterpriseInviteQue.believethismanagementteam
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What networks and industry contacts do you believe this
                  company possesses which are crucial to the sector and market
                  they are operating in?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue &&
                  enterpriseInviteQue.networksandindustry
                    ? enterpriseInviteQue.networksandindustry
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Anything else you would like to say about this company or
                  their execution capability?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue && enterpriseInviteQue.Anythingelse
                    ? enterpriseInviteQue.Anythingelse
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Does someone of your team have a strong personal relationship
                  with this management team?
                </Typography>
                <Typography variant="h5">
                  {enterpriseInviteQue &&
                  enterpriseInviteQue.Doessomeoneofyourteam
                    ? enterpriseInviteQue.Doessomeoneofyourteam
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
            </Card>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={ViewScore}
          maxWidth="sm"
          fullWidth="sm"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View Score
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Financial Risk Recommendation">
              <Box mb={2} mt={3}>
                <Typography variant="h4">Pillar 1: Earnings</Typography>
                <Divider />
                <Typography variant="h4">
                  Which of the following represents the range for your annual
                  revenue?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.revenue.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.revenue.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your Gross Margin from revenue?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.grossMargin.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.grossMargin.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your Net Margin from revenue?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.netMargin.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.netMargin.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What has been your YOY growth of revenue for the last three
                  full years?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.yoyGrowth.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.yoyGrowth.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Pillar Score</Typography>
                <Typography variant="h5">
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar1Earnings.finalEarningsScore
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Pillar 2: Team</Typography>
                <Divider />
                <Typography variant="h4">
                  Which range represents the Founding Team’s combined years of
                  industry expertise as relevant to this business?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore.pillar2Team
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar2Team.fundingTeamExperience.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar2Team.fundingTeamExperience.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Divider />
                <Typography variant="h4">
                  Do you have an established Board of Advisors or Directors?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar2Team.boardOfadvisors.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar2Team.boardOfadvisors.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Pillar Score</Typography>
                <Typography variant="h5">
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar2Team.finalTeamScore
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Pillar 3: Fundamentals</Typography>
                <Divider />
                <Typography variant="h4">How old is your company?</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.yearSinceIncorporation.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.yearSinceIncorporation.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Divider />
                <Typography variant="h4">
                  What is your leverage ratio (debt/ net worth) based on your
                  current Balance Sheet?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.leverage.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.leverage.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Divider />
                <Typography variant="h4">
                  What is your projected annual debt service coverage ratio
                  (DSCR)?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.dscr.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.dscr.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Divider />
                <Typography variant="h4">
                  Do you have other senior loans?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.otherSeniorLoans.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.otherSeniorLoans.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Pillar Score</Typography>
                <Typography variant="h5">
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar3Fundamentals.finalFundamentalsScore
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Pillar 4: Qualitative</Typography>
                <Divider />
                <Typography variant="h4">
                  How many accelerators or incubators have you graduated from or
                  are on track to graduate from?
                </Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar4Qualitative.acceptanceIntoAccelertor.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar4Qualitative.acceptanceIntoAccelertor.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Pillar Score</Typography>
                <Typography variant="h5">
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .pillar1Earnings
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .pillar4Qualitative.finalQualitativeScore
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />

              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Financial Risk Score</Typography>
                <Typography variant="h5">
                  {enterpriseRiskScores &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore &&
                  enterpriseRiskScores.enterpriseFinancialRiskScore
                    .adminUserfinalFinRiskScore
                    ? enterpriseRiskScores.enterpriseFinancialRiskScore
                        .adminUserfinalFinRiskScore
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3} style={{ marginBottom: '40px' }}>
                <TextField
                  id="name"
                  label="Market Risk"
                  type="text"
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: '20px' }}
                  onChange={e => setMarketRisk(e.target.value)}
                  value={marketRisk}
                />
                <TextField
                  id="name"
                  label="Publicly Available Data"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={e => setPubAd(e.target.value)}
                  value={pubAd}
                />
                <Button
                  onClick={e => updateData(enterpriseRiskScores)}
                  color="primary"
                  variant="contained"
                  style={{ float: 'right', marginTop: '20px' }}
                >
                  Update
                </Button>
              </Box>
            </Card>
            <Card title="Impact Recommendation">
              <Box mb={2} mt={3}>
                <Typography variant="h4">SDG Breakdown</Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">No Poverty</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.NoPoverty
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.NoPoverty + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Gender Equality</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.GenderEquality
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.GenderEquality + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Industry Innovation & Infrastructure
                        </TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.IndustryInnovationInfrastructure
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.IndustryInnovationInfrastructure +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Climate Action</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.climateAction
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.climateAction + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Zero Hunger</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.ZeroHunger
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.ZeroHunger + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Clean Water & Sanitation</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.CleanWaterSanitation
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.CleanWaterSanitation + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Reduced Inequality</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.ReducedInequality
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.ReducedInequality + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Life Below Water</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.LifeBelowWater
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.LifeBelowWater + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Good Health and Well Being</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.goodHealth
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.goodHealth + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Affordable & Clean Energy</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.AffordableCleanEnergy
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.AffordableCleanEnergy + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sustainable Cities & Communities</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.SustainableCitiesCommunities
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.SustainableCitiesCommunities + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Life on Land</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.LifeonLand
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.LifeonLand + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Peace & Justice Strong Insitutions
                        </TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.PeaceJusticeStrongInsitutions
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.PeaceJusticeStrongInsitutions +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quality Education</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.qualityEducation
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.qualityEducation + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Decent Work and Economic Growth</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.DecentWorkandEconomicGrowth
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.DecentWorkandEconomicGrowth + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Responsible Production & Consumption
                        </TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.ResponsibleProductionConsumption
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.ResponsibleProductionConsumption +
                              '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Partnerships</TableCell>
                        <TableCell align="left">
                          {enterpriseImpactScores &&
                          enterpriseImpactScores.enterpriseimpactRiskScore &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown &&
                          enterpriseImpactScores.enterpriseimpactRiskScore
                            .SGDBreakdown.Partnerships
                            ? enterpriseImpactScores.enterpriseimpactRiskScore
                                .SGDBreakdown.Partnerships + '%'
                            : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">100%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Number of SDG Above 10%</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore
                    .numberofGeographies
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .numberofSDGAbove10Percent.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore
                    .numberofGeographies
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .numberofSDGAbove10Percent.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Number of Geographies</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore
                    .numberofGeographies
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .numberofGeographies.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore
                    .numberofGeographies
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .numberofGeographies.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Impact Profile (IP)</Typography>
                <Typography variant="h5">
                  Answer:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore.impactOutcome
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .impactOutcome.answer
                    : 'N/A'}
                </Typography>
                <Typography variant="h5">
                  Score:&nbsp;
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore &&
                  enterpriseImpactScores.enterpriseimpactRiskScore.impactOutcome
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .impactOutcome.score
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">Final Impact Score</Typography>
                <Typography variant="h5">
                  {enterpriseImpactScores &&
                  enterpriseImpactScores.enterpriseimpactRiskScore
                    ? enterpriseImpactScores.enterpriseimpactRiskScore
                        .ImpactScore
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </DialogContent>
          {/*<DialogActions>
            <Button onClick={handleClose} color="danger" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>*/}
        </Dialog>
      </div>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={content}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            View
          </DialogTitle>
          <DialogContent dividers>
            <Card title="Personal info">
              <Typography variant="h4">Name</Typography>
              {UserInfo && UserInfo.first && UserInfo.last
                ? UserInfo.first + '' + UserInfo.last
                : 'N/A'}
              <Divider />
              <Typography variant="h4">Email</Typography>
              {UserInfo && UserInfo.email ? UserInfo.email : 'N/A'}
              <Divider />
              <Typography variant="h4">DOB</Typography>
              {UserInfo && UserInfo.dob ? UserInfo.dob : 'N/A'}
              <Divider />
              <Typography variant="h4">Gender</Typography>
              {UserInfo && UserInfo.gender ? UserInfo.gender : 'N/A'}
              <Divider />
              <Typography variant="h4">City</Typography>
              {UserInfo && UserInfo.city ? UserInfo.city : 'N/A'}
              <Divider />
              <Typography variant="h4">State</Typography>
              {UserInfo && UserInfo.state ? UserInfo.state : 'N/A'}
              <Divider />
              <Typography variant="h4">Country</Typography>
              {UserInfo && UserInfo.country ? UserInfo.country : 'N/A'}
              <Divider />
              <Typography variant="h4">Organization</Typography>
              {UserInfo && UserInfo.organization
                ? UserInfo.organization
                : 'N/A'}
              <Divider />
            </Card>
            <Card title="Bridge Financing Needs">
              <Box mb={2} mt={3}>
                <Typography variant="h4">Amount Seeking in Loans</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.financingNeeds ? (
                    <NumberFormat
                      value={
                        enterpriseForm.formAnswers.financingNeeds.seekingLoan
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  ) : (
                    'N/A'
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">Repayment Timeline</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.financingNeeds
                    ? enterpriseForm.formAnswers.financingNeeds.repayment
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
            </Card>
            <Divider />
            <Card title="Pillar 1 of 5: Earnings">
              <Box mb={1.5}>
                <Typography variant="h4">
                  What type of revenue do you have?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings
                    ? enterpriseForm.formAnswers.pillar1Earnings.revenueType
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="h4">
                  Which of the following represents the range for your annual
                  revenue (answers are in USD)?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings
                    .rangeforyourannualrevenue
                    ? enterpriseForm.formAnswers.pillar1Earnings
                        .rangeforyourannualrevenue
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  What has been your YOY growth of revenue for the last three
                  full years?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings.yoyGrowth
                    ? enterpriseForm.formAnswers.pillar1Earnings.yoyGrowth
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  If you do not have current revenue, have you acquired any
                  long-term contracts to service this debt? Please attach
                  relevant contract agreements.
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings
                    .contractAttachmentLinks &&
                  enterpriseForm.formAnswers.pillar1Earnings
                    .contractAttachmentLinks.length > 0 &&
                  enterpriseForm.formAnswers.pillar1Earnings.contractAttachmentLinks.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_1${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Explain your business model succintly.What are your lines of
                  revenue?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings
                    .explainBusinessModel
                    ? enterpriseForm.formAnswers.pillar1Earnings
                        .explainBusinessModel
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  What is your Gross Margin from revenue?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings.GrossMargin
                    ? enterpriseForm.formAnswers.pillar1Earnings.GrossMargin
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="h4">
                  What is your Net Margin from revenue?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings.NetMargin
                    ? enterpriseForm.formAnswers.pillar1Earnings.NetMargin
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mb={1.5}>
                <Typography variant="h4">
                  Please upload your latest financials and latest full year of
                  financials.
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar1Earnings &&
                  enterpriseForm.formAnswers.pillar1Earnings.yearoffinancials &&
                  enterpriseForm.formAnswers.pillar1Earnings.yearoffinancials.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_2${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
            </Card>
            <Card title="Pillar 1 of 5: Earnings">
              <Box mb={2} mt={3}>
                <Typography variant="h4">How old is your company?</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .howOldIsYourCompany
                    ? enterpriseForm.formAnswers.pillar3Fundamentals
                        .howOldIsYourCompany
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your projected annual debt service coverage ratio?
                  (net income/loan interest)
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .annualDebtServiceCoverageRation
                    ? enterpriseForm.formAnswers.pillar3Fundamentals
                        .annualDebtServiceCoverageRation
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  Do you have other senior loans?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .doYouHaveOtherSeniorLoans &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .doYouHaveOtherSeniorLoans == 'false' &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .doYouHaveOtherSeniorLoans == ''
                    ? 'NO'
                    : 'Yes'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  What is your leverage ratio (debt/ net worth) based on your
                  current Balance Sheet?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals.leverageratio
                    ? enterpriseForm.formAnswers.pillar3Fundamentals
                        .leverageratio
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5}>
                <Typography variant="h4">
                  How many senior loans do you currently have on the books?
                  Please provide relevant agreements.
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .howManySeniorLoansDoYouCurrentlyHaveOnTheBooks
                    ? enterpriseForm.formAnswers.pillar3Fundamentals
                        .howManySeniorLoansDoYouCurrentlyHaveOnTheBooks
                    : 'N/A'}
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar3Fundamentals &&
                  enterpriseForm.formAnswers.pillar3Fundamentals
                    .seniorLoanAgreementAttachmentLinks &&
                  enterpriseForm.formAnswers.pillar3Fundamentals.seniorLoanAgreementAttachmentLinks.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_3${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
              <Divider />
            </Card>
            <Card title="Pillar 3 of 5: Team">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What growth milestones have you achieved so far?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar2Team &&
                  enterpriseForm.formAnswers.pillar2Team.growthmilestones
                    ? enterpriseForm.formAnswers.pillar2Team.growthmilestones
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5} mt={6}>
                <Typography variant="h4">
                  Which range represents the Founding Team’s combined years of
                  industry expertise as relevant to this business?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar2Team &&
                  enterpriseForm.formAnswers.pillar2Team
                    .rangerepresentstheFoundingTeam
                    ? enterpriseForm.formAnswers.pillar2Team
                        .rangerepresentstheFoundingTeam
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5} mt={6}>
                <Typography variant="h4">
                  Do you have an established Board of Advisors or Directors?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar2Team &&
                  enterpriseForm.formAnswers.pillar2Team
                    .doYouHaveestablishedBoard &&
                  enterpriseForm.formAnswers.pillar2Team
                    .doYouHaveestablishedBoard == 'false'
                    ? 'NO'
                    : 'Yes'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={1.5} mt={6}>
                <Typography variant="h4">
                  Please share your list of Board of Advisors or Directors or
                  both.
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar2Team &&
                  enterpriseForm.formAnswers.pillar2Team
                    .listAdvisorsOrDirectors &&
                  enterpriseForm.formAnswers.pillar2Team.listAdvisorsOrDirectors.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_4${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
              <Divider />
            </Card>
            <Card title="Pillar 4 of 5: Qualitative">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  How many accelerators or incubators have you graduated from or
                  are on track to graduate from?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar4Qualitative &&
                  enterpriseForm.formAnswers.pillar4Qualitative
                    .acceleratorsorincubators
                    ? enterpriseForm.formAnswers.pillar4Qualitative
                        .acceleratorsorincubators
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Please list three references who can speak about your
                  integrity, management competencies and market potential.
                </Typography>
              </Box>
              <Box mt={4} mb={3}>
                <Bold>First referral</Bold>
                <Typography variant="h4">First and last name</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar4Qualitative &&
                  enterpriseForm.formAnswers.pillar4Qualitative.referralName1
                    ? enterpriseForm.formAnswers.pillar4Qualitative
                        .referralName1
                    : 'N/A'}
                </Typography>
                <Typography variant="h4">Email</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar4Qualitative &&
                  enterpriseForm.formAnswers.pillar4Qualitative.referralEmail1
                    ? enterpriseForm.formAnswers.pillar4Qualitative
                        .referralEmail1
                    : 'N/A'}
                </Typography>
              </Box>
              <Box mt={4} mb={3}>
                <Typography variant="h4">First and last name</Typography>
                <Typography variant="h5">
                  <Typography variant="h5">
                    {enterpriseForm &&
                    enterpriseForm.formAnswers &&
                    enterpriseForm.formAnswers.pillar4Qualitative &&
                    enterpriseForm.formAnswers.pillar4Qualitative.referralName2
                      ? enterpriseForm.formAnswers.pillar4Qualitative
                          .referralName2
                      : 'N/A'}
                  </Typography>
                </Typography>
                <Typography variant="h4">Email</Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar4Qualitative &&
                  enterpriseForm.formAnswers.pillar4Qualitative.referralEmail2
                    ? enterpriseForm.formAnswers.pillar4Qualitative
                        .referralEmail2
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
            </Card>
            <Card title="Pillar 5 of 5: Market Risk">
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What are the main sources of risk to your business?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.mainRiskSources
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .mainRiskSources
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What are your major challenges to business outlook during and
                  post COVID? How has COVID affect your revenues?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .majorChallengesBusinessOutlookDuringOrPostCovid
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .majorChallengesBusinessOutlookDuringOrPostCovid
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  How do you boost your company’s resilience post-COVID?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .howDoYouBoostCompanyResiliencePostCovid
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .howDoYouBoostCompanyResiliencePostCovid
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Please upload your latest market projections for the next 3-5
                  years.
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .latestMarketProject &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.latestMarketProject.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_5${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your company website?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.companyWebsite
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .companyWebsite
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Please attach your company incorporation paperwork.
                </Typography>
                {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .incorporationpaperwork &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.incorporationpaperwork.map(
                    (item, index) => (
                      <FileList
                        display="flex"
                        flexDirection={isMobile ? 'column' : 'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        px={3}
                        py={1}
                        key={`tb_6${index}`}
                      >
                        <Box display="flex">
                          <DownloadIcon src={iconDownload} alt="download" />
                          <Typography>{item.name}</Typography>
                        </Box>
                        {/* <Typography>A description of the deal and terms</Typography> */}
                        <Button
                          color="secondary"
                          variant="contained"
                          size="small"
                          disableElevation
                        >
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            id={item.name}
                            style={{ color: '#000' }}
                          >
                            Download
                          </a>
                        </Button>
                      </FileList>
                    )
                  )}
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  How many paying customers do you have?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .howManyPayingCustomersDoYouHave
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .howManyPayingCustomersDoYouHave
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  How do you acquire customers?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .howDoyouAcquireCustomers
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .howDoyouAcquireCustomers
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What percentage of your revenue is recurring?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .recurringRevenutPercentage
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .recurringRevenutPercentage
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Who are your main competitors?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.mainCompetitors
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .mainCompetitors
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your vision for your company?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.longTermVision
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .longTermVision
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Please note any awards you have received.
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.awardsReceived
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .awardsReceived
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  Have you or your company ever involved in fraud or bankruptcy?
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk.fraudOrBankruptcy
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .fraudOrBankruptcy
                    : 'N/A'}
                </Typography>
              </Box>
              <Divider />
              <Box mb={2} mt={3}>
                <Typography variant="h4">
                  What is your current rate of interest
                </Typography>
                <Typography variant="h5">
                  {enterpriseForm &&
                  enterpriseForm.formAnswers &&
                  enterpriseForm.formAnswers.pillar5MarketRisk &&
                  enterpriseForm.formAnswers.pillar5MarketRisk
                    .currentRateOfInterest
                    ? enterpriseForm.formAnswers.pillar5MarketRisk
                        .currentRateOfInterest
                    : 'N/A'}
                </Typography>
              </Box>
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
