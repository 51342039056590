import { acceleratorConstants } from '../constants/accelerator';

const initialState = {
  profile: {},
  enterpriseInvite_: {},
  enterpriseQuestAns_: {},
  enterpriseInviteQuestAns_: {},
  allacc: []
};

export function accelerator(state = initialState, action) {
  switch (action.type) {
    case acceleratorConstants.ACCELERATOR_GET_PROFILE_REQUEST:
      return state;
    case acceleratorConstants.ACCELERATOR_GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.data,
          ...action.data
        }
      };
    case acceleratorConstants.ACCELERATOR_GET_PROFILE_FAILURE:
      return state;
    case acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_REQUEST:
      return state;
    case acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.data,
          ...action.data
        }
      };
    case acceleratorConstants.ACCELERATOR_ADD_UPDATE_PROFILE_FAILURE:
      return state;
    case acceleratorConstants.ACCELERATOR_EMAIL_SEND_REQUEST:
      return state;
    case acceleratorConstants.ACCELERATOR_EMAIL_SEND_SUCCESS:
      return {
        ...state,
        enterpriseInvite_: {
          ...state.data,
          ...action.data
        }
      };
    case acceleratorConstants.ACCELERATOR_EMAIL_SEND_FAILURE:
      return state;
    case acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_REQUEST:
      return state;
    case acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_SUCCESS:
      return {
        ...state,
        enterpriseQuestAns_: {
          ...state.data,
          ...action.data
        }
      };
    case acceleratorConstants.ENTERPRISE_SAVE_QUES_ANS_FAILURE:
      return state;
    case acceleratorConstants.ENTERPRISE_GET_QUES_ANS_REQUEST:
      return state;
    case acceleratorConstants.ENTERPRISE_GET_QUES_ANS_SUCCESS:
      return {
        ...state,
        enterpriseInviteQuestAns_: {
          ...state.data,
          ...action.data
        }
      };
    case acceleratorConstants.ENTERPRISE_GET_QUES_ANS_FAILURE:
      return state;
    case acceleratorConstants.GET_ACCELERATOR_REQUEST:
      return state;
    case acceleratorConstants.GET_ACCELERATOR_SUCCESS:
      return {
        ...state,
        allacc: {
          ...state.accdata,
          ...action.accdata
        }
      };
    case acceleratorConstants.GET_ACCELERATOR_FAILURE:
      return state;
    default:
      return state;
  }
}
