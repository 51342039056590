import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import theme from '../../styles/theme';
import { userRoles } from '../../constants/user';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/userActions';

const RoleSelect = () => {
  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [role, setRole] = React.useState('');

  const onChange = useCallback(event => {
    setRole(event.target.value);
  }, []);

  const onNext = useCallback(() => {
    const callback = async () => {
      let path;
      switch (role) {
        case userRoles.ACCELERATOR:
          path = '/accelerator/form';
          break;
        case userRoles.ENTERPRISE:
          path = '/enterprise/form';
          break;
        case userRoles.INVESTOR:
          path = '/investor/form';
          break;
        default:
          break;
      }
      var onboardingLeaveStep = 'intake';
      dispatch(
        userActions.setRole(
          user.id,
          {
            ...user,
            role,
            onboardingLeaveStep
          },
          path
        )
      );
    };
    callback();
  }, [dispatch, role, user]);

  return (
    <Wrapper>
      <Box display="flex" flexDirection="column">
        <Typography variant="h1">Select Who You Are:</Typography>
        <RoleSelection>
          <Select
            labelId="select-role"
            value={role}
            onChange={onChange}
            variant="outlined"
          >
            {Object.keys(userRoles).map(role => (
              <MenuItem value={role} key={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </RoleSelection>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onNext}
          disabled={!role}
        >
          Next
        </Button>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoleSelection = styled(FormControl)`
  margin-top: ${theme.spacing(2)}px;
  margin-bottom: ${theme.spacing(8)}px;
  min-width: 240px;
`;

export default RoleSelect;
