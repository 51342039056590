import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import Card from '../../common/Card/Card';
import iconPencil from '../../../assets/images/pencil.svg';
import iconMinus from '../../../assets/images/minusRect.svg';
import imageBG from '../../../assets/images/circleBackground.svg';
import { investorActions } from '../../../actions/investorActions';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Button,
  useMediaQuery,
  Checkbox,
  TextField,
  FormControlLabel,
  Divider,
  Typography
} from '@material-ui/core';
import {
  StyledRadio,
  StyledCheckedIconTick,
  StyledCheckedTickIcon
} from '../Onboarding/Onboarding.styled';
const GoalsSdgItems = [
  { id: 'noPoverty', label: 'No Poverty', percetange: '20' },
  { id: 'genderEquality', label: 'Gender Equality', percetange: '0' },
  {
    id: 'industryInnovation',
    label: 'Industry Innovation & Infrastructure',
    percetange: '20'
  },
  { id: 'climateAction', label: 'Climate Action', percetange: '0' },
  { id: 'zeroHunger', label: 'Zero Hunger', percetange: '0' },
  { id: 'cleanWater', label: 'Clean Water & Sanitation', percetange: '0' },
  { id: 'reducedInequality', label: 'Reduced Inequality', percetange: '0' },
  { id: 'lifeBelowWater', label: 'Life Below Water', percetange: '20' },
  {
    id: 'goodHealthAndWellBeing',
    label: 'Good Health and Well Being',
    percetange: '0'
  },
  {
    id: 'affordableAndCleanEnergy',
    label: 'Affordable & Clean Energy',
    percetange: '0'
  },
  {
    id: 'sustainableCities',
    label: 'Sustainable Cities & Communities',
    percetange: '0'
  },
  { id: 'lifeOnLand', label: 'Life on Land', percetange: '20' },
  {
    id: 'peaceAndJusticeStrongInstitutions',
    label: 'Peace & Justice Strong Insitutions',
    percetange: '20'
  },
  { id: 'qualityEducatio', label: 'Quality Education', percetange: '0' },
  {
    id: 'decentWorkAndEconomicGrowth',
    label: 'Decent Work and Economic Growth',
    percetange: '0'
  },
  {
    id: 'responsibleProduction',
    label: 'Responsible Production & Consumption',
    percetange: '0'
  },
  { id: 'partnerships', label: 'Partnerships', percetange: '20' }
];

const geoPrItems = [
  {
    id: 'all',
    label: 'All Geographies'
  },
  {
    id: 'asia',
    label: 'Asia'
  },
  {
    id: 'australia',
    label: 'Australia'
  },
  { id: 'africa', label: 'Africa' },
  { id: 'northAmerica', label: 'North America' },
  { id: 'southAmerica', label: 'South America' },
  { id: 'europe', label: 'Europe' }
];
const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const investorImpact = useSelector(state => state.investor.ImpactRiskScores);
  const investorFin = useSelector(state => state.investor.RiskScores);
  const [activeFormAnswers, setActiveFormAnswers] = useState();
  useEffect(() => {
    user &&
      user.id &&
      dispatch(investorActions.investorImpactRiskScores(user.id));
    user &&
      user.id &&
      dispatch(investorActions.investorFiancialRiskScores(user.id));
    user &&
      user.id &&
      dispatch(investorActions.getForm(user.id)).then(response => {
        setActiveFormAnswers(response.formAnswers);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <Box mb={7}>
        <Typography variant="h1">
          <strong>Your Profile</strong>
        </Typography>
      </Box>
      <Grid container spacing={5} direction="column">
        <Grid item>
          <WhiteBoard>
            <Typography variant="h3">
              Thanks for sharing your goals with ImpactCred! We’ll use this to
              suggest deals and provide recommendations on your investment
              strategy.
            </Typography>
            <Box mb={3} />
            {/*<Typography>
              We find that some of the best deals are made with other investors
              in your corner. Next, we’ll ask you a few questions that will help
              us share information across the ecosystem better. Please share as
              much as you’re comfortable with so we can make our ecosystem
              stronger!
            </Typography>*/}
          </WhiteBoard>
        </Grid>
        {/* <Grid item>
          <Card title="My Impact Profile">
            <Grid container>
              <Grid item xs={2}>
                <CircleImage>Targeting change</CircleImage>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h4">
                  My impact investment profile best matches the Targeting Change
                  profile.
                </Typography>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<img src={iconMinus} alt="next" />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Share Publicly
              </Button>
            </Box> 
          </Card>
        </Grid> */}
        <Grid item>
          <Card title="My Sustainability Goals">
            {/*<Typography variant="h4">
              My primary objectives involve Zero Hunger
            </Typography>*/}
            <Box mt={2} mb={2} display="flex">
              <Grid container spacing={1}>
                {activeFormAnswers &&
                  activeFormAnswers.investmentGoalsSdg &&
                  Object.keys(
                    activeFormAnswers.investmentGoalsSdg.sdgPreferences
                  ).length > 0 &&
                  activeFormAnswers.investmentGoalsSdg.sdgPreferences.map(
                    item => (
                      <Grid item key={`geoPre_-${item}`} xs={12} sm={6} md={4}>
                        <Box style={{ marginLeft: '10px', marginTop: '30px' }}>
                          <FormControlLabel
                            style={{ textTransform: 'capitalize' }}
                            checked={true}
                            control={
                              <StyledRadio
                                disableRipple
                                icon={<StyledCheckedIconTick />}
                                checkedIcon={<StyledCheckedTickIcon />}
                              />
                            }
                            label={
                              GoalsSdgItems.filter(e => e.id == item)[0].label
                            }
                          />
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
            </Box>
            {/* <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<img src={iconMinus} alt="next" />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Set to Private
              </Button>
            </Box> */}
          </Card>
        </Grid>
        <Grid item>
          <Card title="Geographic Preferences">
            {/*<Typography variant="h4">
              My primary objectives involve Zero Hunger
            </Typography>*/}
            <Box mt={2} mb={2} display="flex">
              <Grid container spacing={1}>
                {activeFormAnswers &&
                  activeFormAnswers.geoPreferences &&
                  activeFormAnswers.geoPreferences.map(item => (
                    <Grid item key={`geoPre_-${item}`} xs={12} sm={6} md={3}>
                      <Box style={{ marginLeft: '10px', marginTop: '30px' }}>
                        <FormControlLabel
                          style={{ textTransform: 'capitalize' }}
                          checked={true}
                          control={
                            <StyledRadio
                              disableRipple
                              icon={<StyledCheckedIconTick />}
                              checkedIcon={<StyledCheckedTickIcon />}
                            />
                          }
                          label={geoPrItems.filter(e => e.id == item)[0].label}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {/* <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<img src={iconMinus} alt="next" />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Set to Private
              </Button>
            </Box> */}
          </Card>
        </Grid>
        <Grid item>
          <Card title="My Impact Philosophy">
            <Typography variant="h2">
              <strong>
                {activeFormAnswers &&
                  activeFormAnswers.impactPortfolio &&
                  activeFormAnswers.impactPortfolio.impactProfile}
              </strong>
            </Typography>
            {/*<Typography variant="h6">MOST COMMON IMPACT PROFILE</Typography>*/}
            <Box mt={2} mb={2}>
              <Divider />
            </Box>
            {/* <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<img src={iconMinus} alt="next" />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Set to Private
              </Button>
            </Box> */}
          </Card>
        </Grid>
        <Grid item>
          <Card title="My Overall Financial Strategy">
            <Grid container>
              <Grid item xs={2}>
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore <= 2 && (
                    <CircleImage>Below Average Risk</CircleImage>
                  )}
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore >= 2.1 &&
                  investorFin.finalRiskScore <= 4 && (
                    <CircleImage>Average</CircleImage>
                  )}
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore >= 4.1 && (
                    <CircleImage>Above Average</CircleImage>
                  )}
              </Grid>
              <Grid item xs={10}>
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore <= 2 && (
                    <Typography variant="h4">
                      My goal is to seek out companies with Below `Average` Risk
                      rating with ImpactCred, as these will best fit my
                      financial goals.
                    </Typography>
                  )}
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore >= 2.1 &&
                  investorFin.finalRiskScore <= 4 && (
                    <Typography variant="h4">
                      My goal is to seek out companies with `Average` Risk
                      rating with ImpactCred, as these will best fit my
                      financial goals.
                    </Typography>
                  )}
                {investorFin &&
                  investorFin.finalRiskScore &&
                  investorFin.finalRiskScore >= 4.1 && (
                    <Typography variant="h4">
                      My goal is to seek out companies with Above `Average` Risk
                      rating with ImpactCred, as these will best fit my
                      financial goals.
                    </Typography>
                  )}
              </Grid>
            </Grid>
            {/* <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<img src={iconMinus} alt="next" />}
                variant="contained"
                color="primary"
                disableElevation
              >
                Set to Private
              </Button>
            </Box> */}
          </Card>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export const Wrapper = styled(Box)`
  height: 100%;
  padding: 20px;
  max-width: 1000px;
  background-color: ${props => props.backgroundcolor};
  color: ${theme.palette.common.black};

  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.down('xs')} {
    padding: ${theme.spacing(2)}px;
  }
`;

export const WhiteBoard = styled(Box)`
  background-color: ${theme.palette.common.white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: ${theme.spacing(2, 3)};
`;

const ZeroHunger = styled.div`
  background: ${theme.palette.common.green1};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Zero Hunger';
    color: white;
    margin-left: 10px;
  }
`;

const LifeBelowWater = styled.div`
  background: ${theme.palette.common.main};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'Life Below Water';
    color: white;
    margin-left: 10px;
  }
`;

const NoPoverty = styled.div`
  background: ${theme.palette.common.mainDark};
  flex: ${props => props.width};
  height: 20px;
  border-radius: 5px;

  &:after {
    content: 'No Poverty';
    color: white;
    margin-left: 10px;
  }
`;

const CircleImage = styled(Box)`
  color: white;
  width: 110px;
  height: 110px;
  padding: 20px;
  background-size: cover;
  background-image: url(${imageBG});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
`;

export default Profile;
