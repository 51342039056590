import { Box, Typography } from '@material-ui/core';
import Card from '../../common/Card/Card';

import { Bold, Wrapper } from '../Onboarding/Onboarding.styled';

const Rejected = () => {
  return (
    <Wrapper>
      <Box mb={7}>
        <Bold variant="h1">Account Rejected</Bold>
      </Box>
      <Card title={'Account Rejected'}>
        <Typography variant="h4">
          We were unable to approve your application at this time. A member of
          our team will contact you with further information in ten to fourteen
          business days. We look forward to discussing how to support your
          Organization to become investment ready in future
        </Typography>
      </Card>
    </Wrapper>
  );
};

export default Rejected;
