import React, { useCallback, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import mobileImage from '../../assets/images/mobile.svg';
import theme from '../../styles/theme';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { history } from '../../helpers/history';
import Signup from '../common/Signup/Signup';
import Login from '../common/Login/Login';
const Home = () => {
  const user = useSelector(state => state.user);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  useEffect(() => {
    if (user.role) {
      history.push(`/${user.role.toLowerCase()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onLogin = useCallback(() => {
    setShowLogin(true);
  }, [setShowLogin]);

  const onSignup = useCallback(() => {
    setShowSignup(true);
  }, [setShowSignup]);
  return (
    <Wrapper>
      <Typography
        variant="h3"
        style={{
          backgroundColor: '#fafafa',
          textAlign: 'center',
          marginTop: '150px',
          marginBottom: '80px',
          fontSize: '2.5rem',
          color: '#000',
          fontFamily: 'Poppins',
          lineHeight: '1',
          fontWeight: 'Bold'
        }}
      >
        Thanks for taking the first step!
        <br /> We can&apos;t wait to welcome you to the ImpactCred community!
      </Typography>
      <Container maxWidth="xl">
        {/* <img src={mobileImage} alt="mobile" /> */}
        <Grid
          item
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            top: '50%',
            margin: 'auto',
            width: 'max-content'
          }}
        >
          <ButtonWrapper alignItems="center" display="flex">
            <Typography
              onClick={onLogin}
              style={{
                fontSize: '1rem',
                marginRight: '79px',
                backgroundColor: '#59D3C8',
                color: '#fff',
                textDecoration: 'none',
                lineHeight: '150%',
                display: 'block',
                padding: '15px 35px',
                textAlign: 'center',
                border: '2px solid #59D3C8',
                borderRadius: '3em'
              }}
            >
              Log In
            </Typography>
            <Typography
              style={{
                fontSize: '1rem',
                marginRight: '79px',
                backgroundColor: '#59D3C8',
                color: '#fff',
                textDecoration: 'none',
                lineHeight: '150%',
                display: 'block',
                padding: '15px 35px',
                textAlign: 'center',
                border: '2px solid #59D3C8',
                borderRadius: '3em'
              }}
              onClick={onSignup}
            >
              Sign-up
            </Typography>
          </ButtonWrapper>
        </Grid>
        <Login open={showLogin} setOpen={setShowLogin} />
        <Signup open={showSignup} setOpen={setShowSignup} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  background-color: ${theme.palette.common.gray};
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-tems: center;
  cursor: pointer;
  margin-left: 16px;
`;

export default Home;
